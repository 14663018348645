import { BranchPage } from 'components/Shared/PageTemplate/BranchPage';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import { BranchPagesName } from 'pages/NewConfigure/Configure.util';
import { useFeedbackNavigation } from 'pages/NewConfigure/hooks/useFeedbackNavigation';

type Props = {};

export const MergersAndAcquisitions = (props: Props) => {
  const { redirectToTalkPage, feedbackOption } = useFeedbackNavigation(BranchPagesName.manda);

  return (
    <BranchPage
      title={feedbackOption?.title!}
      description={feedbackOption?.description!}
      logo={<img src={feedbackOption?.image} alt="talk to expert chat icon" className="w-[150px] h-[150px] mx-auto" />}
      buttonIcon={<img src={feedbackOption?.buttonImg} alt="Sign up for contactor (free)" />}
      buttonText={feedbackOption?.buttonText!}
      onClick={redirectToTalkPage}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_MERGERS_AND_ACQUISITIONS}
    />
  );
};
