import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TypographyVariantType } from './Typography.types';
import { twMerge } from 'tailwind-merge';
import { VariantsMap } from './Typography.config';

interface TypographyProps {
  variant?: TypographyVariantType;
  //user can pass any class name to override the default class
  className?: string;
  testid?: string;
}

const Typography: FunctionComponent<
  TypographyProps &
    PropsWithChildren &
    React.HTMLAttributes<HTMLParagraphElement | HTMLSpanElement | HTMLHeadingElement>
> = ({ children, variant = 'p-medium', className, ...rest }) => {
  let typographyVariant = VariantsMap[variant];
  const tag = typographyVariant.tag ? typographyVariant.tag : variant;
  return React.createElement(tag, { className: twMerge(typographyVariant.variantClass, className), ...rest }, children);
};
export default Typography;
