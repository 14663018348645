interface WindowWithExtensions extends Window {
  heap: {
    identify: (id: string) => void;
    resetIdentity: () => void;
    addUserProperties: (obj: Record<string, unknown>) => void;
    addEventProperties: (obj: Record<string, unknown>) => void;
    clearEventProperties: () => void;
  };
}

type marketingData = {
  prospectId: string;
  hashedEmail?: string;
};
/**
 * Gets Heap Instance from Window.
 * @returns Heap Instance.
 */
const getHeap = () => (window as WindowWithExtensions & typeof globalThis).heap;

/**
 * Identifies a User within Heap. All subsequent trackable actions will be assigned to the passed User.
 * @param userData User Data necessary to uniquely identify the user in Heap.
 */
export const identifyHeapUser = (userData: marketingData): void => {
  const heap = getHeap();
  if (heap && heap.addUserProperties && heap.addEventProperties) {
    const preferences = {
      campaign: sessionStorage.getItem('utm_campaign'),
      source: sessionStorage.getItem('utm_source'),
      keyword: sessionStorage.getItem('utm_keyword'),
      medium: sessionStorage.getItem('utm_medium'),
    };
    heap.addUserProperties({ ...userData, ...preferences });
    heap.addEventProperties({ ...userData, ...preferences, personType: 'DES' });
  }
};

export const attachDESToHeap = (): void => {
  const heap = getHeap();
  if (heap && heap.addUserProperties) {
    heap.addUserProperties({ userType: 'DES' });
  }
};

/**
 * Resets the User in Heap, so further actions are not linked to the previously identified User.
 */
export const resetHeapUser = (): void => {
  const heap = getHeap();
  if (heap && heap.resetIdentity) heap.resetIdentity();
  if (heap && heap.clearEventProperties) heap.clearEventProperties();
};
