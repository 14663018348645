import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 9;
const buttonName = 'howManyTeamMembers';
export const HowManyTeamMembers = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      css="tabletDownwards:grow"
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_HOW_MANY_TEAM_MEMBERS_TO_HIRE}
    />
  );
};
