import { getLandingContent } from 'api/content/Landing';
import { makeObservable, observable, runInAction } from 'mobx';
import RootStore from 'rootstore';
import { attachDESToHeap } from 'utils/heap';
import { LandingData, LandingResponse } from './Landing.type';

export default class LandingPageStore {
  public LandingPageData: LandingData | null;
  public isLoading: boolean;
  public error: boolean;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      LandingPageData: observable.ref,
      isLoading: observable.ref,
      error: observable.ref,
    });

    this.rootStore = rootStore;
    this.LandingPageData = null;
    this.isLoading = false;
    this.error = false;
  }

  setError = (error: boolean): void => {
    runInAction(() => (this.error = error));
  };

  setLoading = (loading: boolean): void => {
    runInAction(() => (this.isLoading = loading));
  };

  setLandingPageData = (data: LandingData): void => {
    runInAction(() => (this.LandingPageData = data));
  };

  fetchLandingAsync = async () => {
    try {
      this.setLoading(true);
      const landingPageData: LandingResponse = await getLandingContent();

      if (landingPageData.success) {
        this.setLandingPageData(landingPageData.data[0]);
        attachDESToHeap();
      } else {
        this.setError(true);
      }
      this.setLoading(false);
    } catch (error: any) {
      this.setError(true);
    }
  };

  getLandingData = () => {
    this.setLoading(true);
    if (this.LandingPageData === null) {
      this.fetchLandingAsync();
    }
    this.setLoading(false);
    return this.LandingPageData;
  };
}
