import { FunctionComponent, PropsWithChildren, useCallback, MouseEvent } from 'react';
import { useRootStore } from 'contexts/StoreContext';
import { useForm } from 'react-hook-form';
import { Roles, config, getCountriesWithCallingCodes, getStatesList } from './FormConfig';
import { InputText } from './TextInput/TextInput';
import { CheckBox } from './CheckBox/CheckBox';
import { DropDown } from './DropDown/DropDown';
import { RadioGroup } from './RadioGroup/RadioGroup';
import { Information } from 'components/Atomic/Icon';
import { useSideDrawer } from 'hooks/useSideDrawer';
import { SidePanelInfoContentModel } from 'components/Shared/InfoPanel/InfoPanel.util';
import { ContactFormData } from './ContactForm.store';
import { GP_PRIVATE_POLICY_URL } from 'utils/constants';
import { CTARecord } from './ContactFrom.types';
import { StyledDropDown } from './CountryDropDown/CountryDropDown';
import { ExtendedEvent } from './contactForm.types';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import { eliminateSpace } from 'utils/helpers';

export const FieldWrapper: FunctionComponent<PropsWithChildren<{ label: string }>> = ({ label, children }) => {
  return (
    <div className="relative flex box-border flex-col justify-start grow shrink basis-0 max-w-full min-w-[250px] phoneOnly:min-w-[200px] gap-0">
      <p className="p-0 mb-2 writing-text text-grey-1000" data-testid={`${eliminateSpace(label)}-label`}>
        {label}
      </p>
      {children}
    </div>
  );
};

interface ContactFormProps {
  instanceId: string;
  onFormSubmit: (data: any) => void;
  onFormError: (data: any) => void;
  CTA: CTARecord;
  infoPanelIndex?: string;
}

export const ContactFormTemplate: FunctionComponent<ContactFormProps> = ({
  onFormSubmit,
  instanceId,
  onFormError,
  CTA,
  infoPanelIndex,
}) => {
  const {
    contactFormStore: { formData, setFormData },
  } = useRootStore();

  const { control, handleSubmit, formState, trigger } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { onTriggerClick } = useSideDrawer();
  const getCallingCodes = useCallback(getCountriesWithCallingCodes, []);
  const getStates = useCallback(getStatesList, []);

  const onFieldChange = (e: ExtendedEvent) => {
    let value;
    if (e.target.type === 'checkbox') value = e.target.checked;
    else value = e.target.value;
    setFormData(e.target.name as keyof ContactFormData, value);
  };

  const onCountryChange = (e: ExtendedEvent) => {
    trigger('phoneNumber');
    onFieldChange(e);
  };

  const handleSidePanelTriggerClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { id, name } = event.currentTarget;
    /**
     * we pass in infoPanelIndex in other to get the correct infoPanel content in the case when;
     * the instance id has meta-data e.g (Primary Goals), which is not a keyof typeof SidePanelInfoContentModel.
     */
    const indexName = infoPanelIndex ? name : id;
    onTriggerClick(SidePanelInfoContentModel[indexName as keyof typeof SidePanelInfoContentModel]);
  };

  const { Comp, props } = CTA;

  const infoPanelIdentifier = infoPanelIndex ?? instanceId;
  return (
    <div className="over survey-question-container">
      <p className="survey-question-title" data-testid={`${instanceId}-title`}>
        Your contact information
      </p>
      <div className="survey-question-description" data-testid={`${instanceId}-description`}>
        {ContactFormSubTitleText[infoPanelIdentifier as keyof typeof ContactFormSubTitleText]}
        <button
          className="absolute pt-1 cursor-pointer pl-sm"
          id={`${instanceId}-infoPanel-trigger`}
          name={`${infoPanelIdentifier}-infoPanel-trigger`}
          onClick={handleSidePanelTriggerClick}
        >
          <Information />
        </button>
      </div>

      <form onSubmit={handleSubmit(onFormSubmit, onFormError)} id={instanceId}>
        <div className="box-border flex flex-wrap items-start justify-start w-full gap-4 my-6">
          <FieldWrapper label="First Name">
            <InputText
              id={`${instanceId}-firstName-input`}
              config={config.firstName}
              value={formData.firstName}
              control={control}
              onFieldChange={onFieldChange}
            />
          </FieldWrapper>
          <FieldWrapper label="Last Name">
            <InputText
              id={`${instanceId}-lastName-input`}
              config={config.lastName}
              value={formData.lastName}
              control={control}
              onFieldChange={onFieldChange}
            />
          </FieldWrapper>
          <FieldWrapper label="Company Name">
            <InputText
              id={`${instanceId}-companyName-input`}
              config={config.company}
              value={formData.companyName}
              control={control}
              onFieldChange={onFieldChange}
            />
          </FieldWrapper>
          <FieldWrapper label="Company Email">
            <InputText
              id={`${instanceId}-companyEmail-input`}
              config={config.companyEmail}
              value={formData.companyEmail}
              control={control}
              onFieldChange={onFieldChange}
            />
          </FieldWrapper>
          <FieldWrapper label="Phone">
            <div className="flex flex-row gap-2">
              <div className="min-w-[124px]">
                <StyledDropDown
                  id={`${instanceId}-countryCode`}
                  config={config.country}
                  value={formData.country}
                  control={control}
                  options={getCallingCodes()}
                  onFieldChange={onCountryChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  id={`${instanceId}-phoneNumber-input`}
                  config={config.phone}
                  value={formData.phoneNumber}
                  control={control}
                  onFieldChange={onFieldChange}
                />
              </div>
            </div>
          </FieldWrapper>
          <FieldWrapper label="State">
            <DropDown
              id={`${instanceId}-state-input`}
              config={config.state}
              control={control}
              value={formData.state}
              options={getStates()}
              onFieldChange={onFieldChange}
            />
          </FieldWrapper>
        </div>
        <div className="my-4">
          <p className="p-0 mb-2 writing-text text-grey-1000">Your Role</p>
          <div className="relative flex flex-wrap items-center justify-start gap-4">
            <RadioGroup
              config={config.role}
              control={control}
              options={Roles}
              value={formData.role}
              onFieldChange={onFieldChange}
              id={`${instanceId}-Role`}
            />
          </div>
        </div>
        <div className="my-6">
          <div className="flex gap-2">
            <CheckBox
              config={config.terms}
              control={control}
              value={formData.termsAccepted}
              onFieldChange={onFieldChange}
              id={`${instanceId}-terms-checkBox`}
            >
              <span className="inline mr-[6px] leading-[22.5px] text-[15px] writing-text">I agree to G-P's</span>
              <span>
                <a href={GP_PRIVATE_POLICY_URL} className="text-[15px] writing-text text-primary-500 hover:underline">
                  Privacy Policy
                </a>
              </span>
            </CheckBox>
          </div>
        </div>
        <Comp {...props} disabled={!formState.isValid} instanceId={instanceId} />
      </form>
    </div>
  );
};

const ContactFormSubTitleText = {
  [`${ElementIdentifiersRecord.CONFIGURE_PAGE_CONTACT_FORM}`]:
    "We'll use your contact information to send you a copy of your quote via email.",
  [`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING_FORM}`]:
    "We'll use these details if we need to contact you.",
  [`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_GET_A_CALLBACK_FORM}`]:
    "We'll use these details if we need to contact you.",
};
