import { sidePanelInfoContent, SidePanelInfoPageIndex } from 'components/Shared/InfoPanel/InfoPanel.util';
import { useRootStore } from 'contexts/StoreContext';

interface InfoPanelData {
  title: string;
  description: string;
}

export type PanelTopic = SidePanelInfoPageIndex;

export type useSideDrawerReturnValue = {
  onTriggerClick: (panelTopic: PanelTopic) => void;
  closeSideBar: () => void;
  currentInfoPanelTopic: string | number | null;
  infoPanelData: InfoPanelData;
};
/**
 * useSideDrawer hook
 * @returns {object} - Object containing the following properties:
 * - onTriggerClick: Function to handle the click event of the trigger button
 * - closeSideBar: Function to close the side drawer
 * - currentInfoPanelTopic: The current topic of the side drawer
 * - infoPanelData: The data of the side drawer
 */
export const useSideDrawer = (): useSideDrawerReturnValue => {
  const { prospectLayoutStore } = useRootStore();
  const { currentInfoPanelTopic, setSideDrawer, setCurrentInfoPanelTopic } = prospectLayoutStore;

  const getInfoPanelData = (topic: PanelTopic): InfoPanelData => {
    return sidePanelInfoContent[topic] ?? { title: '', description: '' };
  };

  const onTriggerClick = (panelTopic: PanelTopic): void => {
    if (currentInfoPanelTopic === panelTopic) {
      closeSideBar();
    } else {
      setCurrentInfoPanelTopic(String(panelTopic)?.trim?.() as PanelTopic);
      setSideDrawer(true);
    }
  };

  const closeSideBar = (): void => {
    setCurrentInfoPanelTopic(null);
    setSideDrawer(false);
  };

  const infoPanelData: InfoPanelData = getInfoPanelData(currentInfoPanelTopic as PanelTopic);

  return { onTriggerClick, closeSideBar, currentInfoPanelTopic, infoPanelData };
};
