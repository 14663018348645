import { Control, Controller } from 'react-hook-form';
import { FunctionComponent, PropsWithChildren } from 'react';
import { ExtendedEvent, FieldConfig, FieldControllerProps } from '../contactForm.types';

export type CheckBoxProps = {
  control: Control;
  config: FieldConfig;
  value: string | boolean | undefined;
  onFieldChange: (e: ExtendedEvent) => void;
  id: string;
};
export const CheckBox: FunctionComponent<PropsWithChildren<FieldControllerProps>> = ({
  children,
  control,
  config,
  value,
  onFieldChange,
  id,
}) => {
  return (
    <Controller
      name={config.name}
      rules={config.rules}
      control={control}
      defaultValue={value}
      render={({ field: { ...field }, fieldState }) => {
        return (
          <>
            <input
              type="checkbox"
              id={`${id}`}
              data-testid={id}
              {...field}
              defaultChecked={value as boolean}
              onChange={(e) => {
                field.onChange(e);
                onFieldChange?.(e as ExtendedEvent);
              }}
              className="relative w-6 h-6 border-2 rounded appearance-none cursor-pointer border-grey-400 checked:bg-primary-500 checked:border-primary-500"
            />
            <svg
              className="absolute w-4 h-4 mx-1 mt-1 outline-none pointer-events-none stroke-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            <label htmlFor={id} data-testid={`${id}-label`}>
              {children}
            </label>
          </>
        );
      }}
    />
  );
};
