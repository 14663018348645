import { ContentContainer } from './PrimaryBusinessGoal.styled';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { observer } from 'mobx-react';
import { ConfigureStepRecord } from 'pages/NewConfigure/Configure.util';
import SurveyBackAndNextButton from 'components/Shared/SurveyBackAndNextButton';
import { usePrimaryBusinessGoal } from './usePrimaryBusinessGoal';
import { GoalsSection } from './GoalsSections';
import { searchMapByKey } from 'utils/helpers';
import { SidePanelInfoContentModel } from 'components/Shared/InfoPanel/InfoPanel.util';
import { useSideDrawer } from 'hooks/useSideDrawer';
import { TitleSubTitleInfoPanelTrigger } from 'components/Shared/TitleSubTilteInfoPanelTrigger';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

export const PrimaryBusinessGoal = observer(() => {
  const {
    onNextClicked,
    handleButtonClick,
    completedConfigureSteps,
    data,
    primaryObjectivesData,
    selectedRules,
    setCurrentPage,
    setDirection,
  } = usePrimaryBusinessGoal();

  const isCompleted = searchMapByKey(completedConfigureSteps, ConfigureStepRecord.primaryBusinessGoal);

  const { onTriggerClick } = useSideDrawer();
  const identifier = `${ElementIdentifiersRecord.CONFIGURE_PAGE_PRIMARY_BUSINESS_GOAL}:1`;
  return (
    <ContentContainer className=" box-border flex flex-col justify-center max-w-[720px] mx-20 my-0 px-0 py-12 grow shrink-0 basis-0">
      <div>
        <TitleSubTitleInfoPanelTrigger
          identifier={identifier}
          title={data.questions[0].title}
          description={data.questions[0].description!}
          onClickHandler={() => onTriggerClick(SidePanelInfoContentModel.primarygoals)}
        />

        <GoalsSection
          primaryObjectivesData={primaryObjectivesData}
          selectedRules={selectedRules}
          handleButtonClick={handleButtonClick}
          onInfoPanelTriggerClick={onTriggerClick}
          identifier={identifier}
        />

        <SurveyBackAndNextButton
          instanceId={identifier}
          disabled={!isCompleted}
          onBackClick={() => {
            setCurrentPage(ConfigureStepRecord.intro);
            setDirection(Direction.back);
          }}
          onNextClick={onNextClicked}
        />
      </div>
    </ContentContainer>
  );
});
