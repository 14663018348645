import React, { useEffect, useReducer } from 'react';

import { AnimatePresence } from 'framer-motion';
import useCmpData from './utils/useCmpData';
import { TestimonialInitialState, TestimonialReducer } from './Testimonial.reducer';
import { Article } from 'pages/NewLanding/Landing.type';
import TestimonialCard from './components/TestimonialCard';
import TestimonialIcons from './components/TestimonialIcons';
import { HandledDragEndType } from './index.type';

const DELAY = 5000;

export const GPTestimonial: React.FC<{ articles?: Article[] }> = (props?: { articles?: Article[] }) => {
  const data = useCmpData(props?.articles!);

  const [state, dispatch] = useReducer(TestimonialReducer, TestimonialInitialState);
  const { activeIndex, direction, play } = state;

  //This is the makes the testimonial slide with a delay of 5 seconds
  useEffect(() => {
    if (data.length) {
      if (play) {
        const interval = setInterval(() => {
          if (direction !== 1) dispatch({ type: 'SET_DIRECTION', payload: 1 });
          if (data && data.length - 1 > activeIndex) {
            dispatch({ type: 'SET_ACTIVE_INDEX', payload: activeIndex + 1 });
          } else {
            dispatch({ type: 'SET_ACTIVE_INDEX', payload: 0 });
          }
        }, DELAY);
        return () => clearInterval(interval);
      }
    }
  }, [direction, activeIndex, data, play]);

  const swipeConfidenceThreshold = 5000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };
  // drag end event triggers do drag to navigate to next/previous card

  const handleClick = (index: number): void => {
    dispatch({ type: 'SET_ACTIVE_INDEX', payload: index });
  };

  const handledDragEnd = ({ offset, velocity }: HandledDragEndType) => {
    const swipe = swipePower(offset.x, velocity.x);
    if (swipe < -swipeConfidenceThreshold) navigate(activeIndex + 1);
    if (swipe > swipeConfidenceThreshold) navigate(activeIndex - 1);
    dispatch({ type: 'SET_IS_DRAGGING', payload: false });
  };

  // navigate to the requested item in the collection
  // if request is out of collection bounds restart
  const navigate = (page: number) => {
    if (page === activeIndex) return; // requested item is active already
    let dir = page <= activeIndex ? -1 : 1; // set direction based on requested item order
    if (data && data.length - 1 < page) {
      // reached end of collection so reset to first item
      dir = 1;
      page = 0;
    }
    if (page < 0) {
      // reached the begging of the collection so go to last item
      dir = -1;
      page = data.length - 1;
    }
    dispatch({ type: 'SET_DIRECTION', payload: dir });
    dispatch({ type: 'SET_ACTIVE_INDEX', payload: page });
  };

  return (
    <div className="overflow-hidden m-[-16px] px-4">
      <AnimatePresence initial={false} custom={direction}>
        <TestimonialCard
          data={data}
          activeIndex={activeIndex}
          direction={direction}
          dispatch={dispatch}
          isDragging={false}
          handledDragEnd={handledDragEnd}
          navigate={navigate}
        />
      </AnimatePresence>

      <TestimonialIcons data={data} activeIndex={activeIndex} handleClick={handleClick} />
    </div>
  );
};
