export const motionConfig = {
  spring: { type: 'spring', stiffness: 500, damping: 24, mass: 1 },
  easeInOut: { ease: 'easeInOut' },
  easIn: { ease: [0.95, 0.02, 0.56, 1] },
  themeChange: { ease: [0.95, 0.02, 0.56, 1], delay: 0.4, duration: 0.35 },
  linear: { ease: 'linear' },
};

export const ButtonScaleMotion = {
  hoverScale: 1.04,
  pressScale: 0.98,
  spring: { type: 'spring', stiffness: 500, damping: 24, mass: 1 },
};
