import styled from 'styled-components';
import { colors, fontFamily } from 'utils/tailwindConfig';
import Icon, { ico } from 'components/Icon/Icon';

export type SurveyBackAndNextButtonProps = {
  onNextClick?: () => void;
  onBackClick?: () => void;
  disabled?: boolean;
  instanceId?: string;
};

function SurveyBackAndNextButton({ onBackClick, onNextClick, disabled, instanceId }: SurveyBackAndNextButtonProps) {
  return (
    <>
      <div className="flex flex-row items-start justify-start gap-4 p-0 mt-10">
        <StyledBackButton
          id={`${instanceId}-BackButton`}
          data-testid={`${instanceId}-BackButton`}
          onClick={(e) => {
            e.preventDefault();
            onBackClick?.();
          }}
        >
          <Icon name={ico.carretUp} theme="white" size={24} />
          Back
        </StyledBackButton>
        <StyledNextButton
          type="submit"
          id={`${instanceId}-NextButton`}
          data-testid={`${instanceId}-NextButton`}
          disabled={disabled}
          onClick={onNextClick}
        >
          Next
        </StyledNextButton>
      </div>
    </>
  );
}

export default SurveyBackAndNextButton;

const StyledBackButton = styled.button`
  font-family: ${fontFamily['polysans-neutral']};
  font-size: 16px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 46px;
  width: auto;
  padding: 4px 20px;
  white-space: nowrap;
  border: 1px solid ${colors.grey[400]};
  border-radius: 8px;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  color: ${colors['survey-button-text']};
  transform: none;
  background-color: ${colors.grey[400]};
`;

const StyledNextButton = styled(StyledBackButton)`
  width: 200px;
  border: 1px solid ${colors.grey[200]};
  cursor: pointer;
  background-color: ${colors.grey[400]};

  &:disabled {
    cursor: not-allowed;
    color: ${colors.grey[700]};
    cursor: not-allowed;
    background-color: ${colors.white};
    border: 1px solid ${colors.grey[200]};
  }
`;
