import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from 'utils/tailwindConfig';
export const LandingHeader = styled.header`
  @media screen and (min-width: 768px) {
    display: none;
  }
  box-sizing: border-box;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0px 24px;
  top: 0px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  z-index: 50;
  color: ${colors.grey[1000]};
  border-color: ${colors.grey[200]};
  background-color: ${colors.white};
`;

export const Container = styled.div`
  display: grid;
  height: 100vh;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
`;

export const LogoRow = styled.div`
  padding-bottom: 64px;
  padding-top: 22px;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ProspectCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: 768px) {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 480px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }
`;

export const RightSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 48px;
  padding: 80px 32px;
  justify-content: center;

  @media screen and (min-width: 1488px) {
    margin-right: 96px;
    align-self: flex-start;
    margin-left: 96px;
    min-width: 0px;
  }

  @media screen and (min-width: 768px) {
    align-self: auto;
    height: 100vh;
  }
  h2 {
    font-family: ${fontFamily.sans};
    font-size: 28px;
    font-style: normal;
    font-weight: ${fontWeight.normal};
    line-height: 120%;
    letter-spacing: 0.24px;
    padding: 0px;
    margin: 0px;
    text-align: center;
  }
`;

export const LeftSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
  padding: 48px 24px 64px;

  background-color: ${colors.primary[500]};
  color: ${colors.white};

  @media screen and (max-width: 768px) {
    order: 1;
    padding-top: 0;
    padding: 0 24px;
    padding-bottom: 48px;
  }

  @media screen and (min-width: 767px) {
    flex: 1 1 0%;
    align-self: baseline;
    padding: 0 32px;
    height: 100%;
  }

  h3 {
    font-family: ${fontFamily.sansslim};
    font-size: 24px;
    font-style: normal;
    font-weight: ${fontWeight.normal};
    line-height: 120%;
    letter-spacing: 0.24px;
    padding: 0px;
    margin: 0px;
    @media screen and (min-width: 768px) {
      margin-bottom: 16px;
    }
  }
  ul {
    li {
      display: flex;
      align-items: center;
      font-family: ${fontFamily.basier};
      font-size: 18px;
      font-style: normal;
      font-weight: ${fontWeight.normal};
      letter-spacing: -0.18px;
      list-style-type: circle;
      line-height: 1.2em;
      margin-top: 8px;
    }
  }
`;

export const DescriptionContainer = styled.div`
  max-width: 544px;
  min-height: 580px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  gap: 16px;
  padding-top: 80px;
  padding-left: 60px;
  padding-bottom: 16px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    padding-top: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const TestimonialContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 317px;
  display: flex;
  overflow-wrap: anywhere;
`;
