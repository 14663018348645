import { FunctionComponent } from 'react';
import ConfigureIntro from './Survery/ConfigureIntro/ConfigureIntro';
import { PrimaryBusinessGoal } from './Survery/PrimaryBusinessGoal/PrimaryBusinessGoal';
import { NewQuote } from './Survery/Quote/NewQuote';
import { CompanySizeAndIndustry } from './Survery/CompanySizeAndIndustry/CompanySizeAndIndustry';
import { CountriesOrMarket } from './Survery/CountriesOrMarket/CountriesOrMarket';
import { NumberOfTeamMembers } from './Survery/NumberOfTeamMembers/NumberOfTeamMembers';
import { Timeline } from './Survery/Timeline/Timeline';
import { Priorities } from './Survery/Priorities/Priorities';
import { Countries } from './Survery/Countries/Countries';
import { HowManyTeamMembers } from './Survery/HowManyTeamMeambers/HowManyTeamMembers';
import { RecruitingNeeds } from './Survery/RecruitingNeeds/RecruitingNeeds';
import { WhenDoYouWantProjectToStart } from './Survery/WhenDoYouWantProjectToStart/WhenDoYouWantProjectToStart';
import { PrioritiesAndFocusArea } from './Survery/PrioritiesAndFocusArea/PrioritiesAndFocusArea';
import { InternationalTeamMembers } from './Survery/InternationTeamMembers/InternationalTeamMembers';
import { WhereAreYourTeamMemberRelocatingFrom } from './Survery/WhereAreYourTeamMemberRelocatingFrom/WhereAreYourTeamMemberRelocatingFrom';
import { WhereAreYourTeamMemberRelocatingTo } from './Survery/WhereAreYourTeamMemberRelocatingTo/WhereAreYourTeamMemberRelocatingTo';
import { RelocationTimeLine } from './Survery/RelocationTimeLine/RelocationTimeLine';
import { RelocationTemporaryOrPermanent } from './Survery/RelocationTemporaryOrPermanent/RelocationTemporaryOrPermanent';
import { RelocationFocusArea } from './Survery/RelocationFocusArea/RelocationFocusArea';
import { ReasonForSwitch } from './Survery/ReasonForSwitch/ReasonForSwitch';
import { CurrentEOR } from './Survery/CurrentEOR/CurrentEOR';
import { TeamMembersManagedWithCurrentEOR } from './Survery/TeamMembersManagedWithCurrentEOR/TeamMembersManagedWithCurrentEOR';
import { CompleteSwitchingEOR } from './Survery/CompleteSwitchingEOR/CompleteSwitchingEOR';
import { SwitchPrioritiesAndFocusAreas } from './Survery/SwitchPrioritiesAndFocusAreas/SwitchPrioritiesAndFocusAreas';
import { SubsidiaryLocation } from './Survery/SubsidiaryLocation/SubsidiaryLocation';
import { TransitionTeamMembers } from './Survery/TransitionTeamMembers/TransitionTeamMembers';
import { TransitionTimeLine } from './Survery/TransitionTimeLine/TransitionTimeLine';
import { BusinessObjectiveEOR } from './Survery/BusinessObjectEOR/BusinessObjectiveEOR';
import { TransactionPrioritiesAndFocusArea } from './Survery/TransactionPrioritiesAndFocusArea/TransactionPrioritiesAndFocusArea';
import { TransitionTemporaryMembersToPermanentRoles } from './Survery/TransitionTemporaryMembersToPermanentRoles/TransitionTemporaryMembersToPermanentRoles';
import { ManageGlobalContractor } from './Survery/ManagerGlobalContractor/ManagerGlobalContractor';
import { MergersAndAcquisitions } from './Survery/ManageContractors/MergersAndAcquisitions';
import { Other } from './Survery/Other/Other';
import { ContactForm } from './Survery/ContactForm/ContactForm';

export const BranchPagesRecord = {
  '/transition': 0.1,
  '/contractors': 0.2,
  '/manda': 0.3,
  '/other': 0.4,
} as const;

export const BranchPagesName = {
  transition: 'transition',
  contractor: 'contractor',
  manda: 'manda',
  other: 'other',
};

export const branchPagesTranslations = {
  [BranchPagesRecord['/transition']]: 'Transition temporary members to permanent roles',
  [BranchPagesRecord['/contractors']]: 'Manage Contractors',
  [BranchPagesRecord['/manda']]: ' Merges and Acquisitions',
  [BranchPagesRecord['/other']]: 'Other scenarios',
} as const;

export const ConfigureStepRecord = {
  intro: 0,
  TalktoAnExpert: 0.1,
  ManageGlobalContractor: 0.2,
  MergersAndAcquisitions: 0.3,
  Other: 0.4,
  ContactForm: 0.5,
  quote: 0.6,
  primaryBusinessGoal: 1,
  companySizeAndIndustry: 2,
  countriesOrMarket: 3,
  numberOfTeamMembers: 4,
  timeline: 5,
  priorities: 6,
  countries: 8,
  HowManyTeamMembers: 9,
  RecruitingNeeds: 10,
  WhenDoYouWantProjectToStart: 11,
  PrioritiesAndFocusArea: 12,
  InternationalTeamMembers: 13,
  WhereAreYourTeamMemberRelocatingFrom: 14,
  WhereAreYourTeamMemberRelocatingTo: 15,
  RelocationTimeLine: 16,
  RelocationTemporaryOrPermanent: 18,
  ReasonForSwitch: 19,
  CurrentEOR: 21,
  TeamMembersManagedWithCurrentEOR: 22,
  CompleteSwitchingEOR: 23,
  SwitchPrioritiesAndFocusAreas: 24,
  SubsidiaryLocation: 25,
  TransitionTeamMembers: 26,
  TransitionTimeLine: 27,
  BusinessObjectiveEOR: 29,
  TransactionPrioritiesAndFocusArea: 30,
  RelocationFocusArea: 31,
} as const;

export type ConfigureStepRecordKey = keyof typeof ConfigureStepRecord;
export type ConfigureStepRecordValue = (typeof ConfigureStepRecord)[keyof typeof ConfigureStepRecord];

type ConfigurePages = {
  [key in ConfigureStepRecordValue]: FunctionComponent<any>;
};

export const ConfigurePageRecord: ConfigurePages = {
  [ConfigureStepRecord.intro]: ConfigureIntro,
  [ConfigureStepRecord.primaryBusinessGoal]: PrimaryBusinessGoal,
  [ConfigureStepRecord.companySizeAndIndustry]: CompanySizeAndIndustry,
  [ConfigureStepRecord.countriesOrMarket]: CountriesOrMarket,
  [ConfigureStepRecord.numberOfTeamMembers]: NumberOfTeamMembers,
  [ConfigureStepRecord.timeline]: Timeline,
  [ConfigureStepRecord.priorities]: Priorities,
  [ConfigureStepRecord.countries]: Countries,
  [ConfigureStepRecord.HowManyTeamMembers]: HowManyTeamMembers,
  [ConfigureStepRecord.RecruitingNeeds]: RecruitingNeeds,
  [ConfigureStepRecord.WhenDoYouWantProjectToStart]: WhenDoYouWantProjectToStart,
  [ConfigureStepRecord.PrioritiesAndFocusArea]: PrioritiesAndFocusArea,
  [ConfigureStepRecord.InternationalTeamMembers]: InternationalTeamMembers,
  [ConfigureStepRecord.WhereAreYourTeamMemberRelocatingFrom]: WhereAreYourTeamMemberRelocatingFrom,
  [ConfigureStepRecord.WhereAreYourTeamMemberRelocatingTo]: WhereAreYourTeamMemberRelocatingTo,
  [ConfigureStepRecord.RelocationTimeLine]: RelocationTimeLine,
  [ConfigureStepRecord.RelocationTemporaryOrPermanent]: RelocationTemporaryOrPermanent,
  [ConfigureStepRecord.RelocationFocusArea]: RelocationFocusArea,
  [ConfigureStepRecord.ReasonForSwitch]: ReasonForSwitch,
  [ConfigureStepRecord.CurrentEOR]: CurrentEOR,
  [ConfigureStepRecord.TeamMembersManagedWithCurrentEOR]: TeamMembersManagedWithCurrentEOR,
  [ConfigureStepRecord.CompleteSwitchingEOR]: CompleteSwitchingEOR,
  [ConfigureStepRecord.SwitchPrioritiesAndFocusAreas]: SwitchPrioritiesAndFocusAreas,
  [ConfigureStepRecord.TransitionTeamMembers]: TransitionTeamMembers,
  [ConfigureStepRecord.TransitionTimeLine]: TransitionTimeLine,
  [ConfigureStepRecord.SubsidiaryLocation]: SubsidiaryLocation,
  [ConfigureStepRecord.BusinessObjectiveEOR]: BusinessObjectiveEOR,
  [ConfigureStepRecord.TransactionPrioritiesAndFocusArea]: TransactionPrioritiesAndFocusArea,
  [ConfigureStepRecord.TalktoAnExpert]: TransitionTemporaryMembersToPermanentRoles,
  [ConfigureStepRecord.ManageGlobalContractor]: ManageGlobalContractor,
  [ConfigureStepRecord.MergersAndAcquisitions]: MergersAndAcquisitions,
  [ConfigureStepRecord.Other]: Other,
  [ConfigureStepRecord.ContactForm]: ContactForm,
  [ConfigureStepRecord.quote]: NewQuote,
};
