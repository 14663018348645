import { makeObservable, observable, runInAction } from 'mobx';
import RootStore from 'rootstore';
import { Direction } from './ProspectLayout.types';
import { HeaderProps } from 'components/Header/Header';
import { SidePanelInfoPageIndex } from 'components/Shared/InfoPanel/InfoPanel.util';
import { createProspect } from 'api/prospect';
import { ProspectResponse, ProspectType } from './types/Prospect.type';
import { identifyHeapUser } from 'utils/heap';

export default class ProspectLayoutStore {
  public asideIsOpen: boolean;
  public previousPage: number | undefined;
  public headerTheme: HeaderProps['theme'];
  public direction: Direction;
  public currentInfoPanelTopic: SidePanelInfoPageIndex | null;
  public prospectInformation: ProspectType;
  public stepsLabel: { [key: number]: string };
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      asideIsOpen: observable.ref,
      previousPage: observable.ref,
      direction: observable.ref,
      headerTheme: observable.ref,
      currentInfoPanelTopic: observable.ref,
      prospectInformation: observable.ref,
    });
    this.rootStore = rootStore;
    this.asideIsOpen = false;
    this.stepsLabel = {};
    this.headerTheme = undefined;
    this.direction = Direction.forward;
    this.currentInfoPanelTopic = null;
    this.prospectInformation = {} as ProspectType;
  }

  setSideDrawer = (isOpen: boolean): void => {
    runInAction(() => (this.asideIsOpen = isOpen));
  };

  setStepsLabel = (steps: { [key: number]: string }): void => {
    runInAction(() => (this.stepsLabel = steps));
  };

  setDirection = (direction: Direction): void => {
    runInAction(() => (this.direction = direction));
  };

  setHeaderTheme = (theme: HeaderProps['theme']): void => {
    runInAction(() => (this.headerTheme = theme));
  };

  setCurrentInfoPanelTopic = (topic: SidePanelInfoPageIndex | null): void => {
    runInAction(() => (this.currentInfoPanelTopic = topic));
  };

  clearCurrentInfoPanelTopic = (): void => {
    runInAction(() => (this.currentInfoPanelTopic = null));
  };

  setProspectInformation = (prospect: ProspectType): void => {
    runInAction(() => {
      this.prospectInformation = prospect;
    });
  };

  fetchProspectAsync = async () => {
    const prospectPayload: ProspectResponse = await createProspect();
    const {
      id,
      token,
      data: { questionary },
    } = prospectPayload.data;
    this.setProspectInformation({
      token,
      id,
      questionary,
    });
    identifyHeapUser({ prospectId: id });
  };

  getProspectId = () => {
    const userToken = this.prospectInformation.token;
    if (userToken && userToken.length > 0) return userToken;
    this.fetchProspectAsync();
  };

  clearProspectInformation = () => {
    this.prospectInformation = {} as ProspectType;
  };
}
