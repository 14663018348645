import styled from 'styled-components';
import { fontFamily } from 'utils/tailwindConfig';

export const ContentContainer = styled.div`
  padding-inline: 16px;
  @media screen and (max-width: 380px) {
    margin-inline: 0;
    padding-inline: 16px;
  }
  @media screen and (max-width: 450px) {
    padding-bottom: 96px;
  }
`;
export const StyledText = styled.p`
  font-family: ${fontFamily.basier};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 95%;
  letter-spacing: -0.18px;
  cursor: pointer;
  user-select: none;
  text-align: left;
  text-wrap: pretty;
  -webkit-user-drag: none;
`;

export const StyledInfoWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
`;
