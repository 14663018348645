import { FunctionComponent } from 'react';
import { SelectBoxWithFlag } from '../StyledSelectBox';
import { CircleFlag } from 'react-circle-flags';
import Icon, { ico } from 'components/Icon/Icon';
import SurveyBackAndNextButton from '../SurveyBackAndNextButton';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { observer } from 'mobx-react';
import { OverlayName } from '../Overlay/Overlay.type';
import { topCountries } from '../Overlay/Countries/Countries.utils';
import { useCountrySelection } from 'hooks/useCountrySelection';
import isoCodeMap from 'utils/IsoCode.utils.json';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';
import { toJS } from 'mobx';

type CountrySelectionProps = {
  pageNumber: number;
  buttonName: string;
  instanceId: string;
};

const CountrySelection: FunctionComponent<CountrySelectionProps> = observer(
  ({ pageNumber, buttonName, instanceId }) => {
    const {
      data,
      completedConfigureSteps,
      selectedCountries,
      additionalCountries,
      handleCountryClick,
      handleAdditionCountryClick,
      currentQuestionIndex,
      goToQuestionIndex,
      pushOverlay,
    } = useCountrySelection(pageNumber);

    const primaryGoal = toJS(completedConfigureSteps.get(1));
    const identifier = `${instanceId}-PG:${primaryGoal}-S:${pageNumber}`;

    return (
      <div className="survey-question-container" data-testid="country-selection-template">
        <p className="survey-question-title" data-testid={`${identifier}-title`}>
          {data.questions[0]?.title}
        </p>
        <div className="survey-question-description" data-testid={`${identifier}-description`}>
          {data.questions[0]?.description}
        </div>
        <div className="flex flex-col justify-start items-start gap-4 my-6 p-0">
          <section className="flex flex-wrap justify-start items-start gap-4">
            {topCountries.map((option) => (
              <SelectBoxWithFlag
                className="writing-text tag-pills gap-2 tabletDownwards:grow"
                id={`${identifier}-${option.label}`}
                data-testid={`${identifier}-${option.label}`}
                {...ButtonScaleEffectProps}
                key={option.option}
                name={buttonName}
                value={option.option}
                $checked={selectedCountries.includes(option.option!)}
                onClick={handleCountryClick}
              >
                <CircleFlag
                  data-testid={`${identifier}-${option.label}-flag`}
                  countryCode={option.isoCode!}
                  className="h-6"
                />
                {option.label}
              </SelectBoxWithFlag>
            ))}
            {additionalCountries.map((option) => (
              <SelectBoxWithFlag
                className="writing-text tag-pills gap-2 tabletDownwards:grow"
                {...ButtonScaleEffectProps}
                id={`${identifier}-${option}`}
                data-testid={`${identifier}-${option}`}
                key={option}
                name={buttonName}
                value={option}
                $checked={selectedCountries.includes(option)}
                onClick={handleAdditionCountryClick}
              >
                <CircleFlag
                  data-testid={`${identifier}-${option}-flag`}
                  countryCode={isoCodeMap[option as keyof typeof isoCodeMap]}
                  className="h-6"
                />
                {option}
              </SelectBoxWithFlag>
            ))}
          </section>
          <div className="flex flex-row justify-start items-center mb-4 p-0">
            <button
              id={`${identifier}-show-more-button`}
              data-testid={`${identifier}-show-more-button`}
              onClick={() => pushOverlay(OverlayName.CountriesModal)}
              className="flex gap-1 font-polysans-neutral text-primary-500 text-[14px] justify-center items-center hover:underline"
            >
              Show More <Icon name={ico.carretDown} theme="white" size={16} />
            </button>
          </div>
          <SurveyBackAndNextButton
            instanceId={identifier}
            disabled={selectedCountries.length === 0}
            onNextClick={() => goToQuestionIndex(currentQuestionIndex! + 1, Direction.forward)}
            onBackClick={() => goToQuestionIndex(currentQuestionIndex! - 1, Direction.back)}
          />
        </div>
      </div>
    );
  },
);

export default CountrySelection;
