import { Rule } from '../types/OnboardingSteps.types';

export interface Option {
  option: string;
  label: string;
  id: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  optionId: number;
  questionId: number;
}

export interface CombinedData {
  nextSteps: number[];
  label: string;
  option: Option | undefined;
  redirect: string;
}

export const useCombinedRulesAndOptions = (
  rules: Rule[] | undefined,
  options: Option[] | undefined,
): CombinedData[] => {
  if (!rules || !options) {
    throw new Error('Rules or options are undefined');
  }

  const combinedData: CombinedData[] = [];

  rules.forEach((rule) => {
    const option = options.find((opt) => rule.criteria === opt.option);
    if (option) {
      combinedData.push({
        nextSteps: rule.nextSteps,
        label: option.label,
        option: option,
        redirect: rule.redirect || '',
      });
    }
  });

  return combinedData;
};
