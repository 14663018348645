import { motion } from 'framer-motion';
import { CmpData } from '../Testimonial.types';
import { ButtonScaleMotion } from 'utils/tailwindConfig/motionConfig';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

type TestimonialIconsProps = {
  data: CmpData[];
  activeIndex: number;
  handleClick: (index: number) => void;
};

const TestimonialIcons: FunctionComponent<TestimonialIconsProps> = ({ data, activeIndex, handleClick }) => {
  return (
    <div className="relative flex gap-6 flex-wrap justify-center gap-y-4">
      {data.map((item, index) => {
        return (
          <StyledImageWrapper
            key={index}
            $active={activeIndex === index}
            data-testid={`testimonial-companyLogo-${index}`}
          >
            <StyledLogo
              src={item.imgSrc}
              alt={item.altText}
              onClick={() => handleClick(index)}
              data-testid={`companyLogo-${index}`}
            />
          </StyledImageWrapper>
        );
      })}
    </div>
  );
};
export default TestimonialIcons;

const hightlightImage = css`
  transition: all 200ms ease-in;
  transform: scale(1.2);
  z-index: 2;
`;

const StyledImageWrapper = styled(motion.div).attrs({
  transition: ButtonScaleMotion.spring,
})<{ $active: boolean }>`
  position: relative;
  display: flex;
  img {
    ${(props) => props.$active && `${hightlightImage} opacity: 1;`}
  }
`;

const StyledLogo = styled.img`
  opacity: 0.4;
  cursor: pointer;
  &:hover {
    ${hightlightImage}
    z-index: 3;
  }
`;
