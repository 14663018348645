import { motion } from 'framer-motion';
import styled from 'styled-components';
import { hexToRgba } from 'utils/helpers';
import { colors, fontFamily } from 'utils/tailwindConfig';

export const ModalContainer = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
`;

export const ModalBoundary = styled(motion.div)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: clamp(300px, 85vw, 600px);
  border-radius: 16px;
  box-shadow: ${hexToRgba(colors.black, 0.1)} 0px 5px 30px 5px;
  background-color: ${colors.grey[100]};
  pointer-events: auto;
  overflow: hidden;
`;

export const ModalHeader = styled.header`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${colors.grey[1000]};
  margin: 0px;
  width: 100%;
  padding: 32px 32px 16px;
`;

export const ModalBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  margin: 0px;
  width: 100%;
  flex: 1 1 0%;
  max-height: 550px;
  overflow: hidden;
  padding: 0px;
`;

export const ModalContentLayout = styled.div`
  font-family: ${fontFamily.basierCircleRegular};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.18px;
  padding: 0px;
  margin: 0px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
  height: 500px;
`;

export const ModalFooter = styled.footer`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 16px 32px;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;

export const FootButton = styled.button`
  font-family: ${fontFamily['polysans-neutral']};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 46px;
  width: 150px;
  padding: 4px 20px;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  -webkit-user-drag: none;
  user-select: none;
  cursor: pointer;
  background-color: ${colors.grey[400]};
  color: ${colors['survey-button-text']};
  border-color: ${colors.grey[400]};
  transform: none;
`;

export const HeaderTitle = styled.span`
  font-size: 18px;
  font-style: normal;
  font-family: ${fontFamily.basier};
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.18px;
  margin: 0px;
  padding: 0px;
  text-align: center;
`;
