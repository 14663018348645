import { motionConfig } from 'utils/tailwindConfig/motionConfig';
import {
  ModalContainer,
  ModalBoundary,
  ModalHeader,
  HeaderTitle,
  ModalBody,
  ModalContentLayout,
  ModalFooter,
  FootButton,
} from './CountriesModal.styled';
import { FunctionComponent } from 'react';
import { BackDrop } from 'components/Layout/ProspectLayout.styled';
import { motion } from 'framer-motion';
import { SearchBarRowComponent } from './components/SearchBarRow';
import { CountriesList } from './components/CountriesList';
import { useCountries } from './useCountries';
import { observer } from 'mobx-react';

export const CountriesModal: FunctionComponent = observer(() => {
  const { id, selectedCountries, handleInputChange, filteredCountries, handleCountryClick, popOverlay, onDone } =
    useCountries();

  return (
    <motion.div
      data-testid="countries-modal"
      key={'overlay'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ...motionConfig.easeInOut, duration: 0.15 }}
    >
      <BackDrop $show={true} $showOnlyOnTabletDownward={false} $zIndex={100} onClick={popOverlay} />
      <ModalContainer>
        <ModalBoundary
          key={id}
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          exit={{ opacity: 0.5 }}
          transition={{ ...motionConfig.spring }}
        >
          <ModalHeader>
            <button className="absolute top-7 right-7 opacity-20" onClick={popOverlay}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <g>
                  <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
                  <path
                    d="M 14.83 9.17 L 9.17 14.83 M 14.83 14.83 L 9.17 9.17 M 12 21 L 12 21 C 7.029 21 3 16.971 3 12 L 3 12 C 3 7.029 7.029 3 12 3 L 12 3 C 16.971 3 21 7.029 21 12 L 21 12 C 21 16.971 16.971 21 12 21 Z"
                    fill="transparent"
                    strokeWidth="1"
                    stroke="rgba(39, 46, 53, 1)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray=""
                  ></path>
                </g>
              </svg>
            </button>
            <HeaderTitle>Select Countries</HeaderTitle>
          </ModalHeader>
          <ModalBody>
            <ModalContentLayout>
              <SearchBarRowComponent
                handleInputChange={handleInputChange}
                selectedCountries={selectedCountries}
                handleCountryClick={handleCountryClick}
              />
              <CountriesList
                filteredCountries={filteredCountries}
                selectedCountries={selectedCountries}
                handleCountryClick={handleCountryClick}
              />
              <ModalFooter>
                <FootButton onClick={popOverlay}>Cancel</FootButton>
                <FootButton onClick={onDone}>Done</FootButton>
              </ModalFooter>
            </ModalContentLayout>
          </ModalBody>
        </ModalBoundary>
      </ModalContainer>
    </motion.div>
  );
});
