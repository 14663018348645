import { AvailabilityResponse } from 'pages/NewTalk/Talk.types';
import { BASE_API_URL, TIMEZONE } from 'utils/constants';

/**
 * Checks availability asynchronously for a prospect meeting.
 * @param id
 * @param from
 * @param to
 * @param token
 * @returns
 */
export const checkAvailabilityAsync = async (
  id: string,
  from: string,
  to: string,
  token: string,
  fetchFunction = fetch,
) => {
  const url = `${BASE_API_URL.ProspectApiUrl}/${id}/lead/meeting/availability?to=${to}&from=${from}&tz=${TIMEZONE}`;
  try {
    const response = await fetchFunction(url, {
      headers: {
        'x-prospect-token': token,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }
    const payload: AvailabilityResponse = await response.json();
    return payload;
  } catch (error) {
    console.error('Error fetching availability:', error);
  }
};
