import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { colors, fontFamily } from 'utils/tailwindConfig';

export const IntroCTAStyles = css`
  font-family: ${fontFamily['polysans-neutral']};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 52px;
  width: 300px;
  padding: 0px 24px;
  white-space: nowrap;
  border: 1px solid ${colors.primary[500]};
  background-color: ${colors.white};
  border-radius: 100px;
  -webkit-user-drag: none;
  user-select: none;
  cursor: pointer;
`;

export const StyledButton = styled(motion.button)`
  color: ${colors.primary[500]};
  ${IntroCTAStyles};
`;
