import React, { FunctionComponent, useEffect } from 'react';
import {
  ButtonBack,
  ButtonBackIcon,
  ButtonCall,
  ButtonQuit,
  ButtonTalk,
  Container,
  LeftWrapper,
  RightWrapper,
  StyledLogo,
  themeColors,
} from './Header.styled';
import Logo from './component/Logo';
import { useNavigate } from 'react-router-dom';
import { useAnimationControls } from 'framer-motion';
import { motionConfig } from 'utils/tailwindConfig/motionConfig';
import UIButton from 'components/UIButton/UIButton';
import { ico } from 'components/Icon/Icon';
import { useRootStore } from 'contexts/StoreContext';
import { observer } from 'mobx-react';
import { ConfigureStepRecord } from 'pages/NewConfigure/Configure.util';

export type HeaderProps = {
  theme?: keyof typeof themeColors;
  context?: 'home' | 'quote' | 'contact';
  instanceId?: string;
};

const Header: FunctionComponent<HeaderProps> = observer(({ theme = 'white', context = 'home', instanceId }) => {
  const navigate = useNavigate();
  const controls = useAnimationControls();
  const {
    talkStore: { TalkToAnExpertIntroData: intro },
    configurePageStore: { resetStore, setCurrentPage: setConfigurePage, setCurrentQuestionIndex },
  } = useRootStore();

  useEffect(() => {
    controls.start(themeColors[theme]);
  }, [theme, controls]);

  const showButton = (buttonType: string) => {
    if (context === 'home' && buttonType === 'quit') return true;
    if (context !== 'home' && buttonType === 'back') return true;
    if (context === 'quote' && buttonType === 'talk') return true;
    if (context === 'contact' && buttonType === 'call') return true;
    return false;
  };

  const goBack = () => {
    // navigate to homepage
    navigate('/');
  };
  const redirectToTalkToAnExpert = () => {
    //Clears the nextSteps, forget the completed steps answers, forgets the branch page is any.
    resetStore();
    //Reset the configure page to intro
    setConfigurePage(ConfigureStepRecord.intro);
    //Reset the question index back to undefined
    setCurrentQuestionIndex(undefined);
    //Navigate to talk page
    navigate('/talk');
  };

  return (
    <Container
      $theme={theme}
      $context={context}
      variants={themeColors}
      initial={themeColors[theme]}
      animate={controls}
      transition={{ ...motionConfig.themeChange }}
    >
      <LeftWrapper $show={context !== 'home'}>
        <ButtonBack $show={showButton('back')}>
          <UIButton
            icon={ico.arrowLeft}
            theme={theme}
            label={'Back'}
            size={'regular'}
            type={'primary'}
            onClick={goBack}
            instanceId={`${instanceId}-BackButton`}
          />
        </ButtonBack>
        <ButtonBackIcon $show={showButton('back')} id={`${instanceId}-BackIcon`} data-testid={`${instanceId}-BackIcon`}>
          <UIButton icon={ico.arrowLeft} theme={theme} size={'regular'} type={'unframed'} onClick={goBack} />
          <Logo theme={theme} onClick={goBack} />
        </ButtonBackIcon>
      </LeftWrapper>

      <StyledLogo $context={context} data-testid={`${instanceId}-Logo`}>
        <Logo theme={theme} />
      </StyledLogo>

      <RightWrapper>
        <ButtonQuit $show={showButton('quit')} id={`${instanceId}-QuitButton`} data-testid={`${instanceId}-QuitButton`}>
          <UIButton icon={ico.x} size={'large'} type={'unframed'} onClick={goBack} theme={theme} />
        </ButtonQuit>
        <ButtonTalk
          $show={showButton('talk')}
          id={`${instanceId}-TalkToAnExpertButton`}
          data-testid={`${instanceId}-TalkToAnExpertButton`}
        >
          <UIButton
            showicon={false}
            label={'Talk to an expert'}
            theme={theme}
            type={'primary'}
            onClick={redirectToTalkToAnExpert}
          />
        </ButtonTalk>
        <ButtonCall
          $show={showButton('call')}
          id={`${instanceId}-TollFreeNumberButton`}
          data-testid={`${instanceId}-TollFreeNumberButton`}
        >
          <UIButton showicon={false} label={intro.tollFreeNumber} theme={theme} type={'primary'} />
        </ButtonCall>
      </RightWrapper>
    </Container>
  );
});

export default Header;
