import { ButtonScaleMotion } from './motionConfig';

export const ButtonScaleEffectProps = {
  transition: ButtonScaleMotion.spring,
  whileHover: {
    scale: ButtonScaleMotion.hoverScale,
  },
  whileTap: {
    scale: ButtonScaleMotion.pressScale,
  },
};

export const ToolTipRevealProps = {
  variants: {
    open: {
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
      transition: {
        type: 'spring',
        bounce: 0,
        duration: 0.7,
        delayChildren: 0.3,
        staggerChildren: 0.05,
      },
    },
    closed: {
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
      transition: {
        type: 'spring',
        bounce: 0,
        duration: 0.3,
      },
    },
  },
};
