import { useEffect, MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'contexts/StoreContext';

import Icon from 'components/Icon/Icon';
import { TitleSubTitleInfoPanelTrigger } from 'components/Shared/TitleSubTilteInfoPanelTrigger';
import { EventInfo } from './ScheduleMeeting.utils';
import { twMerge } from 'tailwind-merge';
import { DirectionButtons } from './components/DirectionButtons';
import { WeeklyCalendar } from 'components/Shared/WeeklyCalendar/WeeklyCalendar';
import { ScheduleMeetingContainer } from './ScheduleMeeting.styled';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import { SidePanelInfoContentModel } from 'components/Shared/InfoPanel/InfoPanel.util';
import { useSideDrawer } from 'hooks/useSideDrawer';
import { eliminateSpace } from 'utils/helpers';

export const ScheduleMeeting = observer(() => {
  const {
    talkStore: { fetchAvailability, overload },
  } = useRootStore();

  useEffect(() => {
    fetchAvailability();
  }, [fetchAvailability, overload]);

  const { onTriggerClick } = useSideDrawer();

  const handleSidePanelTriggerClick = (event: MouseEvent<HTMLSpanElement>) => {
    const { id } = event.currentTarget;
    onTriggerClick(SidePanelInfoContentModel[id as keyof typeof SidePanelInfoContentModel]);
  };

  return (
    <div className="survey-question-container">
      <TitleSubTitleInfoPanelTrigger
        identifier={ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME_INFO_PANEL_TRIGGER_BUTTON}
        title="Select a date and time to talk to a G-P expert."
        description="We'll schedule a meeting and send you an invitation."
        onClickHandler={handleSidePanelTriggerClick}
      />
      <ScheduleMeetingContainer className="flex flex-col items-start justify-start gap-4 my-6 p-0">
        <div className="container_wrapper flex flex-row box-border max-w-[868px] w-full gap-[88px]">
          <div className="container-row flex flex-col box-border max-w-[225px] gap-6">
            <div className="writing-text p-0 m-0 text-grey-800 flex flex-col items-start justify-start gap-1">
              {EventInfo.map(({ name, text }) => (
                <span
                  key={text}
                  className="flex gap-1"
                  data-testid={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME}-${eliminateSpace(name)}-icon`}
                >
                  <Icon name={name} theme="white" size={22} />
                  {text}
                </span>
              ))}
            </div>
            <h1
              className={twMerge('writing-text', 'font-basierslim text-[20px] leading-[1.2em] m-0 p-0')}
              data-testid={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME}-meeting-title`}
            >
              Intros and Q&A
            </h1>
            <p
              className="'writing-text font-basier text-[18px] text-grey-800 whitespace-pre-wrap m-0 p-0"
              data-testid={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME}-meeting-description`}
            >
              Introductions to G-P, general Q&amp;A as well as discussion of the project you have in mind.
              <br />
              <br />
              Note: While all calls are video enabled you're not required to turn on your camera. Video simply makes
              sharing documents and other information easier for everyone.
            </p>
          </div>
          <div className="flex flex-col box-border items-start justify-start grow shrink-0 basis-0">
            <div className="box-border flex justify-end items-end">
              <DirectionButtons instanceId={ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME} />
            </div>
            <WeeklyCalendar />
          </div>
        </div>
      </ScheduleMeetingContainer>
    </div>
  );
});
