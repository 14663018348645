import { useEffect, useReducer } from 'react';
import { TestimonialInitialState, TestimonialReducer } from '../Testimonial.reducer';
import { CmpData } from '../Testimonial.types';
import { Article } from 'pages/NewLanding/Landing.type';

const useCmpData = (articles: Article[]): CmpData[] => {
  const [state, dispatch] = useReducer(TestimonialReducer, TestimonialInitialState);

  useEffect(() => {
    if (articles?.length) {
      const tmp: CmpData[] = articles.map((p: Article) => {
        const author = p.author || {};
        const altText = `${author.name || ''}, ${author.role || ''} at ${author.company || ''}`;
        return {
          id: author.company || undefined,
          text: p.description || '',
          name: `${author.name || ''}, ${author.role || ''}`,
          imgSrc: author.companyLogo?.url || undefined,
          altText,
        };
      });
      dispatch({ type: 'SET_DATA', payload: tmp });
    } else {
      dispatch({ type: 'RESET_DATA' });
    }
  }, [articles]);

  return state.testimonialData;
};

export default useCmpData;
