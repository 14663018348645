import jumbotronImage from 'assets/images/configure/jumbotron-talk-page-image.png';
import CheckIcon from 'assets/images/configure/check-tick-grey-100.svg';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { useRootStore } from 'contexts/StoreContext';
import { useEffect } from 'react';
import { isObjectEmpty } from 'utils/helpers';
import { observer } from 'mobx-react';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';
import Icon, { ico } from 'components/Icon/Icon';
import { ActionButtonRow, Delimiter, TalkIntroCTA } from 'pages/NewTalk/Talk.styled';
import { Jumbotron } from 'components/Shared/Jumbotron/Jumbotron';
import { TalkFlowMode, TalkStepRecord } from 'pages/NewTalk/Talk.util';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

export const TalkIntro = observer(() => {
  const {
    talkStore: {
      TalkToAnExpertIntroData: intro,
      isLoading,
      error,
      setCurrentPage,
      setTalkFlowMode,
      getTalkToAnExpertIntroContentData,
    },
    prospectLayoutStore: { setDirection, fetchProspectAsync },
  } = useRootStore();

  const isDataReady = !isObjectEmpty(intro) && !isLoading && !error;

  useEffect(() => {
    getTalkToAnExpertIntroContentData();
    fetchProspectAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="flex flex-col self-stretch flex-auto w-full text-grey-1000">
      <Jumbotron
        instanceId={ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_INTRO_PAGE}
        jumbotronImage={jumbotronImage}
        isDataReady={isDataReady}
        introData={intro}
        listIcon={CheckIcon}
        heroImage="justify-start"
        contentRow="flex-row-reverse"
        container="bg-warning-200"
      />

      <ActionButtonRow className="flex justify-center items-center px-8 py-[74px] gap-6">
        <TalkIntroCTA
          {...ButtonScaleEffectProps}
          id={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_INTRO_PAGE}-GetACallBackNow-CTA`}
          data-testid={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_INTRO_PAGE}-GetACallBackNow-CTA`}
          onClick={() => {
            setTalkFlowMode(TalkFlowMode.GET_A_CALL_BACK_NOW);
            setCurrentPage(TalkStepRecord.getACallBackNow);
            setDirection(Direction.forward);
          }}
        >
          <Icon name={ico.callBack} size={24} theme="orange" />
          Get a callback
        </TalkIntroCTA>

        <Delimiter data-testid={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_INTRO_PAGE}-separator`}>or</Delimiter>

        <TalkIntroCTA
          {...ButtonScaleEffectProps}
          id={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_INTRO_PAGE}-ScheduleAMeeting-CTA`}
          data-testid={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_INTRO_PAGE}-ScheduleAMeeting-CTA`}
          disabled={!isDataReady}
          onClick={() => {
            setTalkFlowMode(TalkFlowMode.SCHEDULE_A_MEETING);
            setCurrentPage(TalkStepRecord.scheduleAMeeting);
            setDirection(Direction.forward);
          }}
        >
          <Icon name={ico.calendar} size={24} theme="orange" />
          Schedule a call
        </TalkIntroCTA>
      </ActionButtonRow>
    </section>
  );
});
