import React, { MouseEvent, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'contexts/StoreContext';
import { TalkStepRecord } from 'pages/NewTalk/Talk.util';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { staticTimeSlots } from './staticDates';
import WeekSlotsRenderer from './component/WeekSlotsRenderer';

type WeeklyCalendarProps = {};

export type InitialState = { selectedElementId: string; selectedElementValue: string };
const initialState: InitialState = { selectedElementId: '', selectedElementValue: '' };

export const WeeklyCalendar: React.FunctionComponent<WeeklyCalendarProps> = observer(() => {
  const {
    talkStore: { setTimeSlot, setCurrentPage, availableTimeSlots, selectedTimeSlot },
    prospectLayoutStore: { setDirection },
  } = useRootStore();

  const [selectedTime, setSelectedTime] = useState(initialState);

  const onPillClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setTimeSlot(null); // clear the previous selected slot in store
      const { id, value } = event.currentTarget;
      const payload = { selectedElementId: id, selectedElementValue: value };
      setSelectedTime((prevState) => {
        if (prevState.selectedElementId === id) {
          return initialState;
        }
        return payload;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCancelClick = useCallback(() => {
    setSelectedTime(initialState);
    setTimeSlot(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { value } = event.currentTarget;
      setTimeSlot(value);
      setCurrentPage(TalkStepRecord.contactInformation);
      setDirection(Direction.forward);
    },
    [setCurrentPage, setDirection, setTimeSlot],
  );

  const PillProps = {
    selectedTime: selectedTime,
    staticTimeSlots: staticTimeSlots,
    setSelectedTime: setSelectedTime,
    selectedTimeSlot: selectedTimeSlot!,
    onPillClick: onPillClick,
    onCancelClick: onCancelClick,
    onConfirmClick: onConfirmClick,
  };

  return (
    <div
      className="flex justify-start items-start flex-col box-border gap-8 pt-8 w-full"
      data-testid="weekly-calendar-container"
    >
      <WeekSlotsRenderer availableTimeSlots={availableTimeSlots} {...PillProps} />
    </div>
  );
});
