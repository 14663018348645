import configure from 'assets/images/landing/des-configure-card-image.png';
import configureMobile from 'assets/images/landing/des-configure-card-image-mobile.png';
import talkToExpert from 'assets/images/landing/des-talk-to-expert-card-image.png';
import talkToExpertMobile from 'assets/images/landing/des-talk-to-expert-card-image-mobile.png';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

export const ProspectCardConfig = [
  {
    key: 'configure-card',
    id: ElementIdentifiersRecord.LANDING_PAGE_GET_A_PRICE_QUOTE_BUTTON,
    mobileViewImage: configureMobile,
    desktopViewImage: configure,
    route: '/configure',
  },
  {
    key: 'talk-to-expert-card',
    id: ElementIdentifiersRecord.LANDING_PAGE_TALK_TO_AN_EXPERT_BUTTON,
    mobileViewImage: talkToExpertMobile,
    desktopViewImage: talkToExpert,
    route: '/talk',
  },
];
