import { FunctionComponent, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import Button from 'components/Atomic/Button/Button';

import DoneCheck from 'components/Animated/DoneCheck';
import { ContactWhite, DownloadBlue, Information } from 'components/Atomic/Icon';
import Typography from 'components/Atomic/Typography/Typography';
import { useRootStore } from 'contexts/StoreContext';
import { useSideDrawer } from 'hooks/useSideDrawer';
import { SidePanelInfoContentModel } from 'components/Shared/InfoPanel/InfoPanel.util';
import { observer } from 'mobx-react';
import { Quotes } from './components/QuoteCards';
import styled from 'styled-components';
import { isObjectEmpty } from 'utils/helpers';

export const NewQuote: FunctionComponent = observer(() => {
  const [showQuote, setShowQuote] = useState(false);
  const navigate = useNavigate();
  const {
    configurePageStore: { resetStore, resetCurrentPageAndQuestionIndex, quoteData: content },
  } = useRootStore();

  const { onTriggerClick } = useSideDrawer();

  return (
    <div className="flex max-w-[865px] min-h-screen py-3xl mx-7xl flex-col justify-start items-center">
      <div className="flex items-center justify-center m-auto">
        <DoneCheck
          id="done-check"
          playing={true}
          size={100}
          stroke={0.5}
          progressEnd={() => {
            setShowQuote(true);
          }}
        />
      </div>
      {showQuote && !isObjectEmpty(content) && (
        <motion.div
          data-testid="quote-page"
          className="grid grid-cols-1 gap-3xl py-3xl pt-[16px] pb-3xl"
          initial={{ height: 0, opacity: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.75 }}
          animate={{ height: 'auto', opacity: 1 }}
        >
          <div className="rounded-[14px] relative bg-grey-100 p-xl">
            <div className="absolute file:opacity-[1] top-[16px] right-[16px] cursor-pointer">
              <Information onClick={() => onTriggerClick(SidePanelInfoContentModel.quote)} />
            </div>
            <Typography
              data-testid="screen-disclaimer"
              variant="p-small"
              className="text-grey-800 text-[16px] break-words"
            >
              {content.disclaimer}
            </Typography>
          </div>
          <Quotes content={content} onTriggerClickEvent={onTriggerClick} />
          <ButtonContainer className="flex flex-col items-center mt-xl gap-3xl">
            <div className="btncontent ">
              <Button
                data-testid="quote-done"
                onClick={() => {
                  resetCurrentPageAndQuestionIndex();
                  resetStore();
                  navigate('/');
                }}
                variant="primary"
                className="w-[16rem] order-1 border-primary-500 text-primary-500 done"
              >
                Done
              </Button>

              <Button
                data-testid="review"
                onClick={() => {
                  ReactGA.event({
                    category: 'Review with Expert',
                    action: 'Click',
                    label: 'Review with Expert',
                  });
                  resetCurrentPageAndQuestionIndex();
                  resetStore();
                  navigate('/talk');
                }}
                variant="primary"
                icon={<ContactWhite />}
                className="w-[16rem] border-primary-500 text-grey-0 bg-primary-500 review order-0"
              >
                Review with Expert
              </Button>
            </div>
            <Button
              data-testid="download-quote"
              onClick={() => window.print()}
              variant="link"
              icon={<DownloadBlue />}
              className="w-[16.5rem]"
            >
              Download Quote (PDF)
            </Button>
          </ButtonContainer>
        </motion.div>
      )}
    </div>
  );
});

const ButtonContainer = styled.div`
  container-type: inline-size;
  .btncontent {
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    @container (min-width: 540px) {
      flex-direction: row;
    }
  }

  .done {
    @container (min-width: 540px) {
      order: 0;
    }
  }

  .review {
    @container (min-width: 540px) {
      order: 1;
    }
  }
`;
