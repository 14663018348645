import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 23;
const buttonName = 'completeSwitchingEOR';
export const CompleteSwitchingEOR = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_COMPLETE_SWITCHING_EOR}
    />
  );
};
