import { FunctionComponent, useState } from 'react';

import Button from 'components/Atomic/Button/Button';
import { Quote } from 'types/quoteScreen';

import { GreenCheck, Information } from 'components/Atomic/Icon';
import Typography from 'components/Atomic/Typography/Typography';
import styled from 'styled-components';

type QuoteCardProps = {
  quote: Quote;
  onTriggerClick: () => void;
};
export const QuoteCard: FunctionComponent<QuoteCardProps> = ({ quote, onTriggerClick }) => {
  const [showMore, setShowMore] = useState(false);
  const minlength = 3;
  const suiteDesc: string[] = quote.description.split('\n-');
  const desc = suiteDesc[0];
  const offerings: string[] = suiteDesc.length > 1 ? suiteDesc.slice(1) : [];
  const visibleOfferings = showMore ? offerings : offerings.slice(0, minlength);

  return (
    <Container
      className="flex flex-col relative w-full gap-xl border-[1px] rounded-[16px] p-xl border-grey-400"
      data-testid="quote-card"
    >
      <div className="absolute opacity-[1] top-[16px] right-[16px] cursor-pointer" data-testid="info-icon">
        <Information onClick={onTriggerClick} />
      </div>
      <div className="content">
        <div className="flex flex-col justify-center items-center sm:flex">
          {quote.logo?.url && (
            <img
              data-testid={`quote-logo-${quote.id}`}
              alt={quote.logo?.url}
              src={quote.logo?.url}
              style={{ marginBottom: '1rem' }}
            />
          )}
          <Typography
            data-testid={`quote-name-${quote.id}`}
            variant="span-button-small"
            className="font-sans uppercase text-grey-1000 mb-lg"
          >
            {quote.name}
          </Typography>
          <Typography
            data-testid={`quote-original-price-${quote.id}`}
            variant="p-small-emphasis"
            className="line-through text-grey-1000"
          >
            {quote.originalPrice!}
          </Typography>
          <Typography data-testid={`quote-price-${quote.id}`} variant="h3" className="'text-grey-1000">
            {quote.price}
          </Typography>
          <Typography data-testid={`quote-plan-${quote.id}`} variant="p-xsmall" className="text-grey-700">
            {quote.plan}
          </Typography>
        </div>
        <div className="flex flex-col gap-[16px] ml-lg mr-xl sm:break-words">
          <Typography
            data-testid={`quote-description-${quote.id}`}
            key={desc}
            variant="p-medium"
            className="text-[16px]"
          >
            {desc}
          </Typography>
          {offerings.length > 0 && (
            <>
              <ul>
                {visibleOfferings.map((item, index) => (
                  <li key={index} className="flex gap-1 break-words my-sm sm:break-words">
                    <GreenCheck strokeWidth={2.5} className="h-auto min-w-[20px] mr-2 opacity-[0.8]" />
                    <Typography
                      data-testid={`quote-${quote.id}-offering-${index}`}
                      variant="p-small"
                      className="text-[14px] text-grey-1000 "
                    >
                      {item}
                    </Typography>
                  </li>
                ))}
              </ul>
              {offerings.length > minlength && (
                <Button
                  data-testid={`toggle-${quote.id}`}
                  className="text-[15px]"
                  variant="link"
                  onClick={() => setShowMore(!showMore)}
                >
                  {' '}
                  {showMore ? 'Show less' : 'Show more'}{' '}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <Typography data-testid={`quote-disclaimer-${quote.id}`} variant="p-xsmall" className="text-grey-700">
          {quote.disclaimer!}
        </Typography>
      </div>
    </Container>
  );
};

const Container = styled.div`
  container-type: inline-size;
  .content {
    gap: 32px;
    display: grid;
    grid-template-columns: 150px 1fr;
    @container (max-width: 475px) {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
`;
