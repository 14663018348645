import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 26;
const buttonName = 'transitionTeamMembers';
export const TransitionTeamMembers = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_TRANSITION_TEAM_MEMBERS_FROM_SUBSIDIARY_TO_EOR}
    />
  );
};
