import { feedbackOptions } from 'components/Shared/Feedback/FeedbackConfig';

import { BranchPage } from 'components/Shared/PageTemplate/BranchPage';
import { OPEN_SELF_SERVE_ON_NEW_TAB } from 'utils/constants';
import { BranchPagesName } from 'pages/NewConfigure/Configure.util';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

type Props = {};

export const ManageGlobalContractor = (props: Props) => {
  const feedbackOption = feedbackOptions.find((item) => item.name === BranchPagesName.contractor);

  return (
    <BranchPage
      title={feedbackOption?.title!}
      description={feedbackOption?.description!}
      logo={<img src={feedbackOption?.image} alt="Invoice showing Review payment quote" />}
      buttonIcon={<img src={feedbackOption?.buttonImg} alt="Sign up for contactor (free)" />}
      buttonText={feedbackOption?.buttonText!}
      onClick={OPEN_SELF_SERVE_ON_NEW_TAB}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_MANAGE_GLOBAL_CONTRACTOR}
    />
  );
};
