import { makeObservable, observable, runInAction } from 'mobx';
import RootStore from 'rootstore';
import { AllSetMode, TalkStepRecord } from './Talk.util';
import { getIntroContent } from 'api/content/Talk';
import { TalkToExpertIntroData, TalkToExpertIntroResponse } from './Talk.types';
import { isObjectEmpty } from 'utils/helpers';
import { checkAvailabilityAsync } from 'api/lead';
import moment from 'moment';
import { groupDatesIntoWeeks, GroupedDates } from 'components/Shared/WeeklyCalendar/calendar.util';

export default class TalkStore {
  public TalkToAnExpertIntroData: TalkToExpertIntroData;
  public currentPage: number | string;
  public talkFlowMode: string;
  public isLoading: boolean;
  public error: boolean;
  public availableTimeSlots: GroupedDates;
  public overload: number;
  public currentDateWindowCount: number;
  public allSetMode: AllSetMode | null;
  public selectedTimeSlot: string | null;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      availableTimeSlots: observable.ref,
      overload: observable.ref,
      talkFlowMode: observable.ref,
      currentDateWindowCount: observable.ref,
      TalkToAnExpertIntroData: observable.ref,
      allSetMode: observable.ref,
      selectedTimeSlot: observable.ref,
      currentPage: observable.ref,
      isLoading: observable.ref,
      error: observable.ref,
    });

    this.availableTimeSlots = {} as GroupedDates;
    this.overload = 1;
    this.talkFlowMode = '';
    this.currentDateWindowCount = 0;
    this.allSetMode = null;
    this.selectedTimeSlot = null;
    this.TalkToAnExpertIntroData = {} as TalkToExpertIntroData;
    this.currentPage = TalkStepRecord.intro;
    this.rootStore = rootStore;
    this.isLoading = false;
    this.error = false;
  }

  setCurrentPage = (page: number | string): void => {
    runInAction(() => (this.currentPage = page));
  };

  setTalkFlowMode = (mode: string): void => {
    runInAction(() => (this.talkFlowMode = mode));
  };

  setError = (error: boolean): void => {
    runInAction(() => (this.error = error));
  };

  setLoading = (loading: boolean): void => {
    runInAction(() => (this.isLoading = loading));
  };

  setTalkToAnExpertIntroContentData = (data: TalkToExpertIntroData): void => {
    runInAction(() => (this.TalkToAnExpertIntroData = data));
  };

  setAvailableTimeSlots = (availableTimeSlots: GroupedDates): void => {
    runInAction(() => (this.availableTimeSlots = availableTimeSlots));
  };

  setTimeSlot = (timeSlot: string | null): void => {
    runInAction(() => (this.selectedTimeSlot = timeSlot));
  };

  setOverload = (overload: number): void => {
    runInAction(() => (this.overload = overload));
  };

  setAllSetMode = (mode: AllSetMode | null): void => {
    runInAction(() => (this.allSetMode = mode));
  };

  fetchTalkToAnExpertIntroContentAsync = async () => {
    try {
      this.setLoading(true);
      const talkToAnExpertIntroContent: TalkToExpertIntroResponse = await getIntroContent();

      if (talkToAnExpertIntroContent.success) {
        this.setTalkToAnExpertIntroContentData(talkToAnExpertIntroContent.data[0]);
      } else {
        this.setError(true);
      }
      this.setLoading(false);
    } catch (error: any) {
      this.setError(true);
    }
  };

  getTalkToAnExpertIntroContentData = () => {
    this.setLoading(true);
    if (isObjectEmpty(this.TalkToAnExpertIntroData)) {
      this.fetchTalkToAnExpertIntroContentAsync();
    }
    this.setLoading(false);
    return this.TalkToAnExpertIntroData;
  };

  fetchAvailability = async () => {
    const { id: prospectId, token } = this.rootStore.prospectLayoutStore.prospectInformation;
    const fromDate = moment().add(this.overload, 'day');
    const toDate = moment().add(this.overload + 7, 'day');
    const fromDateStr = `${fromDate.year()}-${fromDate.month() + 1}-${fromDate.date()}`;
    const toDateStr = `${toDate.year()}-${toDate.month() + 1}-${toDate.date()}`;

    try {
      const availability = await checkAvailabilityAsync(prospectId, fromDateStr, toDateStr, token);
      if (availability?.success) {
        const groupedDates = groupDatesIntoWeeks(availability.data.dates);
        this.setAvailableTimeSlots(groupedDates);
      }
    } catch (error: any) {
      this.setError(true);
    }
  };
}
