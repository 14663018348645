import { Skeleton } from '../Skeleton/Skeleton';
import { InfoDescription, InfoInstruction, InfoTitle } from './Jumbotron.styled';

const JumbotronSkeleton = () => {
  return (
    <>
      <InfoTitle data-testid="jumbotron-skeleton">
        <Skeleton width="25ch" />
      </InfoTitle>
      <InfoInstruction>
        <Skeleton width="10ch" height="52px" />
        <Skeleton width="100%" height="52px" $margin="16px 0" />
        <Skeleton width="6ch" height="52px" />
      </InfoInstruction>
      <InfoDescription>
        <Skeleton width="100%" height="18px" />
        <Skeleton width="23ch" height="18px" />
        <ul>
          {Array(2)
            .fill(0)
            .map((item, index) => (
              <li key={`bullet-${index}`}>
                <Skeleton width="20px" height="20px" $borderRadius="50%" $margin="0 -9px 0 0" />
                <Skeleton width="90%" height="18px" $margin="0 8px" />
              </li>
            ))}
        </ul>
      </InfoDescription>
    </>
  );
};

export default JumbotronSkeleton;
