import Icon, { ico } from 'components/Icon/Icon';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { hexToRgba } from 'utils/helpers';
import { colors, fontFamily } from 'utils/tailwindConfig';
import { useSideDrawer } from 'hooks/useSideDrawer';
import { observer } from 'mobx-react';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';

export const InfoDescriptionLayout = observer(() => {
  const {
    infoPanelData: { title, description },
    closeSideBar,
  } = useSideDrawer();
  return (
    <StyledInfoContainer>
      <InfoPanelHeader data-testid="InfoDescriptionLayout-header">
        Info Panel
        <span className="cursor-pointer" data-testid="InfoDescriptionLayout-icon">
          <Icon name={ico.x} theme="blue" size={24} onClick={closeSideBar} />
        </span>
      </InfoPanelHeader>
      <InfoPanelBody>
        <InfoTopic data-testid="InfoDescriptionLayout-title">{title}</InfoTopic>
        <InfoDescription data-testid="InfoDescriptionLayout-description">{description}</InfoDescription>
        <InfoPanelCloseButton
          data-testid="InfoDescriptionLayout-closeButton"
          className="writing-text tag-pills"
          {...ButtonScaleEffectProps}
          onClick={closeSideBar}
        >
          Close
        </InfoPanelCloseButton>
      </InfoPanelBody>
    </StyledInfoContainer>
  );
});

const StyledInfoContainer = styled.article`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin: 0px;
  padding: 0px;
  color: ${colors.white};
`;

const InfoPanelHeader = styled.header`
  font-family: ${fontFamily.sans};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  position: sticky;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 73px;
  width: 100%;
  margin: 0px;
  padding: 4px 32px 0px;
  border-bottom: 1px solid ${hexToRgba(colors.white, 0.2)};
  opacity: 0.3;
`;

const InfoPanelBody = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin: 0px;
  padding: 32px;
`;

const InfoTopic = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 0.02em;
  font-family: ${fontFamily.basier};
  margin: 0px;
  padding: 0px;
`;

const InfoDescription = styled.p`
  font-family: ${fontFamily.basier};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.18px;
  margin: 0px 0px 16px;
  padding: 0px;
  opacity: 0.7;
  white-space: pre-wrap;
`;

const InfoPanelCloseButton = styled(motion.button)`
  font-family: ${fontFamily['polysans-neutral']};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
  border-radius: 4px;
  padding: 8px 12px;
  height: auto;
  border-width: 1px;
  border-style: solid;
  -webkit-user-drag: none;
  user-select: none;
  cursor: pointer;
  color: ${hexToRgba(colors.white, 0.5)};
  background-color: ${hexToRgba(colors.white, 0.1)};
  border-color: ${hexToRgba(colors.white, 0.5)};
  transform: none;
`;
