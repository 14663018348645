import CountrySelection from 'components/Shared/PageTemplate/CountrySelection';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 15;
const buttonName = 'whereAreYourTeamMemberRelocatingTo';
export const WhereAreYourTeamMemberRelocatingTo = () => {
  return (
    <CountrySelection
      pageNumber={pageNumber}
      buttonName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_WHERE_ARE_MEMBER_RELOCATING_TO}
    />
  );
};
