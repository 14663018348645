import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './utils/tailwindConfig/mixins.css';
import { RootStoreProvider } from 'contexts/StoreContext';
import RootStore from 'rootstore';

const store = new RootStore();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RootStoreProvider store={store}>
      <Router>
        <App />
      </Router>
    </RootStoreProvider>
  </React.StrictMode>,
);
reportWebVitals();
