import { ico } from 'components/Icon/Icon';

type EventInfoType = {
  name: ico;
  text: string;
};

export const EventInfo: Array<EventInfoType> = [
  {
    name: ico.timerFull,
    text: '30 mins.',
  },
  {
    name: ico.phoneFull,
    text: 'Phone call',
  },
  {
    name: ico.videoFull,
    text: 'Video (optional)',
  },
];
