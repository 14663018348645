import { makeObservable, observable, runInAction } from 'mobx';
import { Onboarding } from './types/Configure.types';
import { OnboardingStep, OnboardingStepResponse } from './types/OnboardingSteps.types';
import RootStore from 'rootstore';
import { getIntroContent, getStepsContent } from 'api/content/Configure';
import { isObjectEmpty } from 'utils/helpers';
import { stepsLabelsWithNonQuestionSteps } from './hooks/helpers';
import { ConfigureStepRecord } from './Configure.util';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { QuoteScreen } from 'types/quoteScreen';

export default class ConfigurePageStore {
  public introPageData: Onboarding;
  public questionStepsData: Record<string, OnboardingStep>;
  public nextSteps: number[];
  public currentPage: number | string;
  public currentQuestionIndex: number | undefined;
  public completedConfigureSteps: Map<number, any>;
  public isLoading: boolean;
  public branchPage: string | null;
  public error: boolean;
  public quoteData: QuoteScreen;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      introPageData: observable.ref,
      questionStepsData: observable.ref,
      nextSteps: observable.ref,
      completedConfigureSteps: observable.deep,
      currentPage: observable.ref,
      branchPage: observable.ref,
      isLoading: observable.ref,
      error: observable.ref,
      quoteData: observable.ref,
    });

    this.rootStore = rootStore;

    this.introPageData = {} as Onboarding;
    this.questionStepsData = {} as Record<string, OnboardingStep>;
    this.nextSteps = [];
    this.branchPage = null;
    this.currentPage = ConfigureStepRecord.intro;
    this.currentQuestionIndex = undefined;
    this.completedConfigureSteps = new Map();
    this.isLoading = false;
    this.error = false;
    this.quoteData = {} as QuoteScreen;
  }

  setIntroPageData = (data: Onboarding): void => {
    runInAction(() => (this.introPageData = data));
  };

  setQuoteData = (data: QuoteScreen): void => {
    runInAction(() => (this.quoteData = data));
  };

  setQuestionStepsData = (steps: Record<string, OnboardingStep>): void => {
    runInAction(() => (this.questionStepsData = steps));
  };

  setNextSteps = (steps: number[]): void => {
    runInAction(() => (this.nextSteps = steps));
  };

  setBranchPage = (page: string): void => {
    runInAction(() => (this.branchPage = page));
  };

  clearBranchPage = (): void => {
    runInAction(() => (this.branchPage = null));
  };

  clearNextSteps = (): void => {
    runInAction(() => {
      this.nextSteps = [];
    });
  };

  resetStore = () => {
    runInAction(() => {
      this.clearNextSteps();
      this.clearBranchPage();
      this.clearCompletedConfigureSteps();
    });
  };

  setCompletedConfigureSteps = (key: number, value: any): void => {
    runInAction(() => {
      this.completedConfigureSteps.set(key, value);
    });
  };

  clearCompletedConfigureSteps = (): void => {
    runInAction(() => {
      this.completedConfigureSteps.clear();
    });
  };

  removeCompletedConfigureStep = (key: number): void => {
    runInAction(() => {
      this.completedConfigureSteps.delete(key);
    });
  };

  setError = (error: boolean): void => {
    runInAction(() => (this.error = error));
  };

  setLoading = (loading: boolean): void => {
    runInAction(() => (this.isLoading = loading));
  };

  setCurrentPage = (page: number | string): void => {
    runInAction(() => (this.currentPage = page));
  };

  setCurrentQuestionIndex = (index: number | undefined): void => {
    runInAction(() => (this.currentQuestionIndex = index));
  };

  resetCurrentPageAndQuestionIndex = (): void => {
    runInAction(() => {
      this.currentPage = ConfigureStepRecord.intro;
      this.currentQuestionIndex = undefined;
    });
  };

  goToQuestionIndex = (questionIndex: number, direction: Direction) => {
    runInAction(() => {
      this.setCurrentQuestionIndex(questionIndex);
      this.setCurrentPage(this.nextSteps[questionIndex]);
      this.rootStore.prospectLayoutStore.setDirection(direction);
    });
  };

  fetchOnboardingAsync = async () => {
    try {
      this.setLoading(true);
      const tempObj: Record<string, OnboardingStep> = {};
      const nextStepsObj: { [key: string]: string } = {};
      const configurePageData = await Promise.all([getIntroContent(), getStepsContent()]);

      this.setIntroPageData(configurePageData[0].data[0]);

      const questionsResponse: OnboardingStepResponse = configurePageData[1];
      questionsResponse.data.forEach((obj: OnboardingStep) => {
        tempObj[obj.stepId] = obj;
        nextStepsObj[obj.stepId] = obj.label;
      });

      this.setQuestionStepsData(tempObj);
      this.rootStore.prospectLayoutStore.setStepsLabel(stepsLabelsWithNonQuestionSteps(nextStepsObj));
    } catch (error: any) {
      this.setError(true);
    }
  };

  getOnboarding = () => {
    this.setLoading(true);
    if (isObjectEmpty(this.introPageData)) {
      this.fetchOnboardingAsync();
    }
    this.setLoading(false);
    return this.introPageData;
  };
}
