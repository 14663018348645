import { ProspectCardConfig } from 'pages/NewLanding/Landing.config';
import { RightSection, ProspectCardWrapper } from 'pages/NewLanding/Landing.styled';
import ProspectCard from '../ProspectCard/ProspectCard';
import { FunctionComponent } from 'react';
import { LandingData } from 'pages/NewLanding/Landing.type';
import { HeaderProps } from 'components/Header/Header';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

type RightSectionComponentType = {
  copy: LandingData;
  setHeaderTheme: (theme: HeaderProps['theme']) => void;
};

const RightSectionComponent: FunctionComponent<RightSectionComponentType> = ({ copy, setHeaderTheme }) => {
  return (
    <RightSection>
      <h2 data-testid={`${ElementIdentifiersRecord.LANDING_PAGE}-choice-header`}>{copy.title}</h2>
      <ProspectCardWrapper>
        {ProspectCardConfig.map((item, index) => (
          <ProspectCard
            id={item.id}
            key={item.key}
            mobileImage={item.mobileViewImage}
            desktopImage={item.desktopViewImage}
            description={copy.intents}
            index={index}
            route={item.route}
            setHeaderTheme={setHeaderTheme}
          />
        ))}
      </ProspectCardWrapper>
    </RightSection>
  );
};

export default RightSectionComponent;
