import { Controller } from 'react-hook-form';
import { FunctionComponent } from 'react';
import { ExtendedEvent, FieldControllerPropsWithOptions } from '../contactForm.types';
import { StyledSelectBox } from 'components/Shared/StyledSelectBox';
import styled from 'styled-components';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';

export const StyledTagBox = styled(StyledSelectBox)<{ $checked: boolean }>``;

export const RadioGroup: FunctionComponent<FieldControllerPropsWithOptions> = ({
  control,
  config,
  options,
  value,
  onFieldChange,
  id,
}) => {
  return (
    <Controller
      name={config.name}
      rules={config.rules}
      control={control}
      defaultValue={value}
      render={({ field: { ...field }, fieldState }) => {
        return (
          <div className="flex flex-wrap items-center justify-start gap-4 grow">
            {(options as Array<Record<string, string>>).map((option) => (
              <div key={option.option} className="relative flex flex-grow">
                <label className="flex flex-grow" htmlFor={option.option}>
                  <input
                    id={`${id}-radio-${option.option}`}
                    data-testid={`${id}-radio-${option.option}`}
                    type="radio"
                    {...field}
                    value={option.option}
                    className="absolute opacity-0"
                  />
                  <StyledTagBox
                    id={`${id}-button-${option.option}`}
                    data-testid={`${id}-button-${option.option}`}
                    onClick={(e) => {
                      e.preventDefault();
                      field.onChange(option.option);
                      onFieldChange?.(e as ExtendedEvent);
                    }}
                    className="gap-2 opacity-100 grow writing-text tag-pills text-grey-1000"
                    {...ButtonScaleEffectProps}
                    key={option.option}
                    name={config.name}
                    value={option.option}
                    $checked={field.value === option.option}
                  >
                    {option.label}
                  </StyledTagBox>
                </label>
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};
