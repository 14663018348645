import { BranchPagesRecord, ConfigureStepRecord } from '../Configure.util';

export const stepsLabelsWithNonQuestionSteps = (steps: Record<string, string>) => {
  return {
    [ConfigureStepRecord.intro]: 'Introduction',
    [ConfigureStepRecord.primaryBusinessGoal]: 'Primary goals',
    ...steps,
    [ConfigureStepRecord.ContactForm]: 'Contacts',
    [ConfigureStepRecord.quote]: 'Quote',
  };
};

/**
 * This function that takes the next steps and the branchPages
 * and returns the steps that will be used to render the progress bar
 * @param nextSteps
 * @param branchPage
 * @returns Array
 */
export const progressBarSteps = (nextSteps: number[], branchPage: string): number[] => {
  //flatten next step array
  const flatNextSteps = nextSteps.flat();
  //check if next step array is NaN - (That means it's not a question step, it would redirect to a branch page)
  if (flatNextSteps.some(isNaN)) {
    return [
      ConfigureStepRecord.intro,
      ConfigureStepRecord.primaryBusinessGoal,
      BranchPagesRecord[branchPage as keyof typeof BranchPagesRecord],
    ];
  } else {
    return [
      ConfigureStepRecord.intro,
      ConfigureStepRecord.primaryBusinessGoal,
      ...flatNextSteps,
      ConfigureStepRecord.ContactForm,
      ConfigureStepRecord.quote,
    ];
  }
};
