import ProspectLayoutStore from 'components/Layout/ProspectLayout.store';
import ConfigurePageStore from 'pages/NewConfigure/Configure.store';
import LandingPageStore from 'pages/NewLanding/Landing.store';
import OverlayStore from 'components/Shared/Overlay/Overlay.store';
import TalkStore from 'pages/NewTalk/Talk.store';
import ContactFormStore from 'components/Shared/ContactFormTemplate/ContactForm.store';

export default class RootStore {
  public configurePageStore: ConfigurePageStore;
  public landingPageStore: LandingPageStore;
  public prospectLayoutStore: ProspectLayoutStore;
  public contactFormStore: ContactFormStore;
  public overlayStore: OverlayStore;
  public talkStore: TalkStore;
  constructor() {
    this.configurePageStore = new ConfigurePageStore(this);
    this.landingPageStore = new LandingPageStore(this);
    this.prospectLayoutStore = new ProspectLayoutStore(this);
    this.contactFormStore = new ContactFormStore(this);
    this.overlayStore = new OverlayStore(this);
    this.talkStore = new TalkStore(this);
  }
}
