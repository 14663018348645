import styled, { css } from 'styled-components';

type StyledContainerProps = {
  $smallScreenSidebarOpen?: boolean;
  $openSideDrawer?: boolean;
  $height?: number;
  $width?: number;
};

export const StyledContainer = styled.div<StyledContainerProps>`
  margin-right: ${(props) => (props.$openSideDrawer ? '350px' : '0')};
  transition: margin-right 0.25s cubic-bezier(0.95, 0.02, 0.56, 1);
  @media screen and (width <= 768px) {
    width: 100%;
  }
`;

export const StyledSideDrawer = styled.div<{ $openSideDrawer?: boolean }>`
  ${(props) =>
    props.$openSideDrawer
      ? `
  z-index: 2;
  animation: slide-in 0.25s cubic-bezier(0.95, 0.02, 0.56, 1) forwards;
  transition: z-index 0.25s cubic-bezier(0.95, 0.02, 0.56, 1);
  `
      : `
  animation: slide-out 0.25s cubic-bezier(0.95, 0.02, 0.56, 1) forwards;
  transition: z-index 0.25s cubic-bezier(0.95, 0.02, 0.56, 1);
  z-index: 0;
  `};
  @keyframes slide-in {
    0% {
      width: 0;
    }
    100% {
      width: min(350px, 85%);
    }
  }
  @keyframes slide-out {
    0% {
      width: min(350px, 85%);
    }
    100% {
      width: 0;
    }
  }
`;

export const backdropStyles = css`
  display: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  transition: display 0.25s cubic-bezier(0.95, 0.02, 0.56, 1);
`;

export const BackDrop = styled.div<{ $show: boolean; $showOnlyOnTabletDownward?: boolean; $zIndex?: number }>`
  ${backdropStyles}
  z-index: ${({ $zIndex }) => $zIndex ?? 0};
  ${({ $show, $showOnlyOnTabletDownward }) => {
    if ($show && $showOnlyOnTabletDownward) {
      return css`
        @media screen and (width <= 768px) {
          display: block;
        }
      `;
    }
    return $show ? 'display: block;' : 'display: none;';
  }};
`;
