import { API_PATH, BASE_API_URL } from 'utils/constants';

export const getIntroContent = async (fetchFunction = fetch) => {
  const url = `${BASE_API_URL.ContentApiUrl}/${API_PATH['talk-to-specialist']}`;
  try {
    const response = await fetchFunction(url);

    if (response.ok) {
      const payload = await response.json();
      return payload;
    }
  } catch (error) {
    console.error(error);
  }
};
