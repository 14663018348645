import { ReactHTML } from 'react';

interface TypographyVariantMap {
  variantClass: string;
  tag?: keyof ReactHTML;
}

export const VariantsMap: Record<string, TypographyVariantMap> = {
  h1: {
    variantClass: 'font-sans font-normal text-[52px] leading-tight tracking-[0.01em]',
  },
  h2: {
    variantClass: 'font-sans font-normal text-[42px] leading-tight tracking-[0.02em]',
  },
  h3: {
    variantClass: 'font-sans font-normal text-[32px] leading-tight tracking-[0.025em]',
  },
  h4: {
    variantClass: 'font-sansslim font-normal text-[28px] leading-tight tracking-[0.02em]',
  },
  h5: {
    variantClass: 'font-sansslim font-normal text-[24px] leading-tight tracking-[0.01em]',
  },
  h6: {
    variantClass: 'font-sans font-normal text-xs leading-tight tracking-[0.07em] uppercase',
  },
  'p-xsmall-emphasis': {
    tag: 'p',
    variantClass: 'font-basier font-semibold text-[14px] leading-relaxed tracking-normal',
  },
  'p-small-emphasis': {
    tag: 'p',
    variantClass: 'font-basier font-semibold text-[15px] leading-relaxed tracking-normal',
  },
  'p-medium-emphasis': {
    tag: 'p',
    variantClass: 'font-basierslim font-semibold text-[18px] leading-relaxed tracking-normal',
  },
  'p-large-emphasis': {
    tag: 'p',
    variantClass: 'font-basierslim font-semibold text-[20px] leading-relaxed tracking-normal',
  },
  'p-xlarge-emphasis': {
    tag: 'p',
    variantClass: 'font-basierslim font-semibold text-[22px] leading-relaxed tracking-normal',
  },
  'p-xsmall': {
    tag: 'p',
    variantClass: 'font-basier font-normal text-[14px] leading-relaxed tracking-normal',
  },
  'p-small': {
    tag: 'p',
    variantClass: 'font-basier font-normal text-[15px] leading-relaxed tracking-normal',
  },
  'p-medium': {
    tag: 'p',
    variantClass: 'font-basier font-normal text-[18px] leading-relaxed tracking-normal',
  },
  'p-large': {
    tag: 'p',
    variantClass: 'font-basier font-normal text-[20px] leading-relaxed tracking-normal',
  },
  'p-xlarge': {
    tag: 'span',
    variantClass: 'font-basier font-normal text-[22px] leading-relaxed tracking-normal',
  },
  'span-caption': {
    tag: 'span',
    variantClass: 'font-basier font-normal text-[12px] leading-tight tracking-[0.04em]',
  },
  'span-label': {
    tag: 'span',
    variantClass: 'font-basier font-normal text-[22px] leading-relaxed tracking-normal',
  },
  'span-button-small': {
    tag: 'span',
    variantClass: 'font-sansslim font-normal text-[12px] leading-tight tracking-[0.04em]',
  },
  'span-button-medium': {
    tag: 'span',
    variantClass: 'font-sansslim font-normal text-[14px] leading-tight tracking-[0.04em]',
  },
  'span-button-large': {
    tag: 'span',
    variantClass: 'font-sansslim font-normal text-[16px] leading-tight tracking-[0.04em]',
  },
};
