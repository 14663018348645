import { MouseEvent } from 'react';
import { Question } from 'pages/NewConfigure/types/OnboardingSteps.types';
import { StyledSelectBox } from './StyledSelectBox';
import { twMerge } from 'tailwind-merge';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';
import { removeSpaceBetweenNumbers } from 'utils/helpers';

export type QuestionSectionProps = Pick<Question, 'options'> & {
  onClickHandler: (event: MouseEvent<HTMLButtonElement>) => void;
  selectionMode?: SelectionMode;
  title?: string;
  name: string;
  state: any;
  flexgrid?: boolean;
  css?: string;
  instanceId?: string;
};

const SelectionRecords = {
  multiple: 'multiple',
  singleString: 'singleString',
  map: 'map',
} as const;

type SelectionMode = keyof typeof SelectionRecords;

export const QuestionSection = ({
  title,
  options,
  onClickHandler,
  name,
  state,
  selectionMode,
  flexgrid = true,
  css,
  instanceId,
}: QuestionSectionProps) => {
  return (
    <div className="w-full" data-testid="question-selection-template">
      {!!options && options?.length ? (
        <>
          {title && (
            <p
              className="font-basier text-[16px] font-[400] leading-[150%] tracking-[-0.18px] mb-2"
              data-testid={`${instanceId}-title`}
            >
              {title}
            </p>
          )}
          <section className="flex flex-wrap justify-start items-start gap-4 ">
            {options.map((option) => (
              <StyledSelectBox
                id={`${instanceId}-option_${removeSpaceBetweenNumbers(option.option, '-')}-button`}
                data-testid={`${instanceId}-option_${removeSpaceBetweenNumbers(option.option, '-')}-button`}
                className={twMerge('writing-text tag-pills', flexgrid && 'grow', css)}
                {...ButtonScaleEffectProps}
                key={option.option}
                name={name}
                value={option.option}
                $checked={isChecked(selectionMode, state, option.option, name)}
                onClick={onClickHandler}
              >
                {option.label}
              </StyledSelectBox>
            ))}
          </section>
        </>
      ) : null}
    </div>
  );
};

export const isChecked = (mode: SelectionMode = 'map', state: any, value: string, index: string) => {
  if (mode === SelectionRecords.multiple) return state.includes(value);
  if (mode === SelectionRecords.singleString) return state === value;
  return (state && state[index] === value) ?? false;
};
