import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router-dom';

import Landing from 'pages/NewLanding/Landing';
import Talk from 'pages/NewTalk/Talk';
import Configure from 'pages/NewConfigure/Configure';

function App() {
  ReactGA.initialize('GTM-N8SD6L3');
  useEffect(() => {
    const url: string = window.location.href;
    const keysToFind: string[] = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_keyword'];

    keysToFind.forEach((key: string) => {
      if (url.indexOf(key + '=') > -1) {
        const valueFound: string = url.substr(url.indexOf(key + '=') + key.length + 1).split('&')[0];
        sessionStorage.setItem(key, valueFound);
      }
    });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/configure/" element={<Configure />} />
      <Route path="/talk/" element={<Talk />} />
    </Routes>
  );
}

export default App;
