import { useEffect } from 'react';
import { useAnimate } from 'framer-motion';

import * as Styled from './styles';
interface Props {
  id?: string;
  playing?: boolean;
  size?: number;
  stroke?: number;
  checkcolor?: string;
  duration?: number;
  loops?: number;
  delay?: number;
  progresscolor?: string;
  progressStart?: () => void;
  progressStop?: () => void;
  progressEnd?: () => void;
}

export default function DoneCheck(props: Props) {
  const {
    id,
    playing = false,
    size = 150,
    stroke = 0.5,
    loops = 4,
    duration = 0.5,
    delay = 0.5,
    checkcolor = '#56c900',
    progresscolor = '#8a9aa8',
    progressStart = () => null,
    progressStop = () => null,
    progressEnd = () => null,
  } = props;

  const [check, animateCheck] = useAnimate();
  const [circle, animateCircle] = useAnimate();

  useEffect(() => {
    if (!playing) return;
    const animation = async () => {
      const transLoop = {
        ease: 'linear',
        repeatType: 'loop',
        repeat: loops,
        duration: duration,
        delay: delay,
      } as any;
      const transSpring = { type: 'spring', stiffness: 400, damping: 15, mass: 1 } as any;
      const transFade = { ease: 'easeInOut' } as any;
      progressStart();
      await animateCircle(circle.current, { rotate: 360 }, transLoop);
      animateCircle(circle.current, { opacity: 0 }, transFade);
      progressStop();
      await animateCheck(check.current, { scale: [0, 1], opacity: [1, 1] }, transSpring);
      progressEnd();
    };
    animation();
    // eslint-disable-next-line
  }, [playing]);

  return (
    <Styled.Progress id={id} $size={size}>
      <Styled.CheckWrapper ref={check} $size={size}>
        <Check color={checkcolor} size={size} stroke={stroke} />
      </Styled.CheckWrapper>
      <Styled.CircleWrapper ref={circle} $size={size}>
        <Circle color={progresscolor} size={size} stroke={stroke} />
      </Styled.CircleWrapper>
    </Styled.Progress>
  );
}
//@ts-ignore
const Check = ({ size, stroke, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0, 0, 24, 24"
      data-testid="done-check-tick"
    >
      <path
        d="M 8.44 12.339 L 10.607 14.506 L 10.587 14.486 L 15.467 9.596"
        fill="transparent"
        strokeWidth={stroke}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
      ></path>
      <path
        d="M 12 3 C 7.029 3 3 7.029 3 12 C 3 16.971 7.029 21 12 21 C 16.971 21 21 16.971 21 12 C 21 7.029 16.971 3 12 3 Z"
        fill="transparent"
        strokeWidth={stroke}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
      ></path>
      <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
    </svg>
  );
};
//@ts-ignore
const Circle = ({ size, stroke, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0, 0, 24, 24"
      data-testid="done-check-circle"
    >
      <path
        d="M 12 3 C 7.029 3 3 7.029 3 12 C 3 16.971 7.029 21 12 21 C 16.971 21 21 16.971 21 12 C 21 10.757 20.748 9.574 20.293 8.497"
        fill="transparent"
        strokeWidth={stroke}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
      ></path>
      <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
    </svg>
  );
};
