import { useRootStore } from 'contexts/StoreContext';
import { AllSetRecord } from 'pages/NewTalk/Talk.util';
import { formatTimeToHumanReadFormat } from 'utils/helpers';

type AllSetMessageReturnType = {
  header: string;
  subTitle: string;
};

export const useAllSet = () => {
  const {
    talkStore: { allSetMode, selectedTimeSlot },
    contactFormStore: { formData },
  } = useRootStore();

  const generateAllSetMessage = (): AllSetMessageReturnType => {
    switch (allSetMode) {
      case AllSetRecord.Schedule:
        if (selectedTimeSlot) {
          return {
            header: `${formData.firstName}, you're all set.`,
            subTitle: `We look forward to talking with you ${formatTimeToHumanReadFormat(selectedTimeSlot)}.
                We've also sent you an email with meeting details and the dial-in information.`,
          };
        } else {
          return {
            header: `You're all set.`,
            subTitle:
              "We're looking forward to talking with you. We've also sent you an email with meeting details and the dial-in information.",
          };
        }
      case AllSetRecord.GetACallBack:
        if (formData?.firstName) {
          return {
            header: `All set.`,
            subTitle: `You're all set, ${formData.firstName}! Talk soon.`,
          };
        } else {
          return {
            header: `All set.`,
            subTitle: `You're all set. Talk soon.`,
          };
        }
      default:
        return {
          header: '',
          subTitle: '',
        };
    }
  };

  return { allSetContent: generateAllSetMessage() };
};
