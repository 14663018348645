import { GPLogoBlack } from 'components/Atomic/Icon';
import { Container, LandingHeader } from './Landing.styled';
import LeftSectionComponent from './components/LeftSectionComponent/LeftSectionComponent';
import RightSectionComponent from './components/RightSectionComponent/RightSectionComponent';
import Loader from 'components/Animated/Loader/Loader';
import { FunctionComponent, useEffect } from 'react';
import { useRootStore } from 'contexts/StoreContext';
import { observer } from 'mobx-react';

const Landing: FunctionComponent = observer(() => {
  const {
    landingPageStore: { LandingPageData, isLoading, error, getLandingData },
    prospectLayoutStore: { setHeaderTheme },
  } = useRootStore();

  useEffect(() => {
    getLandingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDataReady = LandingPageData !== null && !isLoading && !error;

  return isDataReady ? (
    <div data-testid="des-landing-page">
      <LandingHeader>
        <nav>
          <ul>
            <li data-testid="gp-logo">
              <GPLogoBlack />
            </li>
            <li></li>
            <li></li>
          </ul>
        </nav>
      </LandingHeader>
      <Container>
        <LeftSectionComponent copy={LandingPageData} />
        <RightSectionComponent copy={LandingPageData} setHeaderTheme={setHeaderTheme} />
      </Container>
    </div>
  ) : (
    <Loader />
  );
});

export default Landing;
