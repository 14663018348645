import chat from 'assets/icons/chat.svg';
import ContactWhite from 'assets/icons/contactWhite.svg';

import { BranchPage } from 'components/Shared/PageTemplate/BranchPage';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import { BranchPagesName } from 'pages/NewConfigure/Configure.util';
import { useFeedbackNavigation } from 'pages/NewConfigure/hooks/useFeedbackNavigation';

type Props = {};

export const TransitionTemporaryMembersToPermanentRoles = (props: Props) => {
  const { redirectToTalkPage, feedbackOption } = useFeedbackNavigation(BranchPagesName.transition);
  return (
    <BranchPage
      title={feedbackOption?.title!}
      description={feedbackOption?.description!}
      logo={<img src={chat} alt="talk to expert chat icon" className="w-[150px] h-[150px] mx-auto" />}
      buttonIcon={<img src={ContactWhite} alt="contact white icon" />}
      buttonText={feedbackOption?.buttonText!}
      onClick={redirectToTalkPage}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_TRANSITION_TEMPORARY_MEMBERS_TO_PERMANENT_ROLES}
    />
  );
};
