import React, { useMemo } from 'react';
import moment from 'moment';
import { PillsRenderer } from './PillsRenderer';
import { checkWeekRange, GroupedDates } from '../calendar.util';
import { TimeSlot } from 'pages/NewTalk/Talk.types';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

export type WeekSlotsRendererProps = {
  availableTimeSlots: GroupedDates;
  staticTimeSlots: TimeSlot[];
  selectedTime: { selectedElementId: string; selectedElementValue: string };
  setSelectedTime: React.Dispatch<React.SetStateAction<{ selectedElementId: string; selectedElementValue: string }>>;
  onPillClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelClick: () => void;
  onConfirmClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selectedTimeSlot: string;
};

const WeekSlotsRenderer: React.FC<WeekSlotsRendererProps> = ({
  availableTimeSlots,
  staticTimeSlots,
  selectedTime,
  setSelectedTime,
  onPillClick,
  onCancelClick,
  onConfirmClick,
  selectedTimeSlot,
}) => {
  const renderWeekSlots = useMemo(() => {
    return Object.entries(availableTimeSlots).map(([weekRange, slots]) => {
      const weekRangeText = checkWeekRange(weekRange);
      return (
        <React.Fragment key={weekRange}>
          <h2
            className="writing-text font-basierslim text-[20px] leading-[1.2em] m-0 p-0"
            data-testid={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME}-${weekRange}-heading`}
          >
            {weekRangeText}
          </h2>
          {slots?.length > 0 ? (
            slots.map((times, index) => (
              <div key={`calendar-${weekRange}-${index}`} className="m-0 p-0 w-full">
                <div className="writing-text p-0 m-0 text-grey-800 flex mb-2">
                  {moment(times.date).format('dddd, MMMM D, YYYY')}
                </div>
                <div className="flex box-border flex-wrap gap-2 justify-start items-center m-0 p-0 w-full">
                  <PillsRenderer
                    availableSlots={times.timeslots}
                    date={times.date}
                    slots={staticTimeSlots}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    onPillClick={onPillClick}
                    onCancelClick={onCancelClick}
                    onConfirmClick={onConfirmClick}
                    selectedTimeSlot={selectedTimeSlot}
                  />
                </div>
              </div>
            ))
          ) : (
            <div>Loading...</div>
          )}
        </React.Fragment>
      );
    });
  }, [
    availableTimeSlots,
    onCancelClick,
    onConfirmClick,
    onPillClick,
    selectedTime,
    selectedTimeSlot,
    setSelectedTime,
    staticTimeSlots,
  ]);

  return <div className="flex justify-start items-start flex-col box-border gap-8 pt-8 w-full">{renderWeekSlots}</div>;
};

export default WeekSlotsRenderer;
