import CountrySelection from 'components/Shared/PageTemplate/CountrySelection';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 21;
const buttonName = 'currentEOR';
export const CurrentEOR = () => {
  return (
    <CountrySelection
      pageNumber={pageNumber}
      buttonName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_CURRENT_EOR}
    />
  );
};
