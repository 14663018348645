export interface CountryType {
  option: string;
  label: string;
  isoCode: string;
}

export const countries: CountryType[] = [
  {
    option: 'afghanistan',
    label: 'Afghanistan',
    isoCode: 'af',
  },
  {
    option: 'åland islands (finland)',
    label: 'Åland Islands (Finland)',
    isoCode: 'ax',
  },
  {
    option: 'albania',
    label: 'Albania',
    isoCode: 'al',
  },
  {
    option: 'algeria',
    label: 'Algeria',
    isoCode: 'dz',
  },
  {
    option: 'american samoa (usa)',
    label: 'American Samoa (USA)',
    isoCode: 'as',
  },
  {
    option: 'andorra',
    label: 'Andorra',
    isoCode: 'ad',
  },
  {
    option: 'angola',
    label: 'Angola',
    isoCode: 'ao',
  },
  {
    option: 'anguilla (uk)',
    label: 'Anguilla (UK)',
    isoCode: 'ai',
  },
  {
    option: 'antarctica',
    label: 'Antarctica',
    isoCode: 'aq',
  },
  {
    option: 'antigua and barbuda',
    label: 'Antigua and Barbuda',
    isoCode: 'ag',
  },
  {
    option: 'argentina',
    label: 'Argentina',
    isoCode: 'ar',
  },
  {
    option: 'armenia',
    label: 'Armenia',
    isoCode: 'am',
  },
  {
    option: 'aruba (netherlands)',
    label: 'Aruba (Netherlands)',
    isoCode: 'aw',
  },
  {
    option: 'australia',
    label: 'Australia',
    isoCode: 'au',
  },
  {
    option: 'austria',
    label: 'Austria',
    isoCode: 'at',
  },
  {
    option: 'azerbaijan',
    label: 'Azerbaijan',
    isoCode: 'az',
  },
  {
    option: 'bahamas',
    label: 'Bahamas',
    isoCode: 'bs',
  },
  {
    option: 'bahrain',
    label: 'Bahrain',
    isoCode: 'bh',
  },
  {
    option: 'bangladesh',
    label: 'Bangladesh',
    isoCode: 'bd',
  },
  {
    option: 'barbados',
    label: 'Barbados',
    isoCode: 'bb',
  },
  {
    option: 'belarus',
    label: 'Belarus',
    isoCode: 'by',
  },
  {
    option: 'belgium',
    label: 'Belgium',
    isoCode: 'be',
  },
  {
    option: 'belize',
    label: 'Belize',
    isoCode: 'bz',
  },
  {
    option: 'benin',
    label: 'Benin',
    isoCode: 'bj',
  },
  {
    option: 'bermuda (uk)',
    label: 'Bermuda (UK)',
    isoCode: 'bm',
  },
  {
    option: 'bhutan',
    label: 'Bhutan',
    isoCode: 'bt',
  },
  {
    option: 'bolivia',
    label: 'Bolivia',
    isoCode: 'bo',
  },
  {
    option: 'bosnia and herzegovina',
    label: 'Bosnia and Herzegovina',
    isoCode: 'ba',
  },
  {
    option: 'botswana',
    label: 'Botswana',
    isoCode: 'bw',
  },
  {
    option: 'bouvet island',
    label: 'Bouvet Island',
    isoCode: 'bv',
  },
  {
    option: 'brazil',
    label: 'Brazil',
    isoCode: 'br',
  },
  {
    option: 'british indian ocean territory (uk)',
    label: 'British Indian Ocean Territory (UK)',
    isoCode: 'io',
  },
  {
    option: 'british virgin islands (uk)',
    label: 'British Virgin Islands (UK)',
    isoCode: 'vg',
  },
  {
    option: 'brunei',
    label: 'Brunei',
    isoCode: 'bn',
  },
  {
    option: 'bulgaria',
    label: 'Bulgaria',
    isoCode: 'bg',
  },
  {
    option: 'burkina faso',
    label: 'Burkina Faso',
    isoCode: 'bf',
  },
  {
    option: 'burundi',
    label: 'Burundi',
    isoCode: 'bi',
  },
  {
    option: 'caribbean netherlands',
    label: 'Caribbean Netherlands',
    isoCode: 'bq',
  },
  {
    option: 'cape verde',
    label: 'Cape Verde',
    isoCode: 'cv',
  },
  {
    option: 'cambodia',
    label: 'Cambodia',
    isoCode: 'kh',
  },
  {
    option: 'cameroon',
    label: 'Cameroon',
    isoCode: 'cm',
  },
  {
    option: 'canada',
    label: 'Canada',
    isoCode: 'ca',
  },
  {
    option: 'caribbean netherlands (netherlands)',
    label: 'Caribbean Netherlands (Netherlands)',
    isoCode: 'an',
  },
  {
    option: 'cayman islands (uk)',
    label: 'Cayman Islands (UK)',
    isoCode: 'ky',
  },
  {
    option: 'central african republic',
    label: 'Central African Republic',
    isoCode: 'cf',
  },
  {
    option: 'chad',
    label: 'Chad',
    isoCode: 'td',
  },
  {
    option: 'chile',
    label: 'Chile',
    isoCode: 'cl',
  },
  {
    option: 'china',
    label: 'China',
    isoCode: 'cn',
  },
  {
    option: 'christmas island (australia)',
    label: 'Christmas Island (Australia)',
    isoCode: 'cx',
  },
  {
    option: 'cocos (keeling) islands (australia)',
    label: 'Cocos (Keeling) Islands (Australia)',
    isoCode: 'cc',
  },
  {
    option: 'colombia',
    label: 'Colombia',
    isoCode: 'co',
  },
  {
    option: 'comoros',
    label: 'Comoros',
    isoCode: 'km',
  },
  {
    option: 'congo, republic of the',
    label: 'Congo, Republic of the',
    isoCode: 'cg',
  },
  {
    option: 'congo, democratic republic of the',
    label: 'Congo, Democratic Republic of the',
    isoCode: 'cd',
  },
  {
    option: 'cook islands (new zealand)',
    label: 'Cook Islands (New Zealand)',
    isoCode: 'ck',
  },
  {
    option: 'costa rica',
    label: 'Costa Rica',
    isoCode: 'cr',
  },
  {
    option: "cote d'ivoire",
    label: "Cote d'Ivoire",
    isoCode: 'ci',
  },
  {
    option: 'croatia',
    label: 'Croatia',
    isoCode: 'hr',
  },
  {
    option: 'cuba',
    label: 'Cuba',
    isoCode: 'cu',
  },
  {
    option: 'curaçao (netherlands)',
    label: 'Curaçao (Netherlands)',
    isoCode: 'cw',
  },
  {
    option: 'cyprus',
    label: 'Cyprus',
    isoCode: 'cy',
  },
  {
    option: 'czech republic',
    label: 'Czech Republic',
    isoCode: 'cz',
  },
  {
    option: 'denmark',
    label: 'Denmark',
    isoCode: 'dk',
  },
  {
    option: 'djibouti',
    label: 'Djibouti',
    isoCode: 'dj',
  },
  {
    option: 'dominica',
    label: 'Dominica',
    isoCode: 'dm',
  },
  {
    option: 'dominican republic',
    label: 'Dominican Republic',
    isoCode: 'do',
  },
  {
    option: 'ecuador',
    label: 'Ecuador',
    isoCode: 'ec',
  },
  {
    option: 'egypt',
    label: 'Egypt',
    isoCode: 'eg',
  },
  {
    option: 'el salvador',
    label: 'El Salvador',
    isoCode: 'sv',
  },
  {
    option: 'equatorial guinea',
    label: 'Equatorial Guinea',
    isoCode: 'gq',
  },
  {
    option: 'eritrea',
    label: 'Eritrea',
    isoCode: 'er',
  },
  {
    option: 'estonia',
    label: 'Estonia',
    isoCode: 'ee',
  },
  {
    option: 'ethiopia',
    label: 'Ethiopia',
    isoCode: 'et',
  },
  {
    option: 'falkland islands (uk)',
    label: 'Falkland Islands (UK)',
    isoCode: 'fk',
  },
  {
    option: 'faroe islands (denmark)',
    label: 'Faroe Islands (Denmark)',
    isoCode: 'fo',
  },
  {
    option: 'fiji',
    label: 'Fiji',
    isoCode: 'fj',
  },
  {
    option: 'finland',
    label: 'Finland',
    isoCode: 'fi',
  },
  {
    option: 'france',
    label: 'France',
    isoCode: 'fr',
  },
  {
    option: 'french guiana (france)',
    label: 'French Guiana (France)',
    isoCode: 'gf',
  },
  {
    option: 'french polynesia (france)',
    label: 'French Polynesia (France)',
    isoCode: 'pf',
  },
  {
    option: 'french southern territories',
    label: 'French Southern Territories',
    isoCode: 'tf',
  },
  {
    option: 'gabon',
    label: 'Gabon',
    isoCode: 'ga',
  },
  {
    option: 'gambia',
    label: 'Gambia',
    isoCode: 'gm',
  },
  {
    option: 'georgia',
    label: 'Georgia',
    isoCode: 'ge',
  },
  {
    option: 'germany',
    label: 'Germany',
    isoCode: 'de',
  },
  {
    option: 'ghana',
    label: 'Ghana',
    isoCode: 'gh',
  },
  {
    option: 'gibraltar (uk)',
    label: 'Gibraltar (UK)',
    isoCode: 'gi',
  },
  {
    option: 'greece',
    label: 'Greece',
    isoCode: 'gr',
  },
  {
    option: 'greenland (denmark)',
    label: 'Greenland (Denmark)',
    isoCode: 'gl',
  },
  {
    option: 'grenada',
    label: 'Grenada',
    isoCode: 'gd',
  },
  {
    option: 'guadeloupe (france)',
    label: 'Guadeloupe (France)',
    isoCode: 'gp',
  },
  {
    option: 'guam (usa)',
    label: 'Guam (USA)',
    isoCode: 'gu',
  },
  {
    option: 'guatemala',
    label: 'Guatemala',
    isoCode: 'gt',
  },
  {
    option: 'guernsey (uk)',
    label: 'Guernsey (UK)',
    isoCode: 'gg',
  },
  {
    option: 'guinea',
    label: 'Guinea',
    isoCode: 'gn',
  },
  {
    option: 'guinea-bissau',
    label: 'Guinea-Bissau',
    isoCode: 'gw',
  },
  {
    option: 'guyana',
    label: 'Guyana',
    isoCode: 'gy',
  },
  {
    option: 'haiti',
    label: 'Haiti',
    isoCode: 'ht',
  },
  {
    option: 'heard island and mcdonald islands',
    label: 'Heard Island and McDonald Islands',
    isoCode: 'hm',
  },
  {
    option: 'honduras',
    label: 'Honduras',
    isoCode: 'hn',
  },
  {
    option: 'hong kong (china)',
    label: 'Hong Kong (China)',
    isoCode: 'hk',
  },
  {
    option: 'hungary',
    label: 'Hungary',
    isoCode: 'hu',
  },
  {
    option: 'iceland',
    label: 'Iceland',
    isoCode: 'is',
  },
  {
    option: 'india',
    label: 'India',
    isoCode: 'in',
  },
  {
    option: 'indonesia',
    label: 'Indonesia',
    isoCode: 'id',
  },
  {
    option: 'iran',
    label: 'Iran',
    isoCode: 'ir',
  },
  {
    option: 'iraq',
    label: 'Iraq',
    isoCode: 'iq',
  },
  {
    option: 'ireland',
    label: 'Ireland',
    isoCode: 'ie',
  },
  {
    option: 'isle of man (uk)',
    label: 'Isle of Man (UK)',
    isoCode: 'im',
  },
  {
    option: 'israel',
    label: 'Israel',
    isoCode: 'il',
  },
  {
    option: 'italy',
    label: 'Italy',
    isoCode: 'it',
  },
  {
    option: 'jamaica',
    label: 'Jamaica',
    isoCode: 'jm',
  },
  {
    option: 'japan',
    label: 'Japan',
    isoCode: 'jp',
  },
  {
    option: 'jersey (uk)',
    label: 'Jersey (UK)',
    isoCode: 'je',
  },
  {
    option: 'jordan',
    label: 'Jordan',
    isoCode: 'jo',
  },
  {
    option: 'kazakhstan',
    label: 'Kazakhstan',
    isoCode: 'kz',
  },
  {
    option: 'kenya',
    label: 'Kenya',
    isoCode: 'ke',
  },
  {
    option: 'kiribati',
    label: 'Kiribati',
    isoCode: 'ki',
  },
  {
    option: 'kosovo',
    label: 'Kosovo',
    isoCode: 'xk',
  },
  {
    option: 'kuwait',
    label: 'Kuwait',
    isoCode: 'kw',
  },
  {
    option: 'kyrgyzstan',
    label: 'Kyrgyzstan',
    isoCode: 'kg',
  },
  {
    option: 'laos',
    label: 'Laos',
    isoCode: 'la',
  },
  {
    option: 'latvia',
    label: 'Latvia',
    isoCode: 'lv',
  },
  {
    option: 'lebanon',
    label: 'Lebanon',
    isoCode: 'lb',
  },
  {
    option: 'lesotho',
    label: 'Lesotho',
    isoCode: 'ls',
  },
  {
    option: 'liberia',
    label: 'Liberia',
    isoCode: 'lr',
  },
  {
    option: 'libya',
    label: 'Libya',
    isoCode: 'ly',
  },
  {
    option: 'liechtenstein',
    label: 'Liechtenstein',
    isoCode: 'li',
  },
  {
    option: 'lithuania',
    label: 'Lithuania',
    isoCode: 'lt',
  },
  {
    option: 'luxembourg',
    label: 'Luxembourg',
    isoCode: 'lu',
  },
  {
    option: 'macau (china)',
    label: 'Macau (China)',
    isoCode: 'mo',
  },
  {
    option: 'macedonia',
    label: 'Macedonia',
    isoCode: 'mk',
  },
  {
    option: 'madagascar',
    label: 'Madagascar',
    isoCode: 'mg',
  },
  {
    option: 'malawi',
    label: 'Malawi',
    isoCode: 'mw',
  },
  {
    option: 'malaysia',
    label: 'Malaysia',
    isoCode: 'my',
  },
  {
    option: 'maldives',
    label: 'Maldives',
    isoCode: 'mv',
  },
  {
    option: 'mali',
    label: 'Mali',
    isoCode: 'ml',
  },
  {
    option: 'malta',
    label: 'Malta',
    isoCode: 'mt',
  },
  {
    option: 'marshall islands',
    label: 'Marshall Islands',
    isoCode: 'mh',
  },
  {
    option: 'martinique (france)',
    label: 'Martinique (France)',
    isoCode: 'mq',
  },
  {
    option: 'mauritania',
    label: 'Mauritania',
    isoCode: 'mr',
  },
  {
    option: 'mauritius',
    label: 'Mauritius',
    isoCode: 'mu',
  },
  {
    option: 'mayotte (france)',
    label: 'Mayotte (France)',
    isoCode: 'yt',
  },
  {
    option: 'mexico',
    label: 'Mexico',
    isoCode: 'mx',
  },
  {
    option: 'micronesia',
    label: 'Micronesia',
    isoCode: 'fm',
  },
  {
    option: 'moldova',
    label: 'Moldova',
    isoCode: 'md',
  },
  {
    option: 'monaco',
    label: 'Monaco',
    isoCode: 'mc',
  },
  {
    option: 'mongolia',
    label: 'Mongolia',
    isoCode: 'mn',
  },
  {
    option: 'montenegro',
    label: 'Montenegro',
    isoCode: 'me',
  },
  {
    option: 'montserrat (uk)',
    label: 'Montserrat (UK)',
    isoCode: 'ms',
  },
  {
    option: 'morocco',
    label: 'Morocco',
    isoCode: 'ma',
  },
  {
    option: 'mozambique',
    label: 'Mozambique',
    isoCode: 'mz',
  },
  {
    option: 'myanmar',
    label: 'Myanmar',
    isoCode: 'mm',
  },
  {
    option: 'namibia',
    label: 'Namibia',
    isoCode: 'na',
  },
  {
    option: 'nauru',
    label: 'Nauru',
    isoCode: 'nr',
  },
  {
    option: 'nepal',
    label: 'Nepal',
    isoCode: 'np',
  },
  {
    option: 'netherlands',
    label: 'Netherlands',
    isoCode: 'nl',
  },
  {
    option: 'new caledonia (france)',
    label: 'New Caledonia (France)',
    isoCode: 'nc',
  },
  {
    option: 'new zealand',
    label: 'New Zealand',
    isoCode: 'nz',
  },
  {
    option: 'nicaragua',
    label: 'Nicaragua',
    isoCode: 'ni',
  },
  {
    option: 'niger',
    label: 'Niger',
    isoCode: 'ne',
  },
  {
    option: 'nigeria',
    label: 'Nigeria',
    isoCode: 'ng',
  },
  {
    option: 'niue (new zealand)',
    label: 'Niue (New Zealand)',
    isoCode: 'nu',
  },
  {
    option: 'norfolk island (australia)',
    label: 'Norfolk Island (Australia)',
    isoCode: 'nf',
  },
  {
    option: 'north korea',
    label: 'North Korea',
    isoCode: 'kp',
  },
  {
    option: 'northern mariana islands (usa)',
    label: 'Northern Mariana Islands (USA)',
    isoCode: 'mp',
  },
  {
    option: 'norway',
    label: 'Norway',
    isoCode: 'no',
  },
  {
    option: 'oman',
    label: 'Oman',
    isoCode: 'om',
  },
  {
    option: 'pakistan',
    label: 'Pakistan',
    isoCode: 'pk',
  },
  {
    option: 'palau',
    label: 'Palau',
    isoCode: 'pw',
  },
  {
    option: 'palestine',
    label: 'Palestine',
    isoCode: 'ps',
  },
  {
    option: 'panama',
    label: 'Panama',
    isoCode: 'pa',
  },
  {
    option: 'papua new guinea',
    label: 'Papua New Guinea',
    isoCode: 'pg',
  },
  {
    option: 'paraguay',
    label: 'Paraguay',
    isoCode: 'py',
  },
  {
    option: 'peru',
    label: 'Peru',
    isoCode: 'pe',
  },
  {
    option: 'philippines',
    label: 'Philippines',
    isoCode: 'ph',
  },
  {
    option: 'pitcairn islands (uk)',
    label: 'Pitcairn Islands (UK)',
    isoCode: 'pn',
  },
  {
    option: 'poland',
    label: 'Poland',
    isoCode: 'pl',
  },
  {
    option: 'portugal',
    label: 'Portugal',
    isoCode: 'pt',
  },
  {
    option: 'puerto rico (usa)',
    label: 'Puerto Rico (USA)',
    isoCode: 'pr',
  },
  {
    option: 'qatar',
    label: 'Qatar',
    isoCode: 'qa',
  },
  {
    option: 'reunion (france)',
    label: 'Reunion (France)',
    isoCode: 're',
  },
  {
    option: 'romania',
    label: 'Romania',
    isoCode: 'ro',
  },
  {
    option: 'russia',
    label: 'Russia',
    isoCode: 'ru',
  },
  {
    option: 'rwanda',
    label: 'Rwanda',
    isoCode: 'rw',
  },
  {
    option: 'saint barthélemy (france)',
    label: 'Saint Barthélemy (France)',
    isoCode: 'bl',
  },
  {
    option: 'saint helena (uk)',
    label: 'Saint Helena (UK)',
    isoCode: 'sh',
  },
  {
    option: 'saint kitts and nevis',
    label: 'Saint Kitts and Nevis',
    isoCode: 'kn',
  },
  {
    option: 'saint lucia',
    label: 'Saint Lucia',
    isoCode: 'lc',
  },
  {
    option: 'saint martin (france)',
    label: 'Saint Martin (France)',
    isoCode: 'mf',
  },
  {
    option: 'saint pierre and miquelon (france)',
    label: 'Saint Pierre and Miquelon (France)',
    isoCode: 'pm',
  },
  {
    option: 'saint vincent and the grenadines',
    label: 'Saint Vincent and the Grenadines',
    isoCode: 'vc',
  },
  {
    option: 'samoa',
    label: 'Samoa',
    isoCode: 'ws',
  },
  {
    option: 'san marino',
    label: 'San Marino',
    isoCode: 'sm',
  },
  {
    option: 'sao tome and principe',
    label: 'Sao Tome and Principe',
    isoCode: 'st',
  },
  {
    option: 'saudi arabia',
    label: 'Saudi Arabia',
    isoCode: 'sa',
  },
  {
    option: 'senegal',
    label: 'Senegal',
    isoCode: 'sn',
  },
  {
    option: 'serbia',
    label: 'Serbia',
    isoCode: 'rs',
  },
  {
    option: 'seychelles',
    label: 'Seychelles',
    isoCode: 'sc',
  },
  {
    option: 'sierra leone',
    label: 'Sierra Leone',
    isoCode: 'sl',
  },
  {
    option: 'singapore',
    label: 'Singapore',
    isoCode: 'sg',
  },
  {
    option: 'sint maarten (netherlands)',
    label: 'Sint Maarten (Netherlands)',
    isoCode: 'sx',
  },
  {
    option: 'slovakia',
    label: 'Slovakia',
    isoCode: 'sk',
  },
  {
    option: 'slovenia',
    label: 'Slovenia',
    isoCode: 'si',
  },
  {
    option: 'solomon islands',
    label: 'Solomon Islands',
    isoCode: 'sb',
  },
  {
    option: 'somalia',
    label: 'Somalia',
    isoCode: 'so',
  },
  {
    option: 'south africa',
    label: 'South Africa',
    isoCode: 'za',
  },
  {
    option: 'south georgia and the south sandwich islands',
    label: 'South Georgia and the South Sandwich Islands',
    isoCode: 'gs',
  },
  {
    option: 'south korea',
    label: 'South Korea',
    isoCode: 'kr',
  },
  {
    option: 'south sudan',
    label: 'South Sudan',
    isoCode: 'ss',
  },
  {
    option: 'spain',
    label: 'Spain',
    isoCode: 'es',
  },
  {
    option: 'sri lanka',
    label: 'Sri Lanka',
    isoCode: 'lk',
  },
  {
    option: 'sudan',
    label: 'Sudan',
    isoCode: 'sd',
  },
  {
    option: 'suriname',
    label: 'Suriname',
    isoCode: 'sr',
  },
  {
    option: 'svalbard and jan mayen (norway)',
    label: 'Svalbard and Jan Mayen (Norway)',
    isoCode: 'sj',
  },
  {
    option: 'swaziland',
    label: 'Swaziland',
    isoCode: 'sz',
  },
  {
    option: 'sweden',
    label: 'Sweden',
    isoCode: 'se',
  },
  {
    option: 'switzerland',
    label: 'Switzerland',
    isoCode: 'ch',
  },
  {
    option: 'syria',
    label: 'Syria',
    isoCode: 'sy',
  },
  {
    option: 'taiwan',
    label: 'Taiwan',
    isoCode: 'tw',
  },
  {
    option: 'tajikistan',
    label: 'Tajikistan',
    isoCode: 'tj',
  },
  {
    option: 'tanzania',
    label: 'Tanzania',
    isoCode: 'tz',
  },
  {
    option: 'thailand',
    label: 'Thailand',
    isoCode: 'th',
  },
  {
    option: 'timor-leste',
    label: 'Timor-Leste',
    isoCode: 'tl',
  },
  {
    option: 'togo',
    label: 'Togo',
    isoCode: 'tg',
  },
  {
    option: 'tokelau (new zealand)',
    label: 'Tokelau (New Zealand)',
    isoCode: 'tk',
  },
  {
    option: 'tonga',
    label: 'Tonga',
    isoCode: 'to',
  },
  {
    option: 'trinidad and tobago',
    label: 'Trinidad and Tobago',
    isoCode: 'tt',
  },
  {
    option: 'tunisia',
    label: 'Tunisia',
    isoCode: 'tn',
  },
  {
    option: 'turkiye',
    label: 'Turkiye',
    isoCode: 'tr',
  },
  {
    option: 'turkmenistan',
    label: 'Turkmenistan',
    isoCode: 'tm',
  },
  {
    option: 'turks and caicos islands (uk)',
    label: 'Turks and Caicos Islands (UK)',
    isoCode: 'tc',
  },
  {
    option: 'tuvalu',
    label: 'Tuvalu',
    isoCode: 'tv',
  },
  {
    option: 'uganda',
    label: 'Uganda',
    isoCode: 'ug',
  },
  {
    option: 'ukraine',
    label: 'Ukraine',
    isoCode: 'ua',
  },
  {
    option: 'united arab emirates',
    label: 'United Arab Emirates',
    isoCode: 'ae',
  },
  {
    option: 'united kingdom',
    label: 'United Kingdom',
    isoCode: 'gb',
  },
  {
    option: 'united states',
    label: 'United States',
    isoCode: 'us',
  },
  {
    option: 'united states minor outlying islands',
    label: 'United States Minor Outlying Islands',
    isoCode: 'um',
  },
  {
    option: 'uruguay',
    label: 'Uruguay',
    isoCode: 'uy',
  },
  {
    option: 'us virgin islands (usa)',
    label: 'US Virgin Islands (USA)',
    isoCode: 'vi',
  },
  {
    option: 'uzbekistan',
    label: 'Uzbekistan',
    isoCode: 'uz',
  },
  {
    option: 'vanuatu',
    label: 'Vanuatu',
    isoCode: 'vu',
  },
  {
    option: 'vatican city (holy see)',
    label: 'Vatican City (Holy See)',
    isoCode: 'va',
  },
  {
    option: 'venezuela',
    label: 'Venezuela',
    isoCode: 've',
  },
  {
    option: 'vietnam',
    label: 'Vietnam',
    isoCode: 'vn',
  },
  {
    option: 'wallis and futuna islands (france)',
    label: 'Wallis and Futuna Islands (France)',
    isoCode: 'wf',
  },
  {
    option: 'yemen',
    label: 'Yemen',
    isoCode: 'ye',
  },
  {
    option: 'zambia',
    label: 'Zambia',
    isoCode: 'zm',
  },
  {
    option: 'zimbabwe',
    label: 'Zimbabwe',
    isoCode: 'zw',
  },
];

export type TopCountry = {
  option: string;
  label: string;
  isoCode: string;
};
export const topCountries: TopCountry[] = [
  { option: 'australia', label: 'Australia', isoCode: 'au' },
  { option: 'brazil', label: 'Brazil', isoCode: 'br' },
  { option: 'canada', label: 'Canada', isoCode: 'ca' },
  { option: 'china', label: 'China', isoCode: 'ca' },
  { option: 'germany', label: 'Germany', isoCode: 'de' },
  { option: 'india', label: 'India', isoCode: 'in' },
  { option: 'mexico', label: 'Mexico', isoCode: 'mx' },
  { option: 'philippines', label: 'Philippines', isoCode: 'ph' },
  { option: 'spain', label: 'Spain', isoCode: 'es' },
  { option: 'united kingdom', label: 'United Kingdom', isoCode: 'gb' },
];

export const mockedFilteredCountries: Array<{ text: string }> = [
  {
    text: 'Albania',
  },
  {
    text: 'Australia',
  },
  {
    text: 'Switzerland',
  },
  {
    text: 'Taiwan',
  },
  {
    text: 'Thailand',
  },
  {
    text: 'Togo',
  },
  {
    text: 'Uzbekistan',
  },
  {
    text: 'Vietnam',
  },
  {
    text: 'Zimbabwe',
  },
];
export const mockedSelectedCountries: Set<string> = new Set(['australia', 'algeria', 'angola']);
