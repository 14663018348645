import { useEffect } from 'react';
import { colors } from 'utils/tailwindConfig';
import { TalkFlowProgressBarStepLabels, TalkStepRecord, TalkFlowMode, TalkPageRecord } from '../Talk.util';
import { useRootStore } from 'contexts/StoreContext';
import { Direction } from 'components/Layout/ProspectLayout.types';

/**
 * Custom hook for managing the Talk flow functionality.
 * Handles progress bar, page navigation, and component rendering logic.
 * @returns Object containing necessary state variables and functions for Talk flow management.
 */
export const useTalk = () => {
  const {
    prospectLayoutStore: {
      asideIsOpen,
      setSideDrawer,
      direction,
      headerTheme,
      setHeaderTheme,
      clearCurrentInfoPanelTopic,
      setStepsLabel,
      setDirection,
      clearProspectInformation,
    },
    talkStore: { currentPage, setCurrentPage, talkFlowMode },
  } = useRootStore();

  // Set up progress bar steps labels and clean up on unmount
  useEffect(() => {
    setStepsLabel(TalkFlowProgressBarStepLabels);
    return () => {
      setCurrentPage(TalkStepRecord.intro);
      clearCurrentInfoPanelTopic();
    };
  }, [clearCurrentInfoPanelTopic, setCurrentPage, setStepsLabel]);

  // Update header theme and document body styling based on current page
  useEffect(() => {
    setHeaderTheme(Number(currentPage) > 0 ? 'white' : 'orange');
    document.body.style.backgroundColor = Number(currentPage) > 0 ? colors.white : colors.primary[500];
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.backgroundColor = '#fff';
      document.body.style.overflow = 'auto';
    };
  }, [currentPage, setHeaderTheme]);

  /**
   * Generate progress bar steps based on the current flow mode.
   * @param stepMode - Current step mode for the Talk flow.
   * @returns Array of step strings for the progress bar.
   */
  const progressBarSteps = (stepMode: string): string[] => {
    const FlowMode: Record<keyof typeof TalkFlowMode, Array<number>> = {
      GET_A_CALL_BACK_NOW: [TalkStepRecord.getACallBackNow],
      SCHEDULE_A_MEETING: [TalkStepRecord.scheduleAMeeting, TalkStepRecord.contactInformation],
    };

    const additionalSteps: Array<number> = stepMode?.length !== 0 ? FlowMode[stepMode as keyof typeof FlowMode] : [];
    const steps: Array<string> = [TalkStepRecord.intro, ...additionalSteps, TalkStepRecord.allSet].map(String);

    return steps;
  };

  /**
   * Navigate to a specific page in the Talk flow.
   * @param pageNumber - Page number to navigate to.
   */
  const navigatePage = (pageNumber: number) => {
    /**
     * For Future Reference:
     * we are using the value of the index of the step array
     */
    setCurrentPage(talkFlowSteps[pageNumber]);
    setDirection(Direction.back);
  };

  const talkFlowSteps = progressBarSteps(talkFlowMode);
  const positionCurrentPageInTheStepsArray = talkFlowSteps.indexOf(String(currentPage));

  const CurrentComponent = TalkPageRecord[currentPage as keyof typeof TalkPageRecord];

  return {
    asideIsOpen,
    currentPage,
    CurrentComponent,
    direction,
    headerTheme,
    navigatePage,
    positionCurrentPageInTheStepsArray,
    setSideDrawer,
    setHeaderTheme,
    setCurrentPage,
    talkFlowSteps,
    clearProspectInformation,
  };
};
