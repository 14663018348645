import { FunctionComponent } from 'react';
import { TalkIntro } from './Steps/TalkIntro/TalkIntro';
import { ScheduleMeeting } from './Steps/ScheduleMeeting/ScheduleMeeting';
import { ContactInformation } from './Steps/ContactInformation/ContactInformation';
import { AllSet } from './Steps/AllSet/AllSet';
import { GetACallBackNow } from './Steps/GetACallBackNow/GetACallBackNow';

export const TalkStepRecord = {
  intro: 0,
  scheduleAMeeting: 1,
  contactInformation: 2,
  getACallBackNow: 3,
  allSet: 4,
} as const;

export type TalkStepRecordKey = keyof typeof TalkStepRecord;
export type TalkStepRecordValue = (typeof TalkStepRecord)[keyof typeof TalkStepRecord];

type TalkPages = {
  [key in TalkStepRecordValue]: FunctionComponent<any>;
};

export const TalkPageRecord: TalkPages = {
  [TalkStepRecord.intro]: TalkIntro,
  [TalkStepRecord.scheduleAMeeting]: ScheduleMeeting,
  [TalkStepRecord.contactInformation]: ContactInformation,
  [TalkStepRecord.getACallBackNow]: GetACallBackNow,
  [TalkStepRecord.allSet]: AllSet,
};

export const AllSetRecord = {
  Schedule: 'Schedule',
  GetACallBack: 'GetACallBack',
} as const;

export type AllSetMode = keyof typeof AllSetRecord;

export const TalkFlowMode = {
  GET_A_CALL_BACK_NOW: 'GET_A_CALL_BACK_NOW',
  SCHEDULE_A_MEETING: 'SCHEDULE_A_MEETING',
} as const;

export const TalkFlowProgressBarStepLabels = {
  [TalkStepRecord.intro]: 'Summary',
  [TalkStepRecord.scheduleAMeeting]: 'Select Day and Time',
  [TalkStepRecord.contactInformation]: 'Contact Info.',
  [TalkStepRecord.getACallBackNow]: 'Contact Information.',
  [TalkStepRecord.allSet]: 'Confirmation',
} as const;
