import Icon, { ico } from 'components/Icon/Icon';
import styled from 'styled-components';
import { colors, fontFamily } from 'utils/tailwindConfig';

export type SearchBarRowComponentProps = {
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement & HTMLDivElement>) => void;
  selectedCountries: Set<string>;
  handleCountryClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SearchBarRowComponent = ({
  handleCountryClick,
  selectedCountries,
  handleInputChange,
}: SearchBarRowComponentProps) => {
  return (
    <SearchBarRow data-testid="search-bar">
      <StyledSearchBarWrapper>
        <SearchIconWrapper>
          <SearchIcon>
            <Icon name={ico.search} theme="white" size={24} />
          </SearchIcon>
        </SearchIconWrapper>
        <StyledSearchInput
          data-testid="search_input"
          type="text"
          placeholder="Enter a country name"
          name="countries_modal_search_input"
          onChange={handleInputChange}
        />
      </StyledSearchBarWrapper>
      <SearchResultRow>
        {Array.from(selectedCountries).map((country) => (
          <SearchResultItem key={country} data-testid={`${country}-search-result`}>
            {country}
            <Delimiter></Delimiter>
            <button data-testid={`${country}-search-result-button`} onClick={handleCountryClick} value={country}>
              <Icon name={ico.x} theme="blue" size={24} />
            </button>
          </SearchResultItem>
        ))}
      </SearchResultRow>
    </SearchBarRow>
  );
};

const SearchBarRow = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 32px;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid ${colors.grey[300]};
`;

const StyledSearchBarWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 48px;
  width: 100%;
  gap: 4px;
  padding: 0px 8px;
  border-radius: 0px;
  background-color: transparent;
  border: 0px solid ${colors.grey[100]};
  border-color: ${colors.grey[100]};
`;

const SearchIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

const SearchIcon = styled.div`
  display: inline;
  vertical-align: middle;
  width: 24px;
  height: 24px;
`;

const StyledSearchInput = styled.input`
  font-family: ${fontFamily.basier};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.18px;
  color: ${colors.grey[800]};
  height: 100%;
  flex-grow: 1;
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  background-color: transparent;
`;

const SearchResultRow = styled.div`
  display: flex;
  flex-flow: wrap;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 8px;
  padding: 0px 0px 16px;
`;

const SearchResultItem = styled.span`
  font-family: ${fontFamily.basier};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.18px;
  margin: 0px;
  display: flex;
  box-sizing: border-box;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
  padding: 10px 8px 10px 16px;
  background-color: ${colors.primary[500]};
  color: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.primary[500]};
  cursor: pointer;
  gap: 4px;
`;

const Delimiter = styled.span`
  border-right: 1px solid ${colors.white};
  opacity: 0.3;
  height: 100%;
  margin-left: 6px;
`;
