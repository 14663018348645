import { useRootStore } from 'contexts/StoreContext';
import { useMediaQuery } from 'usehooks-ts';

export const PROGRESS_BAR_MOBILE_POSITION = 25;
export const PROGRESS_BAR_DESKTOP_POSITION = 374;
export const PROGRESS_BAR_MOBILE_DEFAULT = 8;
export const PROGRESS_BAR_DESKTOP_DEFAULT = 40;

/**
 * useProgressBarPosition hook
 * @returns {number} - The right position of the progress bar
 * */

export const useProgressBarPosition = () => {
  const {
    prospectLayoutStore: { asideIsOpen },
  } = useRootStore();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const right = asideIsOpen
    ? isMobile
      ? PROGRESS_BAR_MOBILE_POSITION
      : PROGRESS_BAR_DESKTOP_POSITION
    : isMobile
      ? PROGRESS_BAR_MOBILE_DEFAULT
      : PROGRESS_BAR_DESKTOP_DEFAULT;

  return right;
};
