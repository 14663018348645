import { Direction } from 'components/Layout/ProspectLayout.types';
import { QuestionSection } from 'components/Shared/QuestionSectionWithTags';
import SurveyBackAndNextButton from 'components/Shared/SurveyBackAndNextButton';
import { observer } from 'mobx-react';
import { useCompanySizeAndIndustry } from './useCompanySizeAndIndustry';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import { toJS } from 'mobx';

export const companySizeButtonName = 'companySize';
export const industryButtonName = 'industry';
export const pageNumber = 2;

export const questionIdRecord = {
  [companySizeButtonName]: 0,
  [industryButtonName]: 1,
};

export const CompanySizeAndIndustry = observer(() => {
  const {
    completedConfigureSteps,
    currentQuestionIndex,
    companySizeOption,
    data,
    goToQuestionIndex,
    handleClickChange,
    industryOption,
    state,
  } = useCompanySizeAndIndustry(pageNumber);

  const primaryGoal = toJS(completedConfigureSteps.get(1));
  const identifier = `${ElementIdentifiersRecord.CONFIGURE_PAGE_COMPANY_SIZE_AND_INDUSTRY}-PG:${primaryGoal}-S:${pageNumber}`;

  return (
    <div className="survey-question-container">
      <p className="survey-question-title" data-testid={`${identifier}-title`}>
        {data.questions[0]?.title}
      </p>
      <div className="survey-question-description" data-testid={`${identifier}-description`}>
        {data.questions[0]?.description}
      </div>
      <div className="flex flex-col justify-start items-start gap-4 my-6 p-0">
        <QuestionSection
          title="Company Size"
          options={companySizeOption}
          onClickHandler={handleClickChange}
          name={companySizeButtonName}
          state={state}
          instanceId={`${identifier}_companySize`}
        />

        <QuestionSection
          title={data.questions[1]?.title}
          options={industryOption}
          onClickHandler={handleClickChange}
          name={industryButtonName}
          state={state}
          instanceId={`${identifier}-industry`}
        />
        <SurveyBackAndNextButton
          instanceId={`${identifier}`}
          disabled={!(state[companySizeButtonName] && state[industryButtonName])}
          onNextClick={() => goToQuestionIndex(currentQuestionIndex! + 1, Direction.forward)}
          onBackClick={() => goToQuestionIndex(currentQuestionIndex! - 1, Direction.back)}
        />
      </div>
    </div>
  );
});
