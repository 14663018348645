import { Direction } from 'components/Layout/ProspectLayout.types';
import { ContactFormTemplate } from 'components/Shared/ContactFormTemplate/ContactForm';
import { CTARecord } from 'components/Shared/ContactFormTemplate/ContactFrom.types';
import SurveyBackAndNextButton from 'components/Shared/SurveyBackAndNextButton';
import { useRootStore } from 'contexts/StoreContext';
import { observer } from 'mobx-react';
import { AllSetRecord, TalkStepRecord } from 'pages/NewTalk/Talk.util';
import { useCallback, useEffect } from 'react';
import { parsePhone } from 'components/Shared/ContactFormTemplate/FormConfig';
import { GetACallBackPayload } from 'pages/NewTalk/Talk.types';

import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import { getACallbackNowAsync } from 'api/lead/GetACallbackNow';

export const GetACallBackNow = observer(() => {
  const {
    talkStore: { setCurrentPage, setAllSetMode },
    prospectLayoutStore: { setDirection, prospectInformation },
  } = useRootStore();
  const { id, token } = prospectInformation;

  useEffect(() => {
    setAllSetMode(AllSetRecord.GetACallBack);
  }, [setAllSetMode]);

  const handleSubmit = useCallback(
    (data: any) => {
      const formPayload: GetACallBackPayload = {
        ...data,
        phoneNumber: parsePhone(data.country, data.phoneNumber),
        termsAccepted: true,
        privacyPolicyAccepted: true,
        country: 'United States',
      };
      getACallbackNowAsync(formPayload, id, token);
    },
    [id, token],
  );

  const handleBackClick = useCallback(() => {
    setCurrentPage(TalkStepRecord.intro);
    setDirection(Direction.back);
  }, [setCurrentPage, setDirection]);

  const handleNextClick = useCallback(() => {
    setCurrentPage(TalkStepRecord.allSet);
    setDirection(Direction.forward);
  }, [setCurrentPage, setDirection]);

  const handleError = useCallback((data: any) => {
    console.log(data);
  }, []);

  const CTA: CTARecord = {
    Comp: SurveyBackAndNextButton,
    props: {
      onBackClick: handleBackClick,
      onNextClick: handleNextClick,
    },
  };

  return (
    <ContactFormTemplate
      onFormSubmit={handleSubmit}
      onFormError={handleError}
      CTA={CTA}
      instanceId={ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_GET_A_CALLBACK_FORM}
    />
  );
});
