import moment from 'moment';

/**
 * Simple util function to remove trailing dots from a string
 *
 * Example
 * ```js
 * removeTrailingDots("hello world....");
 * // Prints: hello world
 *
 * * removeTrailingDots("usa.");
 * // Prints: usa
 * ```
 * @param value
 * @returns
 */
export const removeTrailingDots = (value: string) => {
  while (value.charAt(value.length - 1) === '.') {
    value = value.substring(0, value.length - 1);
  }
  return value;
};

/**
 * Simple util function to convert a hex color to an rgba color
 *
 * Example
 * ```js
 * hexToRgba("#000000", 0.5);
 * // Prints: rgba(0, 0, 0, 0.5)
 *
 * * hexToRgba("#FFFFFF", 0.5);
 * // Prints: rgba(255, 255, 255, 0.5)
 * ```
 * @param hex
 * @param alpha
 * @returns
 */
export const hexToRgba = (hex: string, alpha: number) => {
  // Remove the # if it exists
  hex = hex.replace('#', '');

  // Parse the hex values to obtain R, G, B
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // If the alpha value is not provided, set it to 1 (opaque)
  alpha = alpha || 1;

  // Return the RGBA value
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
};

/**
 * Simple util function to check if an object is empty
 *
 * Example
 * ```js
 * isObjectEmpty({});
 * // Prints: true
 *
 * * isObjectEmpty({ name: "John Doe" });
 * // Prints: false
 * ```
 * @param objectName
 * @returns
 */
export const isObjectEmpty = (objectName: Record<string, any>) => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

const forwardSlashWordRegex = /^\/[a-zA-Z]+/;
/**
 * Simple util function to check if a string starts with a forward slash and a word
 *
 * Example
 * ```js
 * startsWithForwardSlashWord("/hello");
 * // Prints: true
 *
 * * startsWithForwardSlashWord("hello");
 * // Prints: false
 * ```
 * @param inputText
 * @returns
 */
export const startsWithForwardSlashWord = (inputText: string): boolean => {
  return forwardSlashWordRegex.test(inputText);
};

/**
 * Simple util function to search a map by key
 *
 * Example
 * ```js
 * const map = new Map([["name", "John Doe"]]);
 * searchMapByKey(map, "name");
 * // Prints: true
 *
 * * searchMapByKey(map, "age");
 * // Prints: false
 * ```
 * @param map
 * @param key
 * @returns boolean
 */
export const searchMapByKey = <K, V>(map: Map<K, V>, key: K): boolean => {
  return map.has(key);
};

/**
 * Checks if two arrays are equal by comparing each element.
 *
 * @param array1 - The first array to compare
 * @param array2 - The second array to compare
 * @returns True if the arrays have the same length and contents, false otherwise.
 */
//generate docs
export const isEqual = <T>(array1: T[], array2: T[]): boolean => {
  if (array1?.length !== array2?.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};

/**
 * Checks if two objects are equal by comparing each property.
 *
 * @param oldObj - The old object to compare
 * @param newObj - The new object to compare
 * @returns True if the objects have the same properties and values, false otherwise.
 * @example
 * ```ts
 * const oldObj = { name: "John Doe", age: 30 };
 * const newObj = { name: "John Doe", age: 30 };
 * hasObjectChanged(oldObj, newObj);
 * // Returns: false
 *
 * const oldObj = { name: "John Doe", age: 30 };
 * const newObj = { name: "Jane Doe", age: 30 };
 * hasObjectChanged(oldObj, newObj);
 * // Returns: true
 * ```
 */
export const hasObjectChanged = <T extends Object>(oldObj: T | undefined, newObj: T): boolean => {
  if (!oldObj) {
    // If oldObj is undefined, treat it as if it has changed
    return true;
  }

  const keys = Object.keys(newObj);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    // If the property doesn't exist in the old object or if the value has changed
    if (!oldObj.hasOwnProperty(key) || oldObj[key as keyof T] !== newObj[key as keyof T]) {
      return true;
    }
  }

  return false;
};

/**
 * Compares two arrays and returns objects representing the added, removed, and unchanged elements.
 * @param oldArray The old array to compare.
 * @param newArray The new array to compare.
 * @returns An object containing arrays of added, removed, and unchanged elements.
 *
 * @example
 * ```ts
 * const oldArrayUndefined: number[] | undefined = undefined;
 * const newArray2 = [2, 3, 4];
 * const { added: added2, removed: removed2, unchanged: unchanged2 } = hasArrayChanged(oldArrayUndefined, newArray2);
 * console.log('Added:', added2); // Output: [2, 3, 4]
 * console.log('Removed:', removed2); // Output: []
 * console.log('Unchanged:', unchanged2); // Output: []
 * ```
 */
export function hasArrayChanged<T>(
  oldArray: T[] | undefined,
  newArray: T[],
): {
  added: T[];
  removed: T[];
  unchanged: T[];
} {
  const added: T[] = [];
  const removed: T[] = [];
  const unchanged: T[] = [];

  // Convert arrays to sets for easier comparison
  const oldSet = new Set(oldArray);
  const newSet = new Set(newArray);

  // Find added elements
  newArray.forEach((item) => {
    if (!oldSet.has(item)) {
      added.push(item);
    } else {
      unchanged.push(item);
    }
  });

  // Find removed elements
  oldArray?.forEach((item) => {
    if (!newSet.has(item)) {
      removed.push(item);
    }
  });

  return { added, removed, unchanged };
}

/**
 * Removes all spaces from a string.
 * @param timeString The string to remove spaces from.
 * @returns The string without spaces.
 */
export const eliminateSpace = (timeString: string) => {
  return timeString.replace(/\s+/g, '');
};

/**
 * Removes all spaces from a string.
 * @param timeString The string to remove spaces from.
 * @returns The string without spaces.
 */
export function removeSpaceBetweenNumbers(input: string, separator: string): string {
  return input.replace(/\s*-\s*/g, separator);
}

/**
 * Formats a given time string to a human-readable format.
 * @param inputTime The time string to format.
 * @returns The formatted time string.
 *
 * ```ts
 *const inputTime = '2024-04-12T08:00:00.000Z';
 *const formattedOutput = formatTime(inputTime);
 *console.log(formattedOutput); // Output: Friday, the 12th of Apr. at 11am.
 * ```
 */
export const formatTimeToHumanReadFormat = (inputTime: string): string => {
  const formattedTime = moment(inputTime).format('dddd, [the] Do [of] MMM. [at] ha');
  return formattedTime;
};
