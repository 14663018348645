import CountrySelection from 'components/Shared/PageTemplate/CountrySelection';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 14;
const buttonName = 'whereAreYourTeamMemberRelocatingFrom';
export const WhereAreYourTeamMemberRelocatingFrom = () => {
  return (
    <CountrySelection
      pageNumber={pageNumber}
      buttonName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_WHERE_ARE_MEMBER_RELOCATING_FROM}
    />
  );
};
