import CountrySelection from 'components/Shared/PageTemplate/CountrySelection';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 3;
const buttonName = 'countries';
export const CountriesOrMarket = () => {
  return (
    <CountrySelection
      pageNumber={pageNumber}
      buttonName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_EXPANSION_COUNTRIES_OR_MARKETS}
    />
  );
};
