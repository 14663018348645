import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 11;
const buttonName = 'whendoyouwantprojecttostart';
export const WhenDoYouWantProjectToStart = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_WHEN_PROJECT_STARTS}
    />
  );
};
