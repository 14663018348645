import Typography from 'components/Atomic/Typography/Typography';
import { QuoteCard } from './QuoteCard';
import { Quote, QuoteGroup, QuoteScreen } from 'types/quoteScreen';
import { FunctionComponent } from 'react';
import { PanelTopic } from 'hooks/useSideDrawer';
import { SidePanelInfoContentModel } from 'components/Shared/InfoPanel/InfoPanel.util';

type QuotesProps = {
  content: QuoteScreen;
  onTriggerClickEvent: (panelTopic: PanelTopic) => void;
};
export const Quotes: FunctionComponent<QuotesProps> = ({ content, onTriggerClickEvent }) => {
  return (
    <>
      {content.quoteGroups.map((quoteGroup: QuoteGroup) => {
        return (
          <>
            <div key={quoteGroup.id} className="grid grid-cols-1 gap-[24px]" data-testid="grouped-quote">
              <div className="pl-[16px]">
                <Typography data-testid={`group-title${quoteGroup.id}`} variant="p-medium" className="text-grey-1000">
                  {quoteGroup.title}
                </Typography>
                <Typography
                  data-testid={`group-title${quoteGroup.id}-desc`}
                  variant="p-small"
                  className="text-grey-1000 text-[16px]"
                >
                  {quoteGroup.description}
                </Typography>
              </div>
              {quoteGroup.quotes.map((quote: Quote) => (
                <QuoteCard
                  key={quote.id}
                  quote={quote}
                  onTriggerClick={() =>
                    onTriggerClickEvent(
                      SidePanelInfoContentModel[quote.name.trim() as keyof typeof SidePanelInfoContentModel],
                    )
                  }
                />
              ))}
            </div>
          </>
        );
      })}
    </>
  );
};
