import { MouseEvent } from 'react';
import { StyledSelectBox } from 'components/Shared/StyledSelectBox';
import styled from 'styled-components';
import { GPCountry } from 'utils';
import { ButtonScaleMotion } from 'utils/tailwindConfig/motionConfig';
import { colors } from 'utils/tailwindConfig';

export type CountriesListProps = {
  filteredCountries: GPCountry[];
  selectedCountries: Set<string>;
  handleCountryClick: (event: MouseEvent<HTMLButtonElement>) => void;
};
export const CountriesList = ({ filteredCountries, selectedCountries, handleCountryClick }: CountriesListProps) => {
  return (
    <CountryGrid data-testid="country-grid">
      <section className="flex flex-wrap justify-start items-start gap-4">
        {filteredCountries.map((option) => (
          <StyledSelectBox
            data-testid={`${option.text}-option-button`}
            className="writing-text tag-pills grow border-[1px]"
            transition={ButtonScaleMotion.spring}
            whileHover={{
              scale: ButtonScaleMotion.hoverScale,
            }}
            whileTap={{
              scale: ButtonScaleMotion.pressScale,
            }}
            key={option.text}
            name={option.text}
            value={option.text?.toLowerCase()}
            $checked={selectedCountries.has(option.text!.toLowerCase())}
            onClick={handleCountryClick}
          >
            {option.text}
          </StyledSelectBox>
        ))}
      </section>
    </CountryGrid>
  );
};

const CountryGrid = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 32px;
  padding-bottom: 16px;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-bottom: 1px solid ${colors.grey[300]};
`;
