import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 10;
const buttonName = 'recruitingNeeds';
export const RecruitingNeeds = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_RECRUITING_NEEDS}
    />
  );
};
