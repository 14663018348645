import styled from 'styled-components';

export const Progress = styled.div<{ $size: number }>`
  position: relative;
  display: flex;
  align-self: center;
  box-sizing: border-box;
  gap: 0px;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
`;

export const CheckWrapper = styled.div<{ $size: number }>`
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  scale: 1;
`;

export const CircleWrapper = styled.div<{ $size: number }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
`;
