import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors, fontFamily } from 'utils/tailwindConfig';

export const ToolTipContainer = styled(motion.div)<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    `
  cursor: not-allowed;
  pointer-events: none;
  `};
`;
export const ToolTipArrow = styled(motion.div)`
  position: absolute;
  top: -8px;
  left: 20px;

  clip-path: polygon(80% 0, 20% 0, 50% 50%);
  width: 0;
  z-index: 1;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid ${colors.warning[200]};
`;
export const ToolTip = styled(motion.div)`
  display: flex;
  position: absolute;
  top: -87px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
  box-sizing: border-box;
  padding: 16px;

  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  &::before {
    content: 'after';
    color: transparent;
    position: absolute;
    background-color: ${colors.warning[200]};
    clip-path: polygon(80% 0, 20% 0, 50% 50%);
    bottom: -8px;
  }

  button {
    font-family: ${fontFamily['polysans-neutral']};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.42px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    gap: 4px;
    border-radius: 4px;
    padding: 16px;
    height: auto;
    border-width: 1px;
    border-style: solid;
    -webkit-user-drag: none;
    user-select: none;
    cursor: pointer;
    color: rgb(39, 46, 53);
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(206, 157, 0, 0.3);
    transform: none;
  }
`;
