import { StyledSelectBox } from 'components/Shared/StyledSelectBox';
import { FunctionComponent } from 'react';
import { StyledInfoWrapper, StyledText } from './PrimaryBusinessGoal.styled';
import { CombinedData } from 'pages/NewConfigure/hooks/useCombinedRulesAndOptions';
import { SidePanelInfoPageIndex } from 'components/Shared/InfoPanel/InfoPanel.util';
import { InfoIconSVG } from 'components/Shared/TitleSubTilteInfoPanelTrigger';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';

export type GoalsSectionProps = {
  primaryObjectivesData: CombinedData[];
  selectedRules: any;
  handleButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onInfoPanelTriggerClick: (topic: SidePanelInfoPageIndex) => void;
  identifier: string;
};
export const GoalsSection: FunctionComponent<GoalsSectionProps> = ({
  primaryObjectivesData,
  selectedRules,
  handleButtonClick,
  onInfoPanelTriggerClick,
  identifier,
}) => {
  return (
    <div className="relative grid gap-[16px] auto-rows-[1fr] grid-cols-[repeat(auto-fit,_minmax(min(212px,_100%),_1fr))] my-6">
      {primaryObjectivesData.map((item, index) => {
        const isSelected = selectedRules === item.option?.option;
        const value = item.nextSteps.length > 0 ? item.nextSteps.map(String) : item.redirect;
        return (
          <StyledSelectBox
            id={`${identifier}-${item.option?.option}`}
            data-testid={`${identifier}-${item.option?.option}`}
            key={`${item.label}-${index}`}
            className={`flex border-2 border-grey-400 rounded-lg py-3 pl-4 pr-2 justify-between group cursor-pointer h-auto  ${item.option?.option === 'other' && 'order-1'}`}
            {...ButtonScaleEffectProps}
            $checked={isSelected}
            value={value}
            onClick={handleButtonClick}
            name={item.option?.option}
          >
            <StyledInfoWrapper className="opacity-0 grid group-hover:opacity-100 right-2 top-1">
              <span
                data-testid={`${identifier}-${item.option?.option}-infoPanel-triggerButton`}
                id={`${identifier}-${item.option?.option}-infoPanel-triggerButton`}
                onClick={() => onInfoPanelTriggerClick(item.option?.option as SidePanelInfoPageIndex)}
              >
                <InfoIconSVG opacity="1" $selected={isSelected} />
              </span>
            </StyledInfoWrapper>
            <div className="w-[100%] flex">
              <StyledText data-testid={`${identifier}-${item.option?.option}-text`}>{item.label}</StyledText>
            </div>
          </StyledSelectBox>
        );
      })}
    </div>
  );
};
