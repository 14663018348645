import styled from 'styled-components';

export const ScheduleMeetingContainer = styled.div`
  container-type: inline-size;
  .container_wrapper {
    @container (max-width: 511px) {
      flex-direction: column-reverse;
      .container-row {
        max-width: 100%;
      }
    }
  }
`;
