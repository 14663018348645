import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 5;
const buttonName = 'timeline';
export const Timeline = () => {
  return (
    <LinearSelectLayout
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_TIMELINE}
      pageNumber={pageNumber}
      inputName={buttonName}
      css="tabletDownwards:grow"
    />
  );
};
