import ProspectLayout from 'components/Layout/ProspectLayout';
import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useConfigure } from './hooks/useConfigure';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const Configure: FunctionComponent = observer(() => {
  const {
    asideIsOpen,
    branchPage,
    CurrentComponent,
    setSideDrawer,
    currentPage,
    direction,
    headerTheme,
    pageSteps,
    positionCurrentPageInTheStepsArray,
    navigatePage,
  } = useConfigure();

  return (
    <ProspectLayout
      context="quote"
      asideIsOpen={asideIsOpen}
      toggleAside={setSideDrawer}
      currentPage={currentPage}
      direction={direction}
      headerTheme={headerTheme}
      steps={pageSteps.map(String)}
      progress={Number(positionCurrentPageInTheStepsArray)}
      selected={Number(positionCurrentPageInTheStepsArray)}
      showCheck={branchPage === null}
      navigateToPage={navigatePage}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE}
    >
      {CurrentComponent && <CurrentComponent />}
    </ProspectLayout>
  );
});

export default Configure;
