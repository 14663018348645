import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 12;
const buttonName = 'prioritiesAndFocusArea';
export const PrioritiesAndFocusArea = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      css="tabletDownwards:grow"
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_PROJECT_PRIORITIES_AND_FOCUS_AREAS}
    />
  );
};
