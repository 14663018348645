import { Reducer } from 'react';
import { CmpData, TestimonialStateType } from './Testimonial.types';

export type Action =
  | { type: 'SET_DATA'; payload: CmpData[] }
  | { type: 'RESET_DATA' }
  | { type: 'SET_ACTIVE_INDEX'; payload: number }
  | { type: 'SET_DIRECTION'; payload: number }
  | { type: 'SET_PLAY'; payload: boolean }
  | { type: 'SET_IS_DRAGGING'; payload: boolean }
  | null;

export const TestimonialInitialState: TestimonialStateType = {
  testimonialData: [] as CmpData[],
  activeIndex: 0,
  direction: 1,
  play: true,
  isDragging: false,
};

export const TestimonialReducer: Reducer<TestimonialStateType, Action> = (state, action) => {
  switch (action?.type) {
    case 'SET_DATA':
      return {
        ...state,
        testimonialData: action.payload,
      };
    case 'RESET_DATA':
      return {
        ...state,
        testimonialData: [],
      };
    case 'SET_ACTIVE_INDEX':
      return {
        ...state,
        activeIndex: action.payload,
      };
    case 'SET_DIRECTION':
      return {
        ...state,
        direction: action.payload,
      };
    case 'SET_PLAY':
      return {
        ...state,
        play: action.payload,
      };
    case 'SET_IS_DRAGGING':
      return {
        ...state,
        isDragging: action.payload,
      };
    default:
      return state;
  }
};
