import { useId, useState, useCallback, ChangeEvent, useMemo, MouseEvent } from 'react';
import { useRootStore } from 'contexts/StoreContext';
import { toJS } from 'mobx';
import { allCountries } from 'utils';
import { updateProspectAsync } from 'api/prospect';

export const useCountries = () => {
  const {
    overlayStore: { popOverlay },
    prospectLayoutStore: { prospectInformation },
    configurePageStore: { currentPage, questionStepsData, completedConfigureSteps, setCompletedConfigureSteps },
  } = useRootStore();
  const id = useId();
  const data = questionStepsData[currentPage];
  const { id: prospectId, token } = prospectInformation;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedCountries, setSelectedCountries] = useState<Set<string>>(
    new Set<string>(toJS(completedConfigureSteps.get(Number(currentPage))) ?? []),
  );

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.trim().toLowerCase());
  }, []);

  const filteredCountries = useMemo(() => {
    return allCountries.filter((country) => {
      const lowerCaseText = country.text?.toLowerCase() ?? '';
      return (!searchTerm || lowerCaseText.includes(searchTerm)) && !selectedCountries.has(lowerCaseText);
    });
  }, [searchTerm, selectedCountries]);

  // Callback function to handle country button click
  const handleCountryClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { value } = event.currentTarget;
      setSelectedCountries((prevSelectedCountries) => {
        const newSelectedCountries = new Set(prevSelectedCountries);
        const lowerCaseValue = value.toLowerCase();
        // If value is already in state, remove it
        if (prevSelectedCountries.has(lowerCaseValue)) {
          newSelectedCountries.delete(lowerCaseValue);
        } else {
          // If value is not in state, add it
          newSelectedCountries.add(lowerCaseValue);
        }
        return newSelectedCountries;
      });
    },
    [setSelectedCountries],
  );

  const onDone = () => {
    setCompletedConfigureSteps(Number(currentPage), Array.from(selectedCountries));
    data.questions[0].value = Array.from(selectedCountries);
    updateProspectAsync(prospectId, data, token);
    popOverlay();
  };

  return {
    id,
    selectedCountries,
    handleInputChange,
    filteredCountries,
    handleCountryClick,
    popOverlay,
    onDone,
  };
};
