import { MotionProps } from 'framer-motion';
import { PropsWithChildren } from 'react';

type ExtendedButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & MotionProps & PropsWithChildren;

export const VariantRecord = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  link: 'link',
} as const;

export interface ButtonProps extends ExtendedButtonProps {
  variant: keyof typeof VariantRecord;
  icon?: React.ReactNode;
  className?: string;
  withEffect?: boolean;
}

interface ButtonType {
  [key: string]: string;
}

export const ButtonMap: ButtonType = {
  baseStyle: 'flex justify-center items-center cursor-pointer font-sansslim leading-tight tracking-[0.04em]',
  primary: 'gap-2 px-lg h-[52px] text-[16px] border-[1px] rounded-full  bg-grey-0 border-grey-1100',
  secondary: 'h-[40px] text-[14px] bg-grey-0 border-[1px] rounded-full pl-md pr-[20px] gap-[8px]',
  tertiary:
    'h-[46px] text-[16px] gap-[8px] text-grey-1000 bg-grey-400 rounded-[8px] disabled:border-[1px] disabled:border-grey-500 disabled:cursor-not-allowed disabled:bg-grey-0 disabled:text-grey-700',
  link: 'border-0 text-primary-500 bg-grey-0 hover:underline text-[16px]',
};
