import { useState } from 'react';
import DoneCheck from 'components/Animated/DoneCheck';
import { motion } from 'framer-motion';
import { TalkIntroCTA } from 'pages/NewTalk/Talk.styled';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAllSet } from './useAllSet';
import { useRootStore } from 'contexts/StoreContext';

export const AllSet = () => {
  const [showQuote, setShowQuote] = useState(false);
  const navigate = useNavigate();
  const {
    prospectLayoutStore: { clearProspectInformation },
  } = useRootStore();
  const RedirectToLandingPage = () => {
    clearProspectInformation();
    navigate('/');
  };

  const { allSetContent } = useAllSet();
  return (
    <div className="relative box-border flex flex-col justify-center items-center m-auto h-[350px]">
      <div className="flex items-center justify-center">
        <DoneCheck
          id="done-check"
          playing={true}
          size={100}
          stroke={0.5}
          progressEnd={() => {
            setShowQuote(true);
          }}
        />
      </div>
      {showQuote && (
        <motion.div
          className="flex items-center justify-center py-4 text-center"
          initial={{ height: 0, opacity: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.75 }}
          animate={{ height: 'auto', opacity: 1 }}
        >
          <div className="flex flex-col items-center gap-6">
            <h2 className="survey-question-title">{allSetContent.header}</h2>
            <p className="mb-0 survey-question-description text-grey-800">{allSetContent.subTitle}</p>
            <DoneButton {...ButtonScaleEffectProps} onClick={RedirectToLandingPage} data-testid="all-set-cta">
              Done
            </DoneButton>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const DoneButton = styled(TalkIntroCTA)`
  width: 160px;
`;
