import styled from 'styled-components';
import { fontFamily, fontWeight } from 'utils/tailwindConfig';

export const InfoTitle = styled.h4`
  font-family: ${fontFamily.sans};
  font-size: 12px;
  font-style: normal;
  font-weight: ${fontWeight.normal};
  line-height: 120%;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  opacity: 0.75;
`;

export const InfoInstruction = styled.h1`
  font-family: ${fontFamily.sans};
  font-size: 52px;
  font-style: normal;
  font-weight: ${fontWeight.semibold};
  line-height: 110%;
  margin: 0px;
  padding: 0px;
  @media screen and (max-width: 480px) {
    font-size: 42px;
    line-height: 50.4px;
    letter: 1%;
  }
  @media screen and (max-width: 380px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const InfoDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-family: ${fontFamily.basier};
    font-size: 18px;
    font-style: normal;
    font-weight: ${fontWeight.semibold};
    line-height: 150%;
    letter-spacing: -0.18px;
    margin: 0px;
    padding: 0px;
  }
  li {
    font-family: ${fontFamily.basier};
    font-size: 18px;
    margin: 8px 0px 0px 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: ${fontWeight.normal};
    letter-spacing: -0.18px;
    line-height: 1.2em;
    gap: 8px;
    opacity: 0.8;
  }
`;

export const StyledListItem = styled.li<{ $icon: string }>`
  list-style-image: url(${(props) => props.$icon});
  margin-left: 22px;
`;

export const JumbotronTitle = styled.h1`
  --max-font-size: 51px;
  --min-font-size: 44px;
  --min-font-size-phone: 38px;
  --small-font-size: 33px;

  font-size: clamp(var(--min-font-size), calc(5.6vw + 2px), var(--max-font-size));

  @media (max-width: 450px) {
    font-size: var(--min-font-size-phone);
  }

  @media (max-width: 400px) {
    font-size: var(--small-font-size);
  }
`;
