const ConfigureFlowIdentifiers = {
  CONFIGURE_PAGE: 'ConfigurePage',
  CONFIGURE_PAGE_INTRO_PAGE: 'ConfigurePage-IntroPage',
  CONFIGURE_PAGE_PRIMARY_BUSINESS_GOAL: 'ConfigurePage-PrimaryBusinessGoal',
  CONFIGURE_PAGE_COMPANY_SIZE_AND_INDUSTRY: 'ConfigurePage-CompanySizeAndIndustry',
  CONFIGURE_PAGE_HIRE_OR_RELOCATE: 'ConfigurePage-HireOrRelocate',
  CONFIGURE_PAGE_PRIORITIES_AND_OPERATIONAL_NEEDS: 'ConfigurePage-PrioritiesAndOperationalNeeds',
  CONFIGURE_PAGE_EXPANSION_COUNTRIES_OR_MARKETS: 'ConfigurePage-ExpansionCountriesOrMarkets',
  CONFIGURE_PAGE_CONTACT_FORM: 'ConfigurePage-ContactForm',
  CONFIGURE_PAGE_TIMELINE: 'ConfigurePage-Timeline',
  CONFIGURE_PAGE_REASON_FOR_SWITCH: 'ConfigurePage-ReasonForSwitch',
  CONFIGURE_PAGE_CURRENT_EOR: 'ConfigurePage-CurrentEOR',
  CONFIGURE_PAGE_BUSINESS_OBJECTIVE_FOR_TRANSITION: 'ConfigurePage-BusinessObjectiveForTransition',
  CONFIGURE_PAGE_TEAM_MEMBERS_MANAGED_WITH_EOR: 'ConfigurePage-TeamMembersManagedWithEOR',
  CONFIGURE_PAGE_COMPLETE_SWITCHING_EOR: 'ConfigurePage-CompleteEORSwitch',
  CONFIGURE_PAGE_PRIORITIES_AND_FOCUS_AREAS_FOR_SWITCH: 'ConfigurePage-PrioritiesAndFocusAreasForSwitch',
  CONFIGURE_PAGE_MEMBERS_RELOCATION: 'ConfigurePage-MembersRelocation',
  CONFIGURE_PAGE_WHERE_ARE_MEMBER_RELOCATING_FROM: 'ConfigurePage-WhereAreMembersRelocatingFrom',
  CONFIGURE_PAGE_WHERE_ARE_MEMBER_RELOCATING_TO: 'ConfigurePage-WhereAreMembersRelocatingTo',
  CONFIGURE_PAGE_RELOCATION_TIMELINE: 'ConfigurePage-RelocationTimeline',
  CONFIGURE_PAGE_RELOCATION_TEMP_OR_PERM: 'ConfigurePage-RelocationTemporaryOrPermanent',
  CONFIGURE_PAGE_PRIORITIES_AND_FOCUS_FOR_RELOCATION: 'ConfigurePage-PrioritiesAndFocusForRelocation',
  CONFIGURE_PAGE_HOW_MANY_TEAM_MEMBERS_TO_HIRE: 'ConfigurePage-HowManyTeamMembersToHire',
  CONFIGURE_PAGE_RECRUITING_NEEDS: 'ConfigurePage-RecruitingNeeds',
  CONFIGURE_PAGE_WHEN_PROJECT_STARTS: 'ConfigurePage-WhenProjectStarts',
  CONFIGURE_PAGE_PROJECT_PRIORITIES_AND_FOCUS_AREAS: 'ConfigurePage-ProjectPrioritiesAndFocusAreas',
  CONFIGURE_PAGE_SUBSIDIARY_LOCATIONS: 'ConfigurePage-SubsidiaryLocations',
  CONFIGURE_PAGE_TRANSITION_TEAM_MEMBERS_FROM_SUBSIDIARY_TO_EOR:
    'ConfigurePage-TransitionTeamMembersFromSubsidiaryToEOR',
  CONFIGURE_PAGE_TRANSITION_TIMELINE: 'ConfigurePage-TransitionTimeline',
  CONFIGURE_PAGE_TRANSITION_PRIORITIES_AND_FOCUS_AREAS: 'ConfigurePage-TransitionPrioritiesAndFocusAreas',
  CONFIGURE_PAGE_MANAGE_GLOBAL_CONTRACTOR: 'ConfigurePage-ManageGlobalContractor',
  CONFIGURE_PAGE_MERGERS_AND_ACQUISITIONS: 'ConfigurePage-MergersAndAcquisitions',
  CONFIGURE_PAGE_OTHER: 'ConfigurePage-Other',
  CONFIGURE_PAGE_TRANSITION_TEMPORARY_MEMBERS_TO_PERMANENT_ROLES:
    'ConfigurePage-TransitionTemporaryMembersToPermanentRoles',
};

const TalkToExpertIdentifiers = {
  TALK_TO_EXPERT_PAGE: 'TalkToExpertPage',
  TALK_TO_EXPERT_PAGE_INTRO_PAGE: 'TalkToExpertPage-IntroPage',
  TALK_TO_EXPERT_PAGE_GET_A_CALLBACK_FORM: 'TalkToExpertPage-GetACallbackForm',
  TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING: 'TalkToExpertPage-ScheduleAMeeting',
  TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME: 'TalkToExpertPage-SelectDateAndTime',
  TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME_INFO_PANEL_TRIGGER_BUTTON:
    'TalkToExpertPage-SelectDateAndTime-InfoPanel-TriggerButton',
  TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING_FORM: 'TalkToExpertPage-ScheduleAMeetingForm',
};

const LandingPageIdentifiers = {
  LANDING_PAGE: 'LandingPage',
  LANDING_PAGE_GET_A_PRICE_QUOTE_BUTTON: 'LandingPage-GetAPriceQuoteButton',
  LANDING_PAGE_TALK_TO_AN_EXPERT_BUTTON: 'LandingPage-TalkToAnExpertButton',
};

/**
 * This Record contains all the element identifiers used in the application.
 */
export const ElementIdentifiersRecord = {
  ...LandingPageIdentifiers,
  ...ConfigureFlowIdentifiers,
  ...TalkToExpertIdentifiers,
};
