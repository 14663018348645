import React from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { ButtonMap, ButtonProps } from './Button.types';
import styled from 'styled-components';

const Button: React.FC<ButtonProps> = ({ children, variant, icon, className, withEffect = true, ...rest }) => {
  const hoverScale = 1.04;
  const pressScale = 0.98;
  const spring = { type: 'spring', stiffness: 500, damping: 24, mass: 1 };

  return (
    <StyledButton
      data-testid="button"
      className={twMerge(ButtonMap['baseStyle'], ButtonMap[variant], className)}
      transition={spring}
      whileHover={{
        scale: withEffect ? hoverScale : undefined,
      }}
      whileTap={{
        scale: withEffect ? pressScale : undefined,
      }}
      {...rest}
    >
      {icon}
      {children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled(motion.button)`
  transition:
    background-color 0.25s cubic-bezier(0.95, 0.02, 0.56, 10) 0.3s,
    color 0.34s cubic-bezier(0.95, 0.02, 0.56, 10) 0.4s,
    border-color 0.25s cubic-bezier(0.95, 0.02, 0.56, 10) 0.3s;
`;
