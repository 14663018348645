import { FunctionComponent, MouseEvent } from 'react';
import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import styled from 'styled-components';
import { colors } from 'utils/tailwindConfig';
import { hexToRgba } from 'utils/helpers';

type TitleSubTitleInfoPanelTriggerProps = {
  title: string;
  description: string;
  onClickHandler: (event: MouseEvent<HTMLSpanElement>) => void;
  identifier?: string;
};

export const TitleSubTitleInfoPanelTrigger: FunctionComponent<TitleSubTitleInfoPanelTriggerProps> = ({
  title,
  description,
  onClickHandler,
  identifier,
}) => {
  return (
    <div>
      <p
        className="font-sans text-[28px] font-[400] leading-[120%] tracking-[0.24px] text-grey-1000 mb-4 p-0"
        data-testid={`${identifier}-title`}
      >
        {title}
      </p>
      <div
        className="font-basier text-[18px] font-[400] leading-[150%] tracking-[-0.18px] text-black opacity-[0.85] mb-8 p-0 max-w-[550px]"
        data-testid={`${identifier}-description`}
      >
        {description}
        <button
          data-testid={`${identifier}-title-infoPanel-trigger-Button`}
          id={`${identifier}description-infoPanel-trigger-Button`}
          className="cursor-pointer relative top-[7px] ml-2 bg-white opacity-100"
          onClick={onClickHandler}
        >
          <InfoIconSVG />
        </button>
      </div>
    </div>
  );
};

export const InfoIconSVG = styled(InfoIcon)<{ $selected?: boolean }>`
  stroke: ${({ $selected }) => ($selected ? colors.white : hexToRgba(colors.primary[500], 1))};
  stroke-width: 0;
`;
