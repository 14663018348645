import { motion } from 'framer-motion';
import styled from 'styled-components';

import * as tokens from './tokens';

export const Wrapper = styled(motion.div)`
  position: absolute;
  top: 40%;
  margin: 0;
  right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  cursor: pointer;
  z-index: 1;
`;

export const BulletContainer = styled(motion.div)<{ $size: number }>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  padding: 6px 0px 6px 16px;
  overflow: visible;
  cursor: pointer;
`;

export const Bullet = styled(motion.div)<{
  $size: { height: number; width: number };
}>`
  border-radius: 100px;
  width: ${(p) => p.$size.width}px;
  height: ${(p) => p.$size.height}px;
  border: 1px solid white;
`;

export const Caption = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 0%;
  width: 0px;
  height: 0px;
  overflow: visible;
`;

export const Title = styled(motion.p)<{ $show: boolean; $disabled: boolean }>`
  ${tokens.type.pMedium};
  display: ${(p) => (p.$show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: ${tokens.color.graysWhite};
  cursor: pointer;
  border-radius: 8px;
  color: ${(p) => (p.$disabled ? tokens.color.graysTextDisabled : tokens.color.primaryGPBlue)};
  border: 1px solid ${tokens.color.grays400};
  white-space: nowrap;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &:hover {
    ${(p) => (!p.$disabled ? 'border-color: ' + tokens.color.primaryGPBlue + ';' : null)}
  }
`;

export const Contain = styled(motion.div)<{ $size: number }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: visible;
  cursor: pointer;
`;

export const theme = {
  blue: {
    lightBG: {
      default: {
        backgroundColor: tokens.color.grays400,
        borderColor: 'rgba(0,0,0,0)',
        fill: tokens.color.grays400,
      },
      selected: {
        backgroundColor: tokens.color.graysTextPrimary,
        borderColor: 'rgba(0,0,0,0)',
        fill: tokens.color.graysTextPrimary,
      },
      disabled: {
        backgroundColor: tokens.color.grays100,
        borderColor: 'rgba(0,0,0,0)',
        fill: tokens.color.grays100,
      },
      hoverselected: {
        scale: 1.2,
      },
      hoverdefault: {
        backgroundColor: tokens.color.graysTextTertiary,
        scale: 1.5,
      },
    },
    darkBG: {
      default: {
        backgroundColor: tokens.color.graysWhite35,
        borderColor: 'rgba(0,0,0,0)',
        fill: tokens.color.graysWhite35,
      },
      selected: {
        backgroundColor: tokens.color.graysWhite,
        borderColor: 'rgba(0,0,0,0)',
        fill: tokens.color.graysWhite,
      },
      disabled: {
        backgroundColor: tokens.color.graysWhite10,
        borderColor: 'rgba(0,0,0,0)',
        fill: tokens.color.graysWhite10,
      },
      hoverselected: {
        scale: 1.2,
      },
      hoverdefault: {
        backgroundColor: tokens.color.graysWhite50,
        scale: 1.5,
      },
    },
  },
  orange: {
    lightBG: {
      default: {
        backgroundColor: tokens.color.grays200,
        borderColor: 'rgba(0,0,0,0)',
        fill: tokens.color.grays200,
      },
      selected: {
        backgroundColor: tokens.color.graysTextPrimary,
        borderColor: tokens.color.graysWhite,
        fill: tokens.color.graysTextPrimary,
      },
      disabled: {
        backgroundColor: tokens.color.grays100,
        borderColor: tokens.color.grays100,
        fill: tokens.color.grays100,
      },
    },
    darkBG: {
      default: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: tokens.color.graysTextPrimary,
        fill: tokens.color.grays200,
      },
      selected: {
        backgroundColor: tokens.color.graysTextPrimary,
        borderColor: tokens.color.graysTextPrimary,
        fill: tokens.color.graysTextPrimary,
      },
      disabled: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: tokens.color.grays400,
        fill: tokens.color.grays400,
      },
    },
  },
};
