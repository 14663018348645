import styled from 'styled-components';
import { colors } from 'utils/tailwindConfig';

const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div<{ $size?: number }>`
  border: 3px solid ${colors.grey[100]};
  border-top: 3px solid ${colors.primary[500]};
  border-radius: 50%;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type Props = {
  size?: number;
};
export const LoaderDefaultSize = 60;
export default function Loader({ size = LoaderDefaultSize }: Props) {
  return (
    <LoaderContainer>
      <Spinner $size={size} data-testid="loader" />
    </LoaderContainer>
  );
}
