import { FunctionComponent, useCallback } from 'react';
import { Direction } from 'components/Layout/ProspectLayout.types';
import SurveyBackAndNextButton from 'components/Shared/SurveyBackAndNextButton';
import { useRootStore } from 'contexts/StoreContext';
import { ConfigureStepRecord } from 'pages/NewConfigure/Configure.util';
import { createLeadAsync } from 'api/lead/CreateLead';
import { ContactFormTemplate } from 'components/Shared/ContactFormTemplate/ContactForm';
import { CTARecord } from 'components/Shared/ContactFormTemplate/ContactFrom.types';
import { ContactFormData } from 'components/Shared/ContactFormTemplate/ContactForm.store';
import { parsePhone } from 'components/Shared/ContactFormTemplate/FormConfig';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import { toJS } from 'mobx';
import { FormPayload } from './ContactForm.type';

export const ContactForm: FunctionComponent = () => {
  const {
    configurePageStore: { nextSteps, setCurrentPage, setCurrentQuestionIndex, completedConfigureSteps, setQuoteData },
    prospectLayoutStore: { setDirection, prospectInformation },
  } = useRootStore();

  const { id, token } = prospectInformation;

  const handleSubmit = useCallback(
    (data: ContactFormData) => {
      const formPayload: FormPayload = {
        customer: {
          ...data,
          phoneNumber: parsePhone(data.country, data.phoneNumber),
          termsAccepted: true,
          privacyPolicyAccepted: true,
          country: 'United States',
        },
      };
      createLeadAsync(formPayload, id, token, setQuoteData);
    },
    [id, setQuoteData, token],
  );

  const handleError = useCallback((data: any) => {
    console.log(data);
  }, []);

  const handleBackClick = useCallback(() => {
    setCurrentQuestionIndex(nextSteps.length - 1);
    setCurrentPage(nextSteps[nextSteps.length - 1]);
    setDirection(Direction.back);
  }, [setCurrentQuestionIndex, setCurrentPage, setDirection, nextSteps]);

  const handleNextClick = useCallback(() => {
    setCurrentPage(ConfigureStepRecord.quote);
    setDirection(Direction.forward);
  }, [setCurrentPage, setDirection]);

  const CTA: CTARecord = {
    Comp: SurveyBackAndNextButton,
    props: {
      onBackClick: handleBackClick,
      onNextClick: handleNextClick,
    },
  };

  const primaryGoal = toJS(completedConfigureSteps.get(1));
  const identifier = `${ElementIdentifiersRecord.CONFIGURE_PAGE_CONTACT_FORM}-PG:${primaryGoal}`;
  const infoPanelIndex = `${ElementIdentifiersRecord.CONFIGURE_PAGE_CONTACT_FORM}`;
  return (
    <ContactFormTemplate
      onFormSubmit={handleSubmit}
      onFormError={handleError}
      CTA={CTA}
      instanceId={identifier}
      infoPanelIndex={infoPanelIndex}
    />
  );
};
