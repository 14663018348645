import { GetACallBackPayload } from 'pages/NewTalk/Talk.types';
import { BASE_API_URL } from 'utils/constants';

/**
 * Initiates a callback for a prospect asynchronously.
 * @param payload - The payload to be sent with the callback request.
 * @param id - The ID of the prospect.
 * @param token - The token of the prospect.
 * @param fetchFunction - The function to use for fetching data - optional.
 * @returns The response payload if the request is successful, otherwise an error object.
 */
export const getACallbackNowAsync = async (
  payload: GetACallBackPayload,
  id: string,
  token: string,
  fetchFunction = fetch,
): Promise<any> => {
  const url: RequestInfo = `${BASE_API_URL.ProspectApiUrl}/${id}/lead/callback-now`;
  try {
    const response = await fetchFunction(url, {
      method: 'POST',
      headers: {
        'x-prospect-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      throw new Error(`Failed to Process request.`);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
