import { Controller } from 'react-hook-form';
import { ErrorIcon } from 'components/Atomic/Icon';
import Typography from 'components/Atomic/Typography/Typography';
import { FunctionComponent } from 'react';
import { ExtendedEvent, FieldControllerProps } from '../contactForm.types';

export const InputText: FunctionComponent<FieldControllerProps> = ({ control, config, onFieldChange, value, id }) => {
  return (
    <Controller
      name={config.name}
      rules={config.rules}
      defaultValue={value}
      control={control}
      render={({ field: { ...field }, fieldState }) => {
        const hasError = !!(fieldState && fieldState.isTouched && fieldState.error);
        const errorMsg = fieldState?.error?.message;
        return (
          <div className="flex flex-row items-center justify-start w-full h-12 gap-1 rounded-lg grow bg-grey-100">
            <input
              id={id}
              data-testid={id}
              type="text"
              {...field}
              defaultValue={config.formatter?.(value as string, control._formValues as FormData) || (value as string)}
              onChange={(e) => {
                field.onChange(e.target.value);
                onFieldChange?.(e as ExtendedEvent);
              }}
              value={config.formatter?.(field.value, control._formValues as FormData)}
              className={`h-full border-[1px] pl-4 pr-2 w-full rounded-lg outline-none writing-text text-grey-800 bg-grey-100 autofill:bg-grey-400 focus:border-primary-500 ${hasError ? 'border-error-200' : ' border-grey-100'}`}
            />
            {hasError && <ErrorIcon className="absolute text-red-500 w-lg h-lg right-sm top-[44px] " />}
            {errorMsg && (
              <Typography
                variant="p-xsmall"
                className="absolute z-[10] inset-x-0 rounded-[8px] h-[34px] px-[12px] py-[8px] text-error-200 bg-error-100 bottom-[-38px]"
                data-testid={`${id}-error`}
              >
                {errorMsg}
              </Typography>
            )}
          </div>
        );
      }}
    />
  );
};
