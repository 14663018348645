import { makeObservable, observable, runInAction } from 'mobx';
import RootStore from 'rootstore';

export interface ContactFormData {
  firstName: string;
  lastName: string;
  companyName: string;
  companyEmail: string;
  phoneNumber: string;
  role: string;
  state: string;
  termsAccepted: boolean;
  country: string;
}

export default class ContactFormStore {
  public formData: ContactFormData;

  constructor(_rootStore: RootStore) {
    makeObservable(this, {
      formData: observable.ref,
      setFormData: observable.ref,
    });

    this.formData = { country: 'US' } as ContactFormData;
    this.setFormData = this.setFormData.bind(this);
  }
  setFormData<K extends keyof ContactFormData>(fieldName: K, value: ContactFormData[K]) {
    runInAction(() => (this.formData[fieldName] = value));
  }
}
