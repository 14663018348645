import { FunctionComponent, ReactNode } from 'react';
import { motion } from 'framer-motion';
import { useRootStore } from 'contexts/StoreContext';
import { ConfigureStepRecord } from 'pages/NewConfigure/Configure.util';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';
import { toJS } from 'mobx';

export type BranchPageProps = {
  title: string;
  description: string;
  logo: ReactNode;
  buttonIcon: ReactNode;
  buttonText: string;
  onClick?: () => void;
  instanceId?: string;
};

export const BranchPage: FunctionComponent<BranchPageProps> = ({
  title,
  description,
  logo,
  buttonIcon,
  buttonText,
  onClick,
  instanceId,
}) => {
  const {
    prospectLayoutStore: { setDirection },
    configurePageStore: { setCurrentPage, completedConfigureSteps },
  } = useRootStore();

  const primaryGoal = toJS(completedConfigureSteps.get(1));
  const identifier = `${instanceId}-PG:${primaryGoal}`;

  return (
    <div className="survey-question-container gap-6" data-testid="branch-page-layout">
      <span data-testid={`${identifier}-heroImage`}>{logo}</span>
      <p className="survey-question-title text-center" data-testid={`${identifier}-title`}>
        {title}
      </p>
      <p className="survey-question-description text-center text-grey-800" data-testid={`${identifier}-description`}>
        {description}
      </p>
      <div className="flex place-items-center items-center justify-center gap-8 flex-col">
        <motion.button
          {...ButtonScaleEffectProps}
          id={`${identifier}-CTA`}
          data-testid={`${identifier}-CTA`}
          className="branch-page-button"
          onClick={onClick}
        >
          {buttonIcon}
          {buttonText}
        </motion.button>
        <button
          id={`${identifier}-BackButtonCTA`}
          data-testid={`${identifier}-BackButtonCTA`}
          className="text-primary-500 gap-2 writing-text font-polysans-neutral hover:underline text-[14px] tracking-[0.42px] select-none"
          onClick={() => {
            setCurrentPage(ConfigureStepRecord.primaryBusinessGoal);
            setDirection(Direction.back);
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};
