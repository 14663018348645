import { HeaderProps } from 'components/Header/Header';

export type ProspectLayoutProps = {
  asideIsOpen: boolean;
  currentPage: string | number;
  direction: Direction;
  toggleAside: (value: boolean) => void;
  headerTheme: HeaderProps['theme'];
  steps?: string[];
  context: 'home' | 'quote' | 'contact';
  activateDarkGBOnIntro?: boolean;
  /**
   * This indicates the current position in the entire page flow from beginning to end
   * [intro, primary goals, [2,3,4,5,6], contact page, quotePage]
   *    ↑            ↑            ↑             ↑          ↑
   * [  0,           1,      2,3,4,5,6,         7,         8] // prop steps above : string[]
   * question Steps is flatted out
   * */
  progress?: number;
  /** Show check logo on the progress bar*/
  selected?: number;
  /** Show check logo on the progress bar*/
  showCheck?: boolean;
  navigateToPage?: (index: number) => void;
  instanceId?: string;
};

export const enum Direction {
  forward = 1,
  back = -1,
}
export type DirectionType = keyof typeof Direction;
