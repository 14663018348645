import { Controller } from 'react-hook-form';
import { FunctionComponent } from 'react';
import { ExtendedEvent, FieldControllerPropsWithOptions } from '../contactForm.types';
import Typography from 'components/Atomic/Typography/Typography';
import { CaretDownBlack, ErrorIcon } from 'components/Atomic/Icon';
import { CircleFlag } from 'react-circle-flags';

export const StyledDropDown: FunctionComponent<FieldControllerPropsWithOptions> = ({
  control,
  config,
  options,
  value,
  onFieldChange,
  id,
}) => {
  return (
    <Controller
      name={config.name}
      rules={config.rules}
      control={control}
      defaultValue={value}
      render={({ field: { ...field }, fieldState }) => {
        const hasError = !!(fieldState && fieldState.isTouched && fieldState.error);
        const errorMsg = fieldState?.error?.message;
        const optionlabel = options.find((item) => item.option === field.value)?.label.split('-');
        return (
          <div
            className={`flex border-[1px h-12 gap-1 w-full rounded-lg bg-grey-100 ${hasError ? 'border-error-200' : ' border-grey-100'}  focus:border-primary-500 `}
          >
            <div className="relative flex flex-row w-full grow">
              <div className="flex flex-row min-w-full gap-2 cursor-pointer justify-between items-center py-[12px] px-[12px]">
                <CircleFlag data-testid={`${id}-flag`} countryCode={field.value.toLowerCase()} className="h-5" />
                <Typography variant="p-small" className="" data-testid={`${id}-label`}>
                  {optionlabel?.[1]}
                </Typography>
                <div className="flex place-items-end">
                  <CaretDownBlack className="flex" data-testid={`${id}-caretDown`} />
                </div>
              </div>
              <select
                {...field}
                className="absolute w-full h-full opacity-0 cursor-pointer"
                data-testid={`${id}-select`}
                id={`${id}-select`}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  onFieldChange?.(e as ExtendedEvent);
                }}
              >
                {(options as Array<Record<string, string>>).map((state) => (
                  <option key={state.option} value={state.option} selected={state.option === value}>
                    {' '}
                    {state.label}
                  </option>
                ))}
              </select>

              {hasError && <ErrorIcon className="absolute text-red-500 bg-grey-100 w-lg h-lg right-sm top-[44px]" />}
              {errorMsg && (
                <Typography
                  data-testid={`${id}-error`}
                  id={`${id}-error`}
                  variant="p-xsmall"
                  className="absolute z-[10] inset-x-0 rounded-[8px] h-[34px] px-[16px] py-[8px] text-error-200 bg-error-100 bottom-[-38px]"
                >
                  {errorMsg}
                </Typography>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
