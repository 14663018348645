import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 27;
const buttonName = 'transitionTimeLine';
export const TransitionTimeLine = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      css="tabletDownwards:grow"
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_TRANSITION_TIMELINE}
    />
  );
};
