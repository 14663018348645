import { Dispatch } from 'react';
import { motion } from 'framer-motion';
import { hexToRgba } from 'utils/helpers';
import { colors } from 'utils/tailwindConfig';
import { CmpData } from '../Testimonial.types';
import Typography from 'components/Atomic/Typography/Typography';
import { HandledDragEndType } from '../index.type';

export type TestimonialCardProps = {
  data: CmpData[];
  activeIndex: number;
  direction: number;
  dispatch: Dispatch<any>;
  isDragging: boolean;
  handledDragEnd: ({ offset, velocity }: HandledDragEndType) => void;
  navigate: (page: number) => void;
};

const hoverScaleDescript = 1.02;
const autoplay = true;

function TestimonialCard({
  activeIndex,
  data,
  direction,
  dispatch,
  isDragging,
  handledDragEnd,
  navigate,
}: TestimonialCardProps) {
  const variants = {
    // card enter state
    enter: (direction: number) => {
      return {
        x: direction === 1 ? 500 : -500,
        opacity: 0,
      };
    },
    // animate to show card in this state
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    // exit card to this state
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction === 1 ? -500 : 500,
        opacity: 0,
      };
    },
  };
  return (
    <motion.div
      data-testid="gp-testimonial-card"
      whileHover={{
        scale: hoverScaleDescript,
        backgroundColor: hexToRgba(colors.grey[1100], 0.05),
        border: `1px solid ${hexToRgba(colors.white, 0.2)}`,
      }}
      className="gap-6 border rounded-2xl p-8 mb-8 mt-16 border-white border-opacity-30 flex justify-start flex-col self-end cursor-pointer"
      title={data[activeIndex]?.text}
      initial="enter"
      animate="center"
      exit="exit"
      variants={variants}
      transition={{ ease: 'easeInOut' }}
      custom={direction}
      key={activeIndex}
      onMouseOver={() => dispatch({ type: 'SET_PLAY', payload: false })}
      onMouseOut={() => dispatch({ type: 'SET_PLAY', payload: autoplay })}
      onClick={() => (!isDragging ? navigate(activeIndex + 1) : null)}
      drag="x"
      onDrag={() => dispatch({ type: 'SET_IS_DRAGGING', payload: true })}
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.5}
      onDragEnd={(e, { offset, velocity }) => {
        handledDragEnd({ offset, velocity });
      }}
    >
      <Typography id={`${data[activeIndex]?.id}-testimonial-quote`} variant="p-small" className="line-clamp-3">
        {data[activeIndex]?.text}
      </Typography>
      <Typography
        id={`${data[activeIndex]?.id}-company-logo`}
        variant="p-xsmall"
        className="text-tertiary-500 line-clamp-1"
      >
        {`- ${data[activeIndex]?.name}`}
      </Typography>
    </motion.div>
  );
}

export default TestimonialCard;
