import styled from 'styled-components';
import { colors } from 'utils/tailwindConfig';

type SkeletonProps = {
  width?: string;
  height?: string;
  $borderRadius?: string;
  //This is the initial color which will be switched by the keyframe
  startColor?: string;
  //This is the color which will be switched to at the end keyframe
  finalColor?: string;
  opacity?: string;
  $margin?: string;
};

export const Skeleton = styled.div<SkeletonProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '16px'};
  border-radius: ${({ $borderRadius }) => $borderRadius || '8px'};
  opacity: ${({ opacity }) => opacity || '0.8'};
  margin: ${({ $margin }) => $margin || '0'};
  animation: skeleton-loading 1.4s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({ startColor }) => startColor || colors.loading['dark-grey']};
    }
    100% {
      background-color: ${({ startColor }) => startColor || colors.loading['light-grey']};
    }
  }
`;
