import { motion } from 'framer-motion';
import { IntroCTAStyles } from 'pages/NewConfigure/Survery/ConfigureIntro/ConfigureIntro.styled';
import styled, { css } from 'styled-components';
import { colors } from 'utils/tailwindConfig';

export const TalkIntroCTA = styled(motion.button)`
  ${IntroCTAStyles};
  color: ${colors.grey[1000]};
  border: 1px solid ${colors.grey[1000]};
`;

export const delimiterStyles = css`
  --delimiter-stacked-width: 100px;
  content: ' ';
  position: relative;
  display: inline-block;
  height: 1px;
  padding-inline: 24px;
  width: 50px;
  background: ${colors.grey[400]};
  @media screen and (width <= 850px) {
    width: var(--delimiter-stacked-width);
  }
`;

export const Delimiter = styled.span`
  display: flex;
  gap: 24px;
  place-items: center;
  width: max-content;
  &::before {
    ${delimiterStyles}
  }
  &::after {
    ${delimiterStyles}
  }
`;
export const ActionButtonRow = styled.div`
  justify-content: center;
  @media screen and (width <= 850px) {
    flex-direction: column;
    padding-block: 35px;
  }
  @media screen and (width <= 450px) {
    flex-direction: column;
    padding-block: 45px;
    gap: 12px;
  }
`;
