import Icon, { ico } from 'components/Icon/Icon';
import { useRootStore } from 'contexts/StoreContext';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { colors } from 'utils/tailwindConfig';

export const DirectionButtons = observer(({ instanceId }: { instanceId?: string }) => {
  const {
    talkStore: { setOverload, overload },
  } = useRootStore();

  const isBackEnabled = overload <= 1;

  const onBackClick = () => {
    if (isBackEnabled) return;
    setOverload(overload - 7);
  };

  const onNextClick = () => {
    setOverload(overload + 7);
  };

  return (
    <>
      <LeftButton
        id={`${instanceId}-backButton`}
        data-testid={`${instanceId}-backButton`}
        onClick={onBackClick}
        disabled={isBackEnabled}
      >
        <Icon name={ico.arrowLeft} theme={isBackEnabled ? 'system' : 'blueButton'} />
      </LeftButton>
      <RightButton id={`${instanceId}-nextButton`} data-testid={`${instanceId}-nextButton`} onClick={onNextClick}>
        <Icon name={ico.arrowRight} theme={'blueButton'} />
      </RightButton>
    </>
  );
});

const LeftButton = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px 0px 0px 8px;
  border-width: 1px;
  border-style: solid;
  margin-right: -1px;
  border-color: ${colors.grey[400]};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

const RightButton = styled(LeftButton)`
  border-radius: 0px 8px 8px 0px;
`;
