import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 31;
const buttonName = 'relocateFocusArea';
export const RelocationFocusArea = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_PRIORITIES_AND_FOCUS_FOR_RELOCATION}
    />
  );
};
