import { BASE_API_URL } from 'utils/constants';

export const createProspect = async (fetchFunction = fetch) => {
  const url = `${BASE_API_URL.ProspectApiUrl}`;
  const preferences = {
    campaign: sessionStorage.getItem('utm_campaign'),
    source: sessionStorage.getItem('utm_source'),
    keyword: sessionStorage.getItem('utm_keyword'),
    medium: sessionStorage.getItem('utm_medium'),
  };

  const marketingPreferences = Object.fromEntries(Object.entries(preferences).filter(([_, value]) => value !== null));

  const prospect = {
    prospect: {
      marketingPreferences,
    },
  };
  try {
    const response = await fetchFunction(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(prospect),
    });

    if (response.ok) {
      const payload = await response.json();
      return payload;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateProspectAsync = async (id: string, payload: unknown, token: string, fetchFunction = fetch) => {
  const url = `${BASE_API_URL.ProspectApiUrl}/${id}`;
  try {
    const response = await fetchFunction(url, {
      method: 'PATCH',
      headers: {
        'x-prospect-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const payload: unknown = await response.json();
      return payload;
    }
  } catch (error) {
    console.error(error);
  }
};
