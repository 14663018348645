export type FormStateType = {
  firstName: string;
  firstNameError: boolean;
  firstNameErrorMessage: string;
  lastName: string;
  lastNameError: boolean;
  lastNameErrorMessage: string;
  companyName: string;
  companyNameError: boolean;
  companyErrorMessage: string;
  companyEmail: string;
  companyEmailError: boolean;
  companyEmailErrorMessage: string;
  phoneNumber: string;
  phoneNumberError: boolean;
  phoneNumberErrorMessage: string;
  role: string;
  country: string;
  acceptedTerms: boolean;
  countryState: string;
};

export const initialState: FormStateType = {
  firstName: '',
  firstNameError: false,
  firstNameErrorMessage: '',
  lastName: '',
  lastNameError: false,
  lastNameErrorMessage: '',
  companyName: '',
  companyNameError: false,
  companyErrorMessage: '',
  companyEmail: '',
  companyEmailError: false,
  companyEmailErrorMessage: '',
  phoneNumber: '',
  phoneNumberError: false,
  phoneNumberErrorMessage: '',
  role: '',
  country: '',
  acceptedTerms: false,
  countryState: '',
};

export let savedState = {};

export const saveStateForm = (stateToSave: any) => {
  savedState = stateToSave;
};

export enum ActionType {
  UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME',
  UPDATE_FIRST_NAME_ERROR = 'UPDATE_FIRST_NAME_ERROR',
  UPDATE_LAST_NAME = 'UPDATE_LAST_NAME',
  UPDATE_LAST_NAME_ERROR = 'UPDATE_LAST_NAME_ERROR',
  UPDATE_COMPANY_NAME = 'UPDATE_COMPANY_NAME',
  UPDATE_COMPANY_NAME_ERROR = 'UPDATE_COMPANY_NAME_ERROR',
  UPDATE_COMPANY_EMAIL = 'UPDATE_COMPANY_EMAIL',
  UPDATE_COMPANY_EMAIL_ERROR = 'UPDATE_COMPANY_EMAIL_ERROR',
  UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER',
  UPDATE_PHONE_NUMBER_ERROR = 'UPDATE_PHONE_NUMBER_ERROR',
  UPDATE_ROLE = 'UPDATE_ROLE',
  UPDATE_COUNTRY = 'UPDATE_COUNTRY',
  UPDATE_ACCEPTED_TERMS = 'UPDATE_ACCEPTED_TERMS',
  UPDATE_STATE = 'UPDATE_STATE',
}

export type FormActionType = {
  type: ActionType;
  payload: string | boolean;
};

export const reducer = (state: FormStateType, action: FormActionType): FormStateType => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.UPDATE_FIRST_NAME: {
      return {
        ...state,
        firstName: payload as string,
      };
    }
    case ActionType.UPDATE_FIRST_NAME_ERROR: {
      const errorMessage = payload ? 'Please enter a valid name' : '';
      return {
        ...state,
        firstNameError: payload as boolean,
        firstNameErrorMessage: errorMessage,
      };
    }
    case ActionType.UPDATE_LAST_NAME: {
      return {
        ...state,
        lastName: payload as string,
      };
    }
    case ActionType.UPDATE_LAST_NAME_ERROR: {
      const errorMessage = payload ? 'Please enter a valid name' : '';
      return {
        ...state,
        lastNameError: payload as boolean,
        lastNameErrorMessage: errorMessage,
      };
    }
    case ActionType.UPDATE_COMPANY_NAME: {
      return {
        ...state,
        companyName: payload as string,
      };
    }
    case ActionType.UPDATE_COMPANY_NAME_ERROR: {
      const errorMessage = payload ? 'Please enter a valid company name' : '';
      return {
        ...state,
        companyNameError: payload as boolean,
        companyErrorMessage: errorMessage,
      };
    }
    case ActionType.UPDATE_COMPANY_EMAIL: {
      return {
        ...state,
        companyEmail: payload as string,
      };
    }
    case ActionType.UPDATE_COMPANY_EMAIL_ERROR: {
      const errorMessage = payload ? 'Please enter a valid email' : '';
      return {
        ...state,
        companyEmailError: payload as boolean,
        companyEmailErrorMessage: errorMessage,
      };
    }
    case ActionType.UPDATE_PHONE_NUMBER: {
      return {
        ...state,
        phoneNumber: payload as string,
      };
    }
    case ActionType.UPDATE_PHONE_NUMBER_ERROR: {
      const errorMessage = payload
        ? "Review the amount of digits and the values you've typed. We only support numbers and + ( ) characters"
        : '';
      return {
        ...state,
        phoneNumberError: payload as boolean,
        phoneNumberErrorMessage: errorMessage,
      };
    }
    case ActionType.UPDATE_ROLE: {
      return {
        ...state,
        role: payload as string,
      };
    }
    case ActionType.UPDATE_COUNTRY: {
      return {
        ...state,
        country: payload as string,
      };
    }
    case ActionType.UPDATE_ACCEPTED_TERMS: {
      return {
        ...state,
        acceptedTerms: payload as boolean,
      };
    }
    case ActionType.UPDATE_STATE: {
      return {
        ...state,
        countryState: payload as string,
      };
    }
    default:
      return state;
  }
};

export type ProspectType = {
  token: string;
  id: string;
  questionary: any[];
};

export type GPCountry = {
  text?: string;
  label?: string;
  option?: string;
  alt?: string;
  isoCode?: string;
};

export const topCountries: GPCountry[] = [
  { option: 'canada', label: 'Canada', isoCode: 'ca' },
  { option: 'uk', label: 'UK', isoCode: 'gb' },
  { option: 'philippines', label: 'Philippines', isoCode: 'ph' },
  { option: 'india', label: 'India', isoCode: 'in' },
  { option: 'spain', label: 'Spain', isoCode: 'es' },
  { option: 'germany', label: 'Germany', isoCode: 'de' },
  { option: 'mexico', label: 'Mexico', isoCode: 'mx' },
  { option: 'australia', label: 'Australia', isoCode: 'au' },
  { option: 'china', label: 'China', isoCode: 'ca' },
  { option: 'brazil', label: 'Brazil', isoCode: 'br' },
];

export const ordinal = (value: number) => {
  switch (value) {
    case 1:
    case 21:
      return value + 'st';
    case 2:
    case 22:
      return value + 'nd';
    case 3:
    case 23:
      return value + 'rd';
    default:
      return value + 'th';
  }
};

export const helpText = () => {
  return [
    {
      title: 'Why we ask about your primary business goals.',
      description:
        "Based on your business goals and objectives, we'll match the solutions and services we think you'll need.	As an industry leader, we'll build off the data and experience we've gained from past projects like yours to recommend a setup for success.",
    },
    {
      title: 'Entering new markets',
      description:
        "Test a new market: Before committing significant resources, companies like yours can use an Employer 	of Record (EOR) to test the market.	If you're embracing remote work and want to hire talent from anywhere in the world, partnering with G-P can simplify the process, manage compliance, and reduce associated risks.",
    },
    {
      title: 'Transition from temporary to permanent roles',
      description: 'Choose this scenario if you are looking to transition temporary team members to permanent roles.',
    },
    {
      title: 'Engage and Manage Contractors',
      description:
        'Hiring contractors is fairly straightforward, and we can help with receiving and paying contractor invoices quickly.',
    },
    {
      title: 'Switching Providers',
      description:
        "If you're switching Employer of Record (EOR) providers, it's essential to conduct a thorough assessment to check whether the new provider aligns with your business needs, offers superior services, and can assist with a smooth transition for your global workforce.",
    },
    {
      title: 'Relocating Team Member',
      description:
        'Our solutions streamline the process of hiring, onboarding, managing, and paying team members. It offers a centralized platform to handle various aspects of the relationship for efficiency, compliance, and reduced risks.',
    },
    {
      title: 'Limited Need',
      description:
        "When you have a limited need, are in a country with challenging employment laws, or don't have an established entity, our Employer of Record (EOR) solution can be invaluable. We enable you to quickly hire and manage new team members using indefinite or fixed term employment contracts (where possible).",
    },
    {
      title: 'Mergers & Acquisitions',
      description:
        'Navigating Mergers and Acquisitions (M&A) can be a complex process, especially when integrating team 	members from different jurisdictions. We can help can simplify the transitional phase, maintain culture and teams, and seamlessly manage and retain your workforce',
    },
    {
      title: 'Entity Wind Downs',
      description:
        'Winding down a subsidiary, especially in foreign jurisdictions, presents unique challenges relating to 	compliance as well as managing your team members. We can recommend and provide solutions that suit your unique needs during this 	transition.',
    },
    {
      title: 'Other Situations',
      description:
        'While there are many common scenarios where businesses utilize our services, there are also unique, 	specific situations where we can provide invaluable support. Whatever your particular need, we offer flexibility 	and expertise to help you navigate complex employment landscapes.',
    },
  ];
};

export const quoteHelpText = () => {
  return [
    {
      title: 'Clarifying Pricing Information',
      description:
        "Because the total cost of employment varies considerably based on an individual's salary, bonus, benefits, and legal costs 	related to employment compliance in each country, we provide pricing that reflects our monthly services fee only. The amount we ultimately pay each individual, will include the compensation package you agree to with each 	individual, plus any costs related to employment compliance in their country (taxes, required benefits, and so on)",
    },
    {
      title: 'G-P Meridian Core',
      description:
        "With G-P Meridian Core, you'll find all the essential tools you need to, hire, and manage your global workforce, all with unmatched global and local compliance, and unbeatable speed to any market you choose.",
    },
    {
      title: 'On Time Setup',
      description:
        "With our one-time setup, you'll get a G-P account with guidance on key entitlements and access to our knowledge base.",
    },
    {
      title: 'Employee Off Boarding',
      description:
        'Every time a team member is off boarded or transitioned off the G-P platform, we ensure all legal, compliance and operational requirements are met.',
    },
    {
      title: 'G-P Recruit',
      description:
        'Find, attract, and recruit the best talent at the right cost globally, through our all-in-one recruitment platform. Access local expertise, insights, and talent pools through our network of recruitment specialists. Easily manage the entire HR process from recruitment and a locally compliant employment contract through to onboarding, without having to navigate multiple platforms',
    },
    {
      title: 'Background Checks',
      description:
        "Get visibility on locally permissible background checks. Verify the accuracy of an applicant's assertions and ensure they are who they say they are. Optimize your recruitment and selection process with a single solution by requesting background checks without leaving the G-P Meridian platform.",
    },
  ];
};

export const calendarHelpInfo = () => {
  return {
    title: 'Meeting with our Global Experts',
    description:
      "Our global experts are available for a live meeting to discuss your goals and needs. All meetings are video enabled to allow for screen sharing - this makes demos, walk throughs and presenting materials much easier, but you don't have to appear on camera.",
  };
};

export const statesArray = () => {
  return [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ];
};

export const allCountries: GPCountry[] = [
  { text: 'Albania' },
  { text: 'Algeria' },
  { text: 'Andorra' },
  { text: 'Angola' },
  { text: 'Argentina' },
  { text: 'Armenia' },
  { text: 'Aruba' },
  { text: 'Australia' },
  { text: 'Austria' },
  { text: 'Azerbaijan' },
  { text: 'Bahamas' },
  { text: 'Bahrain' },
  { text: 'Bangladesh' },
  { text: 'Barbados' },
  { text: 'Belarus' },
  { text: 'Belgium' },
  { text: 'Benin' },
  { text: 'Bermuda' },
  { text: 'Bolivia' },
  { text: 'Bosnia and Herzegovina' },
  { text: 'Botswana' },
  { text: 'Brazil' },
  { text: 'Brunei' },
  { text: 'Bulgaria' },
  { text: 'Burkina Faso' },
  { text: 'Cambodia' },
  { text: 'Cameroon' },
  { text: 'Canada' },
  { text: 'Cayman Islands (UK)' },
  { text: 'Chad' },
  { text: 'Chile' },
  { text: 'China' },
  { text: 'Colombia' },
  { text: 'Comoros' },
  { text: 'Costa Rica' },
  { text: "Cote d'Ivoire" },
  { text: 'Croatia' },
  { text: 'Curacao (Netherlands)' },
  { text: 'Cyprus' },
  { text: 'Czech Republic' },
  { text: 'Democratic Rep of Congo' },
  { text: 'Denmark' },
  { text: 'Djbouti' },
  { text: 'Dominican Republic' },
  { text: 'Dominica' },
  { text: 'Ecuador' },
  { text: 'Egypt' },
  { text: 'El Salvador' },
  { text: 'Equatorial Guinea' },
  { text: 'Estonia' },
  { text: 'Ethiopia' },
  { text: 'Faroe Islands' },
  { text: 'Fiji' },
  { text: 'Finland' },
  { text: 'France' },
  { text: 'Gabon' },
  { text: 'Gambia' },
  { text: 'Georgia' },
  { text: 'Germany' },
  { text: 'Ghana' },
  { text: 'Greece' },
  { text: 'Guatemala' },
  { text: 'Guernsey' },
  { text: 'Guinea' },
  { text: 'Guyana' },
  { text: 'Haiti' },
  { text: 'Honduras' },
  { text: 'Hong Kong' },
  { text: 'Hungary' },
  { text: 'Iceland' },
  { text: 'India' },
  { text: 'Indonesia' },
  { text: 'Ireland' },
  { text: 'Isle of Man' },
  { text: 'Israel' },
  { text: 'Italy' },
  { text: 'Jamaica' },
  { text: 'Japan' },
  { text: 'Jersey' },
  { text: 'Jordan' },
  { text: 'Kazakhstan' },
  { text: 'Kenya' },
  { text: 'Kosovo' },
  { text: 'Kuwait' },
  { text: 'Kyrgyzstan' },
  { text: 'Laos' },
  { text: 'Latvia' },
  { text: 'Lebanon' },
  { text: 'Lesotho' },
  { text: 'Liberia' },
  { text: 'Liechtenstein' },
  { text: 'Lithuania' },
  { text: 'Luxembourg' },
  { text: 'Macedonia' },
  { text: 'Madagascar' },
  { text: 'Malawi' },
  { text: 'Malaysia' },
  { text: 'Maldives' },
  { text: 'Mali' },
  { text: 'Malta' },
  { text: 'Mauritania' },
  { text: 'Mauritius' },
  { text: 'Mexico' },
  { text: 'Moldova' },
  { text: 'Montenegro' },
  { text: 'Morocco' },
  { text: 'Mozambique' },
  { text: 'Myanmar' },
  { text: 'Namibia' },
  { text: 'Nepal' },
  { text: 'Netherlands' },
  { text: 'New Zealand' },
  { text: 'Nicaragua' },
  { text: 'Niger' },
  { text: 'Nigeria' },
  { text: 'Norway' },
  { text: 'Pakistan' },
  { text: 'Palestine' },
  { text: 'Panama' },
  { text: 'Papua New Guinea' },
  { text: 'Paraguay' },
  { text: 'Peru' },
  { text: 'Philippines' },
  { text: 'Poland' },
  { text: 'Portugal' },
  { text: 'Puerto Rico' },
  { text: 'Qatar' },
  { text: 'Republic of the Congo' },
  { text: 'Romania' },
  { text: 'Russia' },
  { text: 'Rwanda' },
  { text: 'Saint Kitts and Nevis' },
  { text: 'Saudi Arabia' },
  { text: 'Senegal' },
  { text: 'Serbia' },
  { text: 'Seychelles' },
  { text: 'Sierra Leone' },
  { text: 'Singapore' },
  { text: 'Slovakia' },
  { text: 'Slovenia' },
  { text: 'South Africa' },
  { text: 'South Korea' },
  { text: 'Spain' },
  { text: 'Sri Lanka' },
  { text: 'Suriname' },
  { text: 'Swaziland / Eswatini' },
  { text: 'Sweden' },
  { text: 'Switzerland' },
  { text: 'Taiwan' },
  { text: 'Tajikistan' },
  { text: 'Tanzania' },
  { text: 'Thailand' },
  { text: 'Togo' },
  { text: 'Trinidad and Tobago' },
  { text: 'Tunisia' },
  { text: 'Türkiye' },
  { text: 'Turkmenistan' },
  { text: 'Uganda' },
  { text: 'Ukraine' },
  { text: 'United Arab Emirates', alt: 'UAE' },
  { text: 'United Kingdom', alt: 'UK' },
  { text: 'United States', alt: 'USA' },
  { text: 'Uruguay' },
  { text: 'Uzbekistan' },
  { text: 'Vietnam' },
  { text: 'Zambia' },
  { text: 'Zanzibar' },
  { text: 'Zimbabwe' },
];

export interface CountryType {
  option: string;
  label: string;
  isoCode: string;
}

export const countries: CountryType[] = [
  {
    option: 'afghanistan',
    label: 'Afghanistan',
    isoCode: 'af',
  },
  {
    option: 'åland islands (finland)',
    label: 'Åland Islands (Finland)',
    isoCode: 'ax',
  },
  {
    option: 'albania',
    label: 'Albania',
    isoCode: 'al',
  },
  {
    option: 'algeria',
    label: 'Algeria',
    isoCode: 'dz',
  },
  {
    option: 'american samoa (usa)',
    label: 'American Samoa (USA)',
    isoCode: 'as',
  },
  {
    option: 'andorra',
    label: 'Andorra',
    isoCode: 'ad',
  },
  {
    option: 'angola',
    label: 'Angola',
    isoCode: 'ao',
  },
  {
    option: 'anguilla (uk)',
    label: 'Anguilla (UK)',
    isoCode: 'ai',
  },
  {
    option: 'antarctica',
    label: 'Antarctica',
    isoCode: 'aq',
  },
  {
    option: 'antigua and barbuda',
    label: 'Antigua and Barbuda',
    isoCode: 'ag',
  },
  {
    option: 'argentina',
    label: 'Argentina',
    isoCode: 'ar',
  },
  {
    option: 'armenia',
    label: 'Armenia',
    isoCode: 'am',
  },
  {
    option: 'aruba (netherlands)',
    label: 'Aruba (Netherlands)',
    isoCode: 'aw',
  },
  {
    option: 'australia',
    label: 'Australia',
    isoCode: 'au',
  },
  {
    option: 'austria',
    label: 'Austria',
    isoCode: 'at',
  },
  {
    option: 'azerbaijan',
    label: 'Azerbaijan',
    isoCode: 'az',
  },
  {
    option: 'bahamas',
    label: 'Bahamas',
    isoCode: 'bs',
  },
  {
    option: 'bahrain',
    label: 'Bahrain',
    isoCode: 'bh',
  },
  {
    option: 'bangladesh',
    label: 'Bangladesh',
    isoCode: 'bd',
  },
  {
    option: 'barbados',
    label: 'Barbados',
    isoCode: 'bb',
  },
  {
    option: 'belarus',
    label: 'Belarus',
    isoCode: 'by',
  },
  {
    option: 'belgium',
    label: 'Belgium',
    isoCode: 'be',
  },
  {
    option: 'belize',
    label: 'Belize',
    isoCode: 'bz',
  },
  {
    option: 'benin',
    label: 'Benin',
    isoCode: 'bj',
  },
  {
    option: 'bermuda (uk)',
    label: 'Bermuda (UK)',
    isoCode: 'bm',
  },
  {
    option: 'bhutan',
    label: 'Bhutan',
    isoCode: 'bt',
  },
  {
    option: 'bolivia',
    label: 'Bolivia',
    isoCode: 'bo',
  },
  {
    option: 'bosnia and herzegovina',
    label: 'Bosnia and Herzegovina',
    isoCode: 'ba',
  },
  {
    option: 'botswana',
    label: 'Botswana',
    isoCode: 'bw',
  },
  {
    option: 'bouvet island',
    label: 'Bouvet Island',
    isoCode: 'bv',
  },
  {
    option: 'brazil',
    label: 'Brazil',
    isoCode: 'br',
  },
  {
    option: 'british indian ocean territory (uk)',
    label: 'British Indian Ocean Territory (UK)',
    isoCode: 'io',
  },
  {
    option: 'british virgin islands (uk)',
    label: 'British Virgin Islands (UK)',
    isoCode: 'vg',
  },
  {
    option: 'brunei',
    label: 'Brunei',
    isoCode: 'bn',
  },
  {
    option: 'bulgaria',
    label: 'Bulgaria',
    isoCode: 'bg',
  },
  {
    option: 'burkina faso',
    label: 'Burkina Faso',
    isoCode: 'bf',
  },
  {
    option: 'burundi',
    label: 'Burundi',
    isoCode: 'bi',
  },
  {
    option: 'caribbean netherlands',
    label: 'Caribbean Netherlands',
    isoCode: 'bq',
  },
  {
    option: 'cape verde',
    label: 'Cape Verde',
    isoCode: 'cv',
  },
  {
    option: 'cambodia',
    label: 'Cambodia',
    isoCode: 'kh',
  },
  {
    option: 'cameroon',
    label: 'Cameroon',
    isoCode: 'cm',
  },
  {
    option: 'canada',
    label: 'Canada',
    isoCode: 'ca',
  },
  {
    option: 'caribbean netherlands (netherlands)',
    label: 'Caribbean Netherlands (Netherlands)',
    isoCode: 'an',
  },
  {
    option: 'cayman islands (uk)',
    label: 'Cayman Islands (UK)',
    isoCode: 'ky',
  },
  {
    option: 'central african republic',
    label: 'Central African Republic',
    isoCode: 'cf',
  },
  {
    option: 'chad',
    label: 'Chad',
    isoCode: 'td',
  },
  {
    option: 'chile',
    label: 'Chile',
    isoCode: 'cl',
  },
  {
    option: 'china',
    label: 'China',
    isoCode: 'cn',
  },
  {
    option: 'christmas island (australia)',
    label: 'Christmas Island (Australia)',
    isoCode: 'cx',
  },
  {
    option: 'cocos (keeling) islands (australia)',
    label: 'Cocos (Keeling) Islands (Australia)',
    isoCode: 'cc',
  },
  {
    option: 'colombia',
    label: 'Colombia',
    isoCode: 'co',
  },
  {
    option: 'comoros',
    label: 'Comoros',
    isoCode: 'km',
  },
  {
    option: 'congo, republic of the',
    label: 'Congo, Republic of the',
    isoCode: 'cg',
  },
  {
    option: 'congo, democratic republic of the',
    label: 'Congo, Democratic Republic of the',
    isoCode: 'cd',
  },
  {
    option: 'cook islands (new zealand)',
    label: 'Cook Islands (New Zealand)',
    isoCode: 'ck',
  },
  {
    option: 'costa rica',
    label: 'Costa Rica',
    isoCode: 'cr',
  },
  {
    option: "cote d'ivoire",
    label: "Cote d'Ivoire",
    isoCode: 'ci',
  },
  {
    option: 'croatia',
    label: 'Croatia',
    isoCode: 'hr',
  },
  {
    option: 'cuba',
    label: 'Cuba',
    isoCode: 'cu',
  },
  {
    option: 'curaçao (netherlands)',
    label: 'Curaçao (Netherlands)',
    isoCode: 'cw',
  },
  {
    option: 'cyprus',
    label: 'Cyprus',
    isoCode: 'cy',
  },
  {
    option: 'czech republic',
    label: 'Czech Republic',
    isoCode: 'cz',
  },
  {
    option: 'denmark',
    label: 'Denmark',
    isoCode: 'dk',
  },
  {
    option: 'djibouti',
    label: 'Djibouti',
    isoCode: 'dj',
  },
  {
    option: 'dominica',
    label: 'Dominica',
    isoCode: 'dm',
  },
  {
    option: 'dominican republic',
    label: 'Dominican Republic',
    isoCode: 'do',
  },
  {
    option: 'ecuador',
    label: 'Ecuador',
    isoCode: 'ec',
  },
  {
    option: 'egypt',
    label: 'Egypt',
    isoCode: 'eg',
  },
  {
    option: 'el salvador',
    label: 'El Salvador',
    isoCode: 'sv',
  },
  {
    option: 'equatorial guinea',
    label: 'Equatorial Guinea',
    isoCode: 'gq',
  },
  {
    option: 'eritrea',
    label: 'Eritrea',
    isoCode: 'er',
  },
  {
    option: 'estonia',
    label: 'Estonia',
    isoCode: 'ee',
  },
  {
    option: 'ethiopia',
    label: 'Ethiopia',
    isoCode: 'et',
  },
  {
    option: 'falkland islands (uk)',
    label: 'Falkland Islands (UK)',
    isoCode: 'fk',
  },
  {
    option: 'faroe islands (denmark)',
    label: 'Faroe Islands (Denmark)',
    isoCode: 'fo',
  },
  {
    option: 'fiji',
    label: 'Fiji',
    isoCode: 'fj',
  },
  {
    option: 'finland',
    label: 'Finland',
    isoCode: 'fi',
  },
  {
    option: 'france',
    label: 'France',
    isoCode: 'fr',
  },
  {
    option: 'french guiana (france)',
    label: 'French Guiana (France)',
    isoCode: 'gf',
  },
  {
    option: 'french polynesia (france)',
    label: 'French Polynesia (France)',
    isoCode: 'pf',
  },
  {
    option: 'french southern territories',
    label: 'French Southern Territories',
    isoCode: 'tf',
  },
  {
    option: 'gabon',
    label: 'Gabon',
    isoCode: 'ga',
  },
  {
    option: 'gambia',
    label: 'Gambia',
    isoCode: 'gm',
  },
  {
    option: 'georgia',
    label: 'Georgia',
    isoCode: 'ge',
  },
  {
    option: 'germany',
    label: 'Germany',
    isoCode: 'de',
  },
  {
    option: 'ghana',
    label: 'Ghana',
    isoCode: 'gh',
  },
  {
    option: 'gibraltar (uk)',
    label: 'Gibraltar (UK)',
    isoCode: 'gi',
  },
  {
    option: 'greece',
    label: 'Greece',
    isoCode: 'gr',
  },
  {
    option: 'greenland (denmark)',
    label: 'Greenland (Denmark)',
    isoCode: 'gl',
  },
  {
    option: 'grenada',
    label: 'Grenada',
    isoCode: 'gd',
  },
  {
    option: 'guadeloupe (france)',
    label: 'Guadeloupe (France)',
    isoCode: 'gp',
  },
  {
    option: 'guam (usa)',
    label: 'Guam (USA)',
    isoCode: 'gu',
  },
  {
    option: 'guatemala',
    label: 'Guatemala',
    isoCode: 'gt',
  },
  {
    option: 'guernsey (uk)',
    label: 'Guernsey (UK)',
    isoCode: 'gg',
  },
  {
    option: 'guinea',
    label: 'Guinea',
    isoCode: 'gn',
  },
  {
    option: 'guinea-bissau',
    label: 'Guinea-Bissau',
    isoCode: 'gw',
  },
  {
    option: 'guyana',
    label: 'Guyana',
    isoCode: 'gy',
  },
  {
    option: 'haiti',
    label: 'Haiti',
    isoCode: 'ht',
  },
  {
    option: 'heard island and mcdonald islands',
    label: 'Heard Island and McDonald Islands',
    isoCode: 'hm',
  },
  {
    option: 'honduras',
    label: 'Honduras',
    isoCode: 'hn',
  },
  {
    option: 'hong kong (china)',
    label: 'Hong Kong (China)',
    isoCode: 'hk',
  },
  {
    option: 'hungary',
    label: 'Hungary',
    isoCode: 'hu',
  },
  {
    option: 'iceland',
    label: 'Iceland',
    isoCode: 'is',
  },
  {
    option: 'india',
    label: 'India',
    isoCode: 'in',
  },
  {
    option: 'indonesia',
    label: 'Indonesia',
    isoCode: 'id',
  },
  {
    option: 'iran',
    label: 'Iran',
    isoCode: 'ir',
  },
  {
    option: 'iraq',
    label: 'Iraq',
    isoCode: 'iq',
  },
  {
    option: 'ireland',
    label: 'Ireland',
    isoCode: 'ie',
  },
  {
    option: 'isle of man (uk)',
    label: 'Isle of Man (UK)',
    isoCode: 'im',
  },
  {
    option: 'israel',
    label: 'Israel',
    isoCode: 'il',
  },
  {
    option: 'italy',
    label: 'Italy',
    isoCode: 'it',
  },
  {
    option: 'jamaica',
    label: 'Jamaica',
    isoCode: 'jm',
  },
  {
    option: 'japan',
    label: 'Japan',
    isoCode: 'jp',
  },
  {
    option: 'jersey (uk)',
    label: 'Jersey (UK)',
    isoCode: 'je',
  },
  {
    option: 'jordan',
    label: 'Jordan',
    isoCode: 'jo',
  },
  {
    option: 'kazakhstan',
    label: 'Kazakhstan',
    isoCode: 'kz',
  },
  {
    option: 'kenya',
    label: 'Kenya',
    isoCode: 'ke',
  },
  {
    option: 'kiribati',
    label: 'Kiribati',
    isoCode: 'ki',
  },
  {
    option: 'kosovo',
    label: 'Kosovo',
    isoCode: 'xk',
  },
  {
    option: 'kuwait',
    label: 'Kuwait',
    isoCode: 'kw',
  },
  {
    option: 'kyrgyzstan',
    label: 'Kyrgyzstan',
    isoCode: 'kg',
  },
  {
    option: 'laos',
    label: 'Laos',
    isoCode: 'la',
  },
  {
    option: 'latvia',
    label: 'Latvia',
    isoCode: 'lv',
  },
  {
    option: 'lebanon',
    label: 'Lebanon',
    isoCode: 'lb',
  },
  {
    option: 'lesotho',
    label: 'Lesotho',
    isoCode: 'ls',
  },
  {
    option: 'liberia',
    label: 'Liberia',
    isoCode: 'lr',
  },
  {
    option: 'libya',
    label: 'Libya',
    isoCode: 'ly',
  },
  {
    option: 'liechtenstein',
    label: 'Liechtenstein',
    isoCode: 'li',
  },
  {
    option: 'lithuania',
    label: 'Lithuania',
    isoCode: 'lt',
  },
  {
    option: 'luxembourg',
    label: 'Luxembourg',
    isoCode: 'lu',
  },
  {
    option: 'macau (china)',
    label: 'Macau (China)',
    isoCode: 'mo',
  },
  {
    option: 'macedonia',
    label: 'Macedonia',
    isoCode: 'mk',
  },
  {
    option: 'madagascar',
    label: 'Madagascar',
    isoCode: 'mg',
  },
  {
    option: 'malawi',
    label: 'Malawi',
    isoCode: 'mw',
  },
  {
    option: 'malaysia',
    label: 'Malaysia',
    isoCode: 'my',
  },
  {
    option: 'maldives',
    label: 'Maldives',
    isoCode: 'mv',
  },
  {
    option: 'mali',
    label: 'Mali',
    isoCode: 'ml',
  },
  {
    option: 'malta',
    label: 'Malta',
    isoCode: 'mt',
  },
  {
    option: 'marshall islands',
    label: 'Marshall Islands',
    isoCode: 'mh',
  },
  {
    option: 'martinique (france)',
    label: 'Martinique (France)',
    isoCode: 'mq',
  },
  {
    option: 'mauritania',
    label: 'Mauritania',
    isoCode: 'mr',
  },
  {
    option: 'mauritius',
    label: 'Mauritius',
    isoCode: 'mu',
  },
  {
    option: 'mayotte (france)',
    label: 'Mayotte (France)',
    isoCode: 'yt',
  },
  {
    option: 'mexico',
    label: 'Mexico',
    isoCode: 'mx',
  },
  {
    option: 'micronesia',
    label: 'Micronesia',
    isoCode: 'fm',
  },
  {
    option: 'moldova',
    label: 'Moldova',
    isoCode: 'md',
  },
  {
    option: 'monaco',
    label: 'Monaco',
    isoCode: 'mc',
  },
  {
    option: 'mongolia',
    label: 'Mongolia',
    isoCode: 'mn',
  },
  {
    option: 'montenegro',
    label: 'Montenegro',
    isoCode: 'me',
  },
  {
    option: 'montserrat (uk)',
    label: 'Montserrat (UK)',
    isoCode: 'ms',
  },
  {
    option: 'morocco',
    label: 'Morocco',
    isoCode: 'ma',
  },
  {
    option: 'mozambique',
    label: 'Mozambique',
    isoCode: 'mz',
  },
  {
    option: 'myanmar',
    label: 'Myanmar',
    isoCode: 'mm',
  },
  {
    option: 'namibia',
    label: 'Namibia',
    isoCode: 'na',
  },
  {
    option: 'nauru',
    label: 'Nauru',
    isoCode: 'nr',
  },
  {
    option: 'nepal',
    label: 'Nepal',
    isoCode: 'np',
  },
  {
    option: 'netherlands',
    label: 'Netherlands',
    isoCode: 'nl',
  },
  {
    option: 'new caledonia (france)',
    label: 'New Caledonia (France)',
    isoCode: 'nc',
  },
  {
    option: 'new zealand',
    label: 'New Zealand',
    isoCode: 'nz',
  },
  {
    option: 'nicaragua',
    label: 'Nicaragua',
    isoCode: 'ni',
  },
  {
    option: 'niger',
    label: 'Niger',
    isoCode: 'ne',
  },
  {
    option: 'nigeria',
    label: 'Nigeria',
    isoCode: 'ng',
  },
  {
    option: 'niue (new zealand)',
    label: 'Niue (New Zealand)',
    isoCode: 'nu',
  },
  {
    option: 'norfolk island (australia)',
    label: 'Norfolk Island (Australia)',
    isoCode: 'nf',
  },
  {
    option: 'north korea',
    label: 'North Korea',
    isoCode: 'kp',
  },
  {
    option: 'northern mariana islands (usa)',
    label: 'Northern Mariana Islands (USA)',
    isoCode: 'mp',
  },
  {
    option: 'norway',
    label: 'Norway',
    isoCode: 'no',
  },
  {
    option: 'oman',
    label: 'Oman',
    isoCode: 'om',
  },
  {
    option: 'pakistan',
    label: 'Pakistan',
    isoCode: 'pk',
  },
  {
    option: 'palau',
    label: 'Palau',
    isoCode: 'pw',
  },
  {
    option: 'palestine',
    label: 'Palestine',
    isoCode: 'ps',
  },
  {
    option: 'panama',
    label: 'Panama',
    isoCode: 'pa',
  },
  {
    option: 'papua new guinea',
    label: 'Papua New Guinea',
    isoCode: 'pg',
  },
  {
    option: 'paraguay',
    label: 'Paraguay',
    isoCode: 'py',
  },
  {
    option: 'peru',
    label: 'Peru',
    isoCode: 'pe',
  },
  {
    option: 'philippines',
    label: 'Philippines',
    isoCode: 'ph',
  },
  {
    option: 'pitcairn islands (uk)',
    label: 'Pitcairn Islands (UK)',
    isoCode: 'pn',
  },
  {
    option: 'poland',
    label: 'Poland',
    isoCode: 'pl',
  },
  {
    option: 'portugal',
    label: 'Portugal',
    isoCode: 'pt',
  },
  {
    option: 'puerto rico (usa)',
    label: 'Puerto Rico (USA)',
    isoCode: 'pr',
  },
  {
    option: 'qatar',
    label: 'Qatar',
    isoCode: 'qa',
  },
  {
    option: 'reunion (france)',
    label: 'Reunion (France)',
    isoCode: 're',
  },
  {
    option: 'romania',
    label: 'Romania',
    isoCode: 'ro',
  },
  {
    option: 'russia',
    label: 'Russia',
    isoCode: 'ru',
  },
  {
    option: 'rwanda',
    label: 'Rwanda',
    isoCode: 'rw',
  },
  {
    option: 'saint barthélemy (france)',
    label: 'Saint Barthélemy (France)',
    isoCode: 'bl',
  },
  {
    option: 'saint helena (uk)',
    label: 'Saint Helena (UK)',
    isoCode: 'sh',
  },
  {
    option: 'saint kitts and nevis',
    label: 'Saint Kitts and Nevis',
    isoCode: 'kn',
  },
  {
    option: 'saint lucia',
    label: 'Saint Lucia',
    isoCode: 'lc',
  },
  {
    option: 'saint martin (france)',
    label: 'Saint Martin (France)',
    isoCode: 'mf',
  },
  {
    option: 'saint pierre and miquelon (france)',
    label: 'Saint Pierre and Miquelon (France)',
    isoCode: 'pm',
  },
  {
    option: 'saint vincent and the grenadines',
    label: 'Saint Vincent and the Grenadines',
    isoCode: 'vc',
  },
  {
    option: 'samoa',
    label: 'Samoa',
    isoCode: 'ws',
  },
  {
    option: 'san marino',
    label: 'San Marino',
    isoCode: 'sm',
  },
  {
    option: 'sao tome and principe',
    label: 'Sao Tome and Principe',
    isoCode: 'st',
  },
  {
    option: 'saudi arabia',
    label: 'Saudi Arabia',
    isoCode: 'sa',
  },
  {
    option: 'senegal',
    label: 'Senegal',
    isoCode: 'sn',
  },
  {
    option: 'serbia',
    label: 'Serbia',
    isoCode: 'rs',
  },
  {
    option: 'seychelles',
    label: 'Seychelles',
    isoCode: 'sc',
  },
  {
    option: 'sierra leone',
    label: 'Sierra Leone',
    isoCode: 'sl',
  },
  {
    option: 'singapore',
    label: 'Singapore',
    isoCode: 'sg',
  },
  {
    option: 'sint maarten (netherlands)',
    label: 'Sint Maarten (Netherlands)',
    isoCode: 'sx',
  },
  {
    option: 'slovakia',
    label: 'Slovakia',
    isoCode: 'sk',
  },
  {
    option: 'slovenia',
    label: 'Slovenia',
    isoCode: 'si',
  },
  {
    option: 'solomon islands',
    label: 'Solomon Islands',
    isoCode: 'sb',
  },
  {
    option: 'somalia',
    label: 'Somalia',
    isoCode: 'so',
  },
  {
    option: 'south africa',
    label: 'South Africa',
    isoCode: 'za',
  },
  {
    option: 'south georgia and the south sandwich islands',
    label: 'South Georgia and the South Sandwich Islands',
    isoCode: 'gs',
  },
  {
    option: 'south korea',
    label: 'South Korea',
    isoCode: 'kr',
  },
  {
    option: 'south sudan',
    label: 'South Sudan',
    isoCode: 'ss',
  },
  {
    option: 'spain',
    label: 'Spain',
    isoCode: 'es',
  },
  {
    option: 'sri lanka',
    label: 'Sri Lanka',
    isoCode: 'lk',
  },
  {
    option: 'sudan',
    label: 'Sudan',
    isoCode: 'sd',
  },
  {
    option: 'suriname',
    label: 'Suriname',
    isoCode: 'sr',
  },
  {
    option: 'svalbard and jan mayen (norway)',
    label: 'Svalbard and Jan Mayen (Norway)',
    isoCode: 'sj',
  },
  {
    option: 'swaziland',
    label: 'Swaziland',
    isoCode: 'sz',
  },
  {
    option: 'sweden',
    label: 'Sweden',
    isoCode: 'se',
  },
  {
    option: 'switzerland',
    label: 'Switzerland',
    isoCode: 'ch',
  },
  {
    option: 'syria',
    label: 'Syria',
    isoCode: 'sy',
  },
  {
    option: 'taiwan',
    label: 'Taiwan',
    isoCode: 'tw',
  },
  {
    option: 'tajikistan',
    label: 'Tajikistan',
    isoCode: 'tj',
  },
  {
    option: 'tanzania',
    label: 'Tanzania',
    isoCode: 'tz',
  },
  {
    option: 'thailand',
    label: 'Thailand',
    isoCode: 'th',
  },
  {
    option: 'timor-leste',
    label: 'Timor-Leste',
    isoCode: 'tl',
  },
  {
    option: 'togo',
    label: 'Togo',
    isoCode: 'tg',
  },
  {
    option: 'tokelau (new zealand)',
    label: 'Tokelau (New Zealand)',
    isoCode: 'tk',
  },
  {
    option: 'tonga',
    label: 'Tonga',
    isoCode: 'to',
  },
  {
    option: 'trinidad and tobago',
    label: 'Trinidad and Tobago',
    isoCode: 'tt',
  },
  {
    option: 'tunisia',
    label: 'Tunisia',
    isoCode: 'tn',
  },
  {
    option: 'turkiye',
    label: 'Turkiye',
    isoCode: 'tr',
  },
  {
    option: 'turkmenistan',
    label: 'Turkmenistan',
    isoCode: 'tm',
  },
  {
    option: 'turks and caicos islands (uk)',
    label: 'Turks and Caicos Islands (UK)',
    isoCode: 'tc',
  },
  {
    option: 'tuvalu',
    label: 'Tuvalu',
    isoCode: 'tv',
  },
  {
    option: 'uganda',
    label: 'Uganda',
    isoCode: 'ug',
  },
  {
    option: 'ukraine',
    label: 'Ukraine',
    isoCode: 'ua',
  },
  {
    option: 'united arab emirates',
    label: 'United Arab Emirates',
    isoCode: 'ae',
  },
  {
    option: 'united kingdom',
    label: 'United Kingdom',
    isoCode: 'gb',
  },
  {
    option: 'united states',
    label: 'United States',
    isoCode: 'us',
  },
  {
    option: 'united states minor outlying islands',
    label: 'United States Minor Outlying Islands',
    isoCode: 'um',
  },
  {
    option: 'uruguay',
    label: 'Uruguay',
    isoCode: 'uy',
  },
  {
    option: 'us virgin islands (usa)',
    label: 'US Virgin Islands (USA)',
    isoCode: 'vi',
  },
  {
    option: 'uzbekistan',
    label: 'Uzbekistan',
    isoCode: 'uz',
  },
  {
    option: 'vanuatu',
    label: 'Vanuatu',
    isoCode: 'vu',
  },
  {
    option: 'vatican city (holy see)',
    label: 'Vatican City (Holy See)',
    isoCode: 'va',
  },
  {
    option: 'venezuela',
    label: 'Venezuela',
    isoCode: 've',
  },
  {
    option: 'vietnam',
    label: 'Vietnam',
    isoCode: 'vn',
  },
  {
    option: 'wallis and futuna islands (france)',
    label: 'Wallis and Futuna Islands (France)',
    isoCode: 'wf',
  },
  {
    option: 'yemen',
    label: 'Yemen',
    isoCode: 'ye',
  },
  {
    option: 'zambia',
    label: 'Zambia',
    isoCode: 'zm',
  },
  {
    option: 'zimbabwe',
    label: 'Zimbabwe',
    isoCode: 'zw',
  },
];
