import { DescriptionContainer, LeftSection, LogoRow, TestimonialContainer } from '../../Landing.styled';
import { GPLogoWhite, WhiteCheck } from 'components/Atomic/Icon';
import { FunctionComponent } from 'react';
import { GPTestimonial } from 'components/GPTestimonial';
import { LandingData } from 'pages/NewLanding/Landing.type';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import Typography from '../../../../components/Atomic/Typography/Typography';
type LeftSectionComponentProps = {
  copy: LandingData;
};

const LeftSectionComponent: FunctionComponent<LeftSectionComponentProps> = ({ copy }) => {
  return (
    <LeftSection>
      <DescriptionContainer>
        <LogoRow>
          <GPLogoWhite />
        </LogoRow>
        <Typography variant="p-small" className="text-left">
          GLOBAL EOR
        </Typography>
        <Typography variant="h1" data-testid={`${ElementIdentifiersRecord.CONFIGURE_PAGE}-subtitle`}>
          {copy.subtitle}
        </Typography>
        <ul>
          <li data-testid={`${ElementIdentifiersRecord.CONFIGURE_PAGE}-offering-title`} className="pb-5">
            {copy.description.split(':')[0]} :
          </li>
          {copy.description
            .split('- ')
            .slice(1)
            .map((item, index) => {
              return (
                <li
                  key={item}
                  className="opacity-80"
                  data-testid={`${ElementIdentifiersRecord.CONFIGURE_PAGE}-service-item-${index}`}
                >
                  <WhiteCheck className="mr-2 min-w-[12px]" />
                  {item}
                </li>
              );
            })}
        </ul>
        <TestimonialContainer data-testid={`${ElementIdentifiersRecord.CONFIGURE_PAGE}-testimonials`}>
          <GPTestimonial articles={copy.articles} />
        </TestimonialContainer>
      </DescriptionContainer>
    </LeftSection>
  );
};

export default LeftSectionComponent;
