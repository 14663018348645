import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

export const SidePanelInfoContentModel = {
  primarygoals: 'primarygoals',
  newmarket: 'newmarket',
  transition: 'transition',
  contractor: 'contractor',
  switch: 'switch',
  relo: 'relo',
  project: 'project',
  manda: 'manda',
  winddown: 'winddown',
  other: 'other',
  quote: 'quote',
  'G-P Meridian Core': 'G-P Meridian Core',
  'One Time Setup': 'One Time Setup',
  'Off Boarding': 'Off Boarding',
  'Background Checks': 'Background Checks',
  [`${ElementIdentifiersRecord.CONFIGURE_PAGE_CONTACT_FORM}-infoPanel-trigger`]: `${ElementIdentifiersRecord.CONFIGURE_PAGE_CONTACT_FORM}-infoPanel-trigger`,
  [`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING_FORM}-infoPanel-trigger`]: `${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING_FORM}-infoPanel-trigger`,
  [`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_GET_A_CALLBACK_FORM}-infoPanel-trigger`]: `${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_GET_A_CALLBACK_FORM}-infoPanel-trigger`,
  [`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME_INFO_PANEL_TRIGGER_BUTTON}description-infoPanel-trigger-Button`]: `${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME_INFO_PANEL_TRIGGER_BUTTON}description-infoPanel-trigger-Button`,
} as const;

export type SidePanelInfoPageIndex = keyof typeof SidePanelInfoContentModel;

type SidePanelInfoContentModelType = Record<
  SidePanelInfoPageIndex,
  {
    title: string;
    description: string;
  }
>;
export const sidePanelInfoContent: SidePanelInfoContentModelType = {
  primarygoals: {
    title: 'Why we ask about your primary business goals.',
    description:
      "Based on your business goals and objectives, we'll match the solutions and services we think you'll need.\n\n As an industry leader, we'll build off the data and experience we've gained from past projects like yours to recommend a setup for success.",
  },
  newmarket: {
    title: 'Entering new markets',
    description:
      "Test a new market: Before committing significant resources, companies like yours can use an Employer of Record (EOR) to test the market.\n\nIf you're embracing remote work and want to hire talent from anywhere in the world, partnering with G-P can simplify the process, manage compliance, and reduce associated risks.",
  },
  transition: {
    title: 'Transition from temporary to permanent roles',
    description: 'Choose this scenario if you are looking to transition temporary team members to permanent roles.',
  },
  contractor: {
    title: 'Engage and Manage Contractors',
    description:
      'Hiring contractors is fairly straightforward, and we can help with receiving and paying contractor invoices quickly.',
  },
  switch: {
    title: 'Switching Providers',
    description:
      "If you're switching Employer of Record (EOR) providers, it's essential to conduct a thorough assessment to check whether the new provider aligns with your business needs, offers superior services, and can assist with a smooth transition for your global workforce.",
  },
  relo: {
    title: 'Relocating Team Member',
    description:
      'Our solutions streamline the process of hiring, onboarding, managing, and paying team members. It offers a centralized platform to handle various aspects of the relationship for efficiency, compliance, and reduced risks.',
  },
  project: {
    title: 'Limited Need',
    description:
      "When you have a limited need, are in a country with challenging employment laws, or don't have an established entity, our Employer of Record (EOR) solution can be invaluable. We enable you to quickly hire and manage new team members using indefinite or fixed term employment contracts (where possible).",
  },
  manda: {
    title: 'Mergers & Acquisitions',
    description:
      'Navigating Mergers and Acquisitions (M&A) can be a complex process, especially when integrating team 	members from different jurisdictions. We can help can simplify the transitional phase, maintain culture and teams, and seamlessly manage and retain your workforce',
  },
  winddown: {
    title: 'Entity Wind Downs',
    description:
      'Winding down a subsidiary, especially in foreign jurisdictions, presents unique challenges relating to 	compliance as well as managing your team members. We can recommend and provide solutions that suit your unique needs during this 	transition.',
  },
  other: {
    title: 'Other Situations',
    description:
      'While there are many common scenarios where businesses utilize our services, there are also unique, 	specific situations where we can provide invaluable support. Whatever your particular need, we offer flexibility 	and expertise to help you navigate complex employment landscapes.',
  },
  quote: {
    title: 'Clarifying Pricing Information',
    description:
      "Because the total cost of employment varies considerably based on an individual's salary, bonus, benefits, and legal costs related to employment compliance in each country, we provide pricing that reflects our monthly services fee only.\n\nThe amount we ultimately pay each individual, will include the compensation package you agree to with each 	individual, plus any costs related to employment compliance in their country (taxes, required benefits, and so on)",
  },
  [`${ElementIdentifiersRecord.CONFIGURE_PAGE_CONTACT_FORM}-infoPanel-trigger`]: {
    title: "We don't share your contact information",
    description:
      "At G-P we respect your privacy. We won't share your contact information with third parties without you explicity consent.\n\nWe'll use your contact information to reach out to you should the need arise and to send a copy of your quote to the email address you provide.",
  },
  [`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING_FORM}-infoPanel-trigger`]: {
    title: "We don't share your contact information",
    description:
      "At G-P we respect your privacy. We won't share your contact information with third parties without you explicity consent.\n\nWe'll use your contact information to reach out to you should the need arise and to send a copy of your quote to the email address you provide.",
  },
  [`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_GET_A_CALLBACK_FORM}-infoPanel-trigger`]: {
    title: "We don't share your contact information",
    description:
      "At G-P we respect your privacy. We won't share your contact information with third parties without you explicity consent.\n\nWe'll use your contact information to reach out to you should the need arise and to send a copy of your quote to the email address you provide.",
  },
  [`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SELECT_DATA_AND_TIME_INFO_PANEL_TRIGGER_BUTTON}description-infoPanel-trigger-Button`]:
    {
      title: 'Meeting with or Global Experts',
      description:
        'Our global experts are available for an in person meeting to discuss your goals and needs.\n\nAll meetings are video enabled to allow for screen sharing - this makes demos, walk throughs and presenting materials mush easier.\n\nHaving a video meeting does not require you to turn on your camera however.',
    },
  [SidePanelInfoContentModel['G-P Meridian Core']]: {
    title: 'G-P Meridian Core™',
    description:
      "With G-P Meridian Core, you'll find all the essential tools you need to, hire, and manage your global workforce, all with unmatched global and local compliance, and unbeatable speed to any market you choose.",
  },
  [SidePanelInfoContentModel['One Time Setup']]: {
    title: 'On Time Setup',
    description:
      "With our one-time setup, you'll get a G-P account with guidance on key entitlements and access to our knowledge base.",
  },
  [SidePanelInfoContentModel['Off Boarding']]: {
    title: 'Employee Off Boarding',
    description:
      'Every time a team member is off boarded or transitioned off the G-P platform, we ensure all legal, compliance and operational requirements are met.',
  },
  [SidePanelInfoContentModel['Background Checks']]: {
    title: 'Background Checks',
    description:
      "Get visibility on locally permissible background checks. Verify the accuracy of an applicant's assertions and ensure they are who they say they are. Optimize your recruitment and selection process with a single solution by requesting background checks without leaving the G-P Meridian platform.",
  },
};
