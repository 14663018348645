import { CompanyNameBlockList, CompetitorDomains, FreeDomains } from './contactForm.utils';
import {
  isValidPhoneNumber,
  getCountries,
  getCountryCallingCode,
  CountryCode,
  parsePhoneNumber,
} from 'libphonenumber-js/max';
import { statesArray } from 'utils';
import { FormConfig } from './contactForm.types';
import { formatNumber } from 'libphonenumber-js';

export const getCountriesWithCallingCodes = () => {
  const countryCodes = getCountries();
  const region = new Intl.DisplayNames(['en'], { type: 'region' });
  const countriesWithCallingCodes = countryCodes.map((countryCode) => ({
    label: `${region.of(countryCode.toUpperCase())} - +${getCountryCallingCode(countryCode)}`,
    option: countryCode,
  }));
  return countriesWithCallingCodes.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
};

export const parsePhone = (countryCode: string, phoneNumber: string) => {
  const parsedValue = parsePhoneNumber(phoneNumber, countryCode as CountryCode);
  return parsedValue?.formatInternational();
};

export const getStatesList = () => {
  const states = statesArray().map((state: string) => ({
    option: state,
    label: state,
  }));
  return states;
};

export const IsValidDomain = (domain: string): boolean => {
  const subdomains: string[] = domain.split('.');
  if (subdomains.length > 1) {
    const validPattern = /^[A-Za-z]+$/i;
    return validPattern.test(subdomains.pop() as string);
  }
  return true;
};

export const config: FormConfig = {
  firstName: {
    name: 'firstName',
    rules: {
      required: true,
      pattern: {
        value: /^[A-Za-z]+$/i,
        message: 'Please enter valid First Name.',
      },
    },
  },
  lastName: {
    name: 'lastName',
    rules: {
      required: true,
      pattern: {
        value: /^[A-Za-z]+$/i,
        message: 'Please enter valid Last Name.',
      },
    },
  },
  company: {
    name: 'companyName',
    rules: {
      required: true,
      validate: (value) => {
        if (CompanyNameBlockList.includes(value.toLowerCase())) return 'Please enter valid business email.';
      },
    },
  },
  companyEmail: {
    name: 'companyEmail',
    rules: {
      required: true,
      validate: (value) => {
        const pattern = /@([\w-]+\.)+([\w-]+)/;
        const match = value.match(pattern);
        const domain = match && match[0].replace('@', '');
        if (!match || !IsValidDomain(domain)) {
          return 'Please enter valid email.';
        }

        if (FreeDomains.includes(domain) || CompetitorDomains.includes(domain))
          return 'Please enter a valid Company Email.';
      },
    },
  },
  phone: {
    name: 'phoneNumber',
    rules: {
      required: true,
      validate: (value, formData) => {
        const pattern = /^(?:(\d)\1{9})$/;
        if (pattern.test(value) || !isValidPhoneNumber(value, formData.country))
          return 'Please enter valid Phone Number.';
      },
    },
    formatter: (value, formData) => {
      if (!value) return value;
      let formattedNum = '';
      if ('country' in formData) {
        formattedNum = formatNumber(value, formData.country as CountryCode, 'NATIONAL');
      }
      return formattedNum.length > 1 ? formattedNum : value;
    },
  },
  state: {
    name: 'state',
    rules: {
      required: true,
    },
  },
  country: {
    name: 'country',
    rules: {
      required: false,
    },
  },
  role: {
    name: 'role',
    rules: {
      required: 'Please select your Role.',
    },
  },
  terms: {
    name: 'termsAccepted',
    rules: {
      required: 'Please agree terms and conditions.',
    },
  },
};

export const Roles = [
  { option: 'Administrative', label: 'Administrative' },
  { option: 'Compliance', label: 'Compliance' },
  { option: 'Finance', label: 'Finance' },
  { option: 'HR', label: 'HR' },
  { option: 'Marketing', label: 'Marketing' },
  { option: 'Legal', label: 'Legal' },
  { option: 'IT/Technology', label: 'IT/Technology' },
  { option: 'Management', label: 'Management' },
  { option: 'Operations', label: 'Operations' },
  { option: 'Other', label: 'Other' },
];
