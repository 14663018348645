import { Onboarding } from 'pages/NewConfigure/types/Configure.types';
import { FunctionComponent } from 'react';
import { twMerge } from 'tailwind-merge';
import JumbotronSkeleton from './JumbotronSkeleton';
import { JumbotronTitle, StyledListItem } from './Jumbotron.styled';

export type JumbotronProps = {
  jumbotronImage: string;
  isDataReady: boolean;
  introData: Onboarding;
  listIcon: string;
  heroImage?: string;
  contentRow?: string;
  container?: string;
  instanceId?: string;
};

export const Jumbotron: FunctionComponent<JumbotronProps> = ({
  jumbotronImage,
  isDataReady,
  introData,
  listIcon,
  heroImage,
  contentRow,
  container,
  instanceId,
}) => {
  return (
    <div className={twMerge('flex flex-col items-center justify-center flex-auto p-12 bg-primary-500', container)}>
      <div className={twMerge('flex flex-row items-center self-stretch justify-center gap-8 mx-8 my-0', contentRow)}>
        <div
          className={twMerge(
            'flex overflow-visible items-center min-w-0 max-w-[250px] h-[427px] flex-1 tabletDownwards:hidden justify-end',
            heroImage,
          )}
        >
          <img
            data-testid={`${instanceId}-jumbotron-image`}
            className="max-h-none max-w-none h-[100%]"
            src={jumbotronImage}
            alt="Jumbotron background showing eligible talents, with their details like location, salary, occupation, and working mode"
          />
        </div>
        <div className="flex flex-col gap-8 h-auto max-w-[507px] min-w-[300px] grow shrink-0 basis-0">
          {!isDataReady ? (
            <JumbotronSkeleton />
          ) : (
            <>
              <h4
                data-testid={`${instanceId}-jumbotron-subTitle`}
                className="font-sans text-[12px] font-[400] leading-[120%] tracking-[0.84px] uppercase m-0 opacity-75"
              >
                {introData.subtitle}
              </h4>

              <JumbotronTitle
                data-testid={`${instanceId}-jumbotron-title`}
                className="font-sans font-[600} leading-[110%] m-0 p-0"
              >
                {introData?.title}
              </JumbotronTitle>
              <div className="flex flex-col gap-2">
                <p
                  data-testid={`${instanceId}-jumbotron-description`}
                  className="font-basier text-[18px] font-[400] leading-[150%] tracking-[-0.18px] m-0 p-0"
                >
                  {introData.description}
                </p>
                {introData.descriptionItems && (
                  <ul
                    data-testid={`${instanceId}-jumbotron-description-collection`}
                    className="flex flex-col gap-2 font-basier text-[18px] font-[400] leading-[150%] tracking-[-0.18px] m-0 p-0 opacity-80"
                  >
                    {introData.descriptionItems
                      .split('- ')
                      .slice(1)
                      .map((item, index) => {
                        return (
                          <StyledListItem
                            data-testid={`${instanceId}-jumbotron-description-item-${index}`}
                            $icon={listIcon}
                            key={`descriptionItem-${index}`}
                            className={`font-basier text-[18px] font-[400] leading-[1.2rem] tracking-[-0.18px] p-0 list-outside`}
                          >
                            {item}
                          </StyledListItem>
                        );
                      })}
                  </ul>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
