import moment from 'moment';
import { DateWithTimeSlots, TimeSlot } from 'pages/NewTalk/Talk.types';

/**
 * Checks if a given date is a weekend (Saturday or Sunday).x
 * @param date The date to check.
 * @returns True if the date falls on a weekend, otherwise false.
 */
export const isWeekend = (date: Date): boolean => {
  const day = date.getDay();
  return day === 0 || day === 6;
};

export type GroupedDates = { [key: string]: DateWithTimeSlots[] };

/**
 * Groups an array of dates into weeks of the year, excluding weekends.
 * @param dates An array of dates with associated time slots.
 * @returns An object where each key represents a week of the year (from Monday to Friday)
 *          and its value is an array of dates with time slots falling within that week.
 */
export const groupDatesIntoWeeks = (dates: DateWithTimeSlots[]): GroupedDates => {
  const groupedDates: { [key: string]: DateWithTimeSlots[] } = {};

  for (const dateObject of dates) {
    const date = new Date(dateObject.date);
    const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    // If it's a weekend, skip
    if (isWeekend(date)) continue;

    // Find the first day (Monday) of the week
    const firstDayOfWeek = new Date(date);
    firstDayOfWeek.setDate(date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust for Sunday

    // Find the last day (Friday) of the week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 4);

    // Construct group key in format "YYYY-MM-DD to YYYY-MM-DD"
    const groupKey = `${firstDayOfWeek.toISOString().slice(0, 10)} to ${lastDayOfWeek.toISOString().slice(0, 10)}`;

    // Add date to corresponding group
    if (!groupedDates[groupKey]) {
      groupedDates[groupKey] = [];
    }
    groupedDates[groupKey].push(dateObject);
  }

  return groupedDates;
};

/**
 * Check if the given date range falls within the current week, next week, or return the date range in "MMM D" format.
 * @param dateString - The date range string in the format "YYYY-MM-DD to YYYY-MM-DD".
 * @returns A string indicating if the date range is "This week", "Next week", or the date range in "MMM D." format.
 */
export function checkWeekRange(dateString: string): string {
  const dateRange = dateString.split(' to ');

  // Check if the date range is valid
  if (dateRange.length !== 2) {
    throw new Error('Invalid date range format. Please provide dates in the format "YYYY-MM-DD to YYYY-MM-DD".');
  }

  const startDate = moment(dateRange[0], 'YYYY-MM-DD');
  const endDate = moment(dateRange[1], 'YYYY-MM-DD');

  // Validate the date formats
  if (!startDate.isValid() || !endDate.isValid()) {
    throw new Error('Invalid date format. Please provide dates in the format "YYYY-MM-DD".');
  }

  // Get current week and next week boundaries
  const today = moment();
  const nextWeek = moment(today).add(1, 'week');
  const startOfWeek = moment(today).startOf('week');
  const endOfWeek = moment(today).endOf('week');
  const startOfNextWeek = moment(nextWeek).startOf('week');
  const endOfNextWeek = moment(nextWeek).endOf('week');

  // Check if the date range falls within this week, next week, or return the date range in "MMM D." format
  if (startDate.isSameOrAfter(startOfWeek) && endDate.isSameOrBefore(endOfWeek)) {
    return 'This week';
  } else if (startDate.isSameOrAfter(startOfNextWeek) && endDate.isSameOrBefore(endOfNextWeek)) {
    return 'Next week';
  } else {
    return `${startDate.format('MMM D.')} - ${endDate.format('MMM D.')}`;
  }
}

/**
 * Checks if a given time slot is enabled.
 * @param timeSlot The time slot to check.
 * @returns True if the time slot is enabled, otherwise false.
 */
export const isTimeSlotEnabled = <T extends TimeSlot>(timeSlotArray1: T, availableSlots: Array<T>) =>
  availableSlots.some(
    (timeSlotArray2: T) => timeSlotArray1.from === timeSlotArray2.from && timeSlotArray1.to === timeSlotArray2.to,
  );
