import { feedbackOptions } from 'components/Shared/Feedback/FeedbackConfig';
import { useNavigate } from 'react-router-dom';

export const useFeedbackNavigation = (type: string) => {
  const navigate = useNavigate();

  const redirectToTalkPage = () => navigate('/talk');
  const feedbackOption = feedbackOptions.find((item) => item.name === type);

  return {
    redirectToTalkPage,
    feedbackOption,
  };
};
