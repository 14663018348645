import CountrySelection from 'components/Shared/PageTemplate/CountrySelection';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 25;
const buttonName = 'SubsidiaryLocation';
export const SubsidiaryLocation = () => {
  return (
    <CountrySelection
      pageNumber={pageNumber}
      buttonName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_SUBSIDIARY_LOCATIONS}
    />
  );
};
