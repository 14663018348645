import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from 'utils/tailwindConfig';

export const Container = styled(motion.article)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${colors.grey[400]};
  cursor: pointer;
  overflow-wrap: anywhere;
  min-width: 146px;

  .description__container {
    flex: 1 1 0%;
    display: flex;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    color: ${colors.grey[1000]};
    @media screen and (min-width: 480px) {
      padding-top: 18px;
    }
    p {
      font-family: ${fontFamily.basier};
      font-size: 18px;
      font-style: normal;
      font-weight: ${fontWeight.normal};
      line-height: 150%;
      letter-spacing: -0.18px;
      padding: 0px;
      margin: 0px;
      -webkit-user-drag: none;
      user-select: none;
      text-wrap: pretty;
      @media screen and (max-width: 340px) {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    flex-direction: row;
  }

  @media screen and (max-width: 340px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 480px) {
    flex-direction: column;
    align-items: center;
    max-width: 230px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ProspectChoiceImage = styled.img`
  width: 150px;
  height: 125px;

  @media screen and (max-width: 479px) {
    min-width: 76px;
    width: 76px;
    height: 75px;
  }
`;
