import { FunctionComponent } from 'react';
import { Container, ProspectChoiceImage } from './ProspectCard.styled';
import { useMediaQuery } from 'usehooks-ts';
import { ArrowRightBlue } from 'components/Atomic/Icon';
import { useNavigate } from 'react-router-dom';
import { colors } from 'utils/tailwindConfig';
import { hexToRgba } from 'utils/helpers';
import { HeaderProps } from 'components/Header/Header';

const switchImageCardImageAtThisValue = '(min-width: 480px)';
const hoverScale = 1.04;
const pressScale = 0.98;
const spring = { type: 'spring', stiffness: 500, damping: 24, mass: 1 };
const whileHoverAttributes = {
  scale: hoverScale,
  boxShadow: `0px 0px 10px 1px ${hexToRgba(colors.black, 0.05)}`,
};
const whileTapConfig = {
  scale: pressScale,
};

export type ProspectCardProps = {
  mobileImage: string;
  desktopImage: string;
  description: {
    name: string;
    description: string;
  }[];
  id: string;
  index: number;
  route: string;
  setHeaderTheme: (theme: HeaderProps['theme']) => void;
};

type ThemeByRouteType = {
  [key: string]: HeaderProps['theme'];
};
const ThemeByRoute: ThemeByRouteType = {
  '/configure': 'blue',
  '/talk': 'orange',
};
const ProspectCard: FunctionComponent<ProspectCardProps> = ({
  id,
  mobileImage,
  desktopImage,
  description,
  index,
  route,
  setHeaderTheme,
}) => {
  const isDesktop = useMediaQuery(switchImageCardImageAtThisValue);
  const navigate = useNavigate();

  const onClickEventHandler = () => {
    setHeaderTheme(ThemeByRoute[route]);
    navigate(route);
  };
  return (
    <Container
      id={id}
      data-testid={id}
      transition={spring}
      whileHover={whileHoverAttributes}
      whileTap={whileTapConfig}
      onClick={onClickEventHandler}
    >
      <ProspectChoiceImage data-testid={`${id}-image`} src={isDesktop ? desktopImage : mobileImage} />
      <div className="description__container">
        <p data-testid={`${id}-description`}>
          {description[index].description}
          <span className="inline-block ml-1" data-testid={`${id}-icon-arrow-right`}>
            <ArrowRightBlue />
          </span>
        </p>
      </div>
    </Container>
  );
};

export default ProspectCard;
