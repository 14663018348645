import { FunctionComponent, useEffect } from 'react';
import jumbotronImage from 'assets/images/configure/jumbotron-configure-page-image.png';
import CheckIcon from 'assets/images/configure/check-tick.svg';
import { CaretDownBlue } from 'components/Atomic/Icon';
import { Jumbotron } from 'components/Shared/Jumbotron/Jumbotron';
import { isObjectEmpty } from 'utils/helpers';
import { useRootStore } from 'contexts/StoreContext';
import { observer } from 'mobx-react';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { ConfigureStepRecord } from 'pages/NewConfigure/Configure.util';
import { ButtonScaleEffectProps } from 'utils/tailwindConfig/elementProps';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';
import { StyledButton } from './ConfigureIntro.styled';

const ConfigureIntro: FunctionComponent = observer(() => {
  const {
    configurePageStore: { getOnboarding, introPageData: intro, isLoading, error, setCurrentPage },
    prospectLayoutStore: { setDirection, getProspectId },
  } = useRootStore();

  useEffect(() => {
    getOnboarding();
    getProspectId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDataReady = !isObjectEmpty(intro) && !isLoading && !error;

  return (
    <section className="flex flex-col self-stretch flex-auto w-full text-white">
      <Jumbotron
        instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_INTRO_PAGE}
        jumbotronImage={jumbotronImage}
        isDataReady={isDataReady}
        introData={intro}
        listIcon={CheckIcon}
      />
      <div className="flex justify-center px-8 py-[74px]">
        <StyledButton
          id={`${ElementIdentifiersRecord.CONFIGURE_PAGE_INTRO_PAGE}-get-started-button`}
          data-testid={`${ElementIdentifiersRecord.CONFIGURE_PAGE_INTRO_PAGE}-get-started-button`}
          {...ButtonScaleEffectProps}
          disabled={!isDataReady}
          onClick={() => {
            setCurrentPage(ConfigureStepRecord.primaryBusinessGoal);
            setDirection(Direction.forward);
          }}
        >
          <CaretDownBlue className="mt-[-4px] animate-bounce-twice-with-delay" />
          Get Started
        </StyledButton>
      </div>
    </section>
  );
});

export default ConfigureIntro;
