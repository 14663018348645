import { CountriesModal } from 'components/Shared/Overlay/Countries/CountriesModal';
import { useRootStore } from 'contexts/StoreContext';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react';

const OverlayRecord = {
  CountriesModal,
};

export const Overlay = observer(() => {
  const {
    overlayStore: { overlays },
  } = useRootStore();

  const overlayComponents = overlays.map((overlay) => {
    const OverlayComponent = OverlayRecord[overlay];
    return <OverlayComponent key={overlay} data-testid={`overlay-${overlay}`} />;
  });

  // Assigning the result of map to a variable
  const renderedOverlays = overlayComponents;

  return <AnimatePresence initial={false}>{renderedOverlays}</AnimatePresence>;
});
