import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 22;
const buttonName = 'teamMembersManagedWithCurrentEOR';
export const TeamMembersManagedWithCurrentEOR = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_TEAM_MEMBERS_MANAGED_WITH_EOR}
    />
  );
};
