import { Controller } from 'react-hook-form';
import { FunctionComponent } from 'react';
import { ExtendedEvent, FieldControllerPropsWithOptions } from '../contactForm.types';
import Typography from 'components/Atomic/Typography/Typography';
import { ErrorIcon } from 'components/Atomic/Icon';
import styled from 'styled-components';

export const DropDown: FunctionComponent<FieldControllerPropsWithOptions> = ({
  control,
  config,
  options,
  value,
  onFieldChange,
  id,
}) => {
  return (
    <Controller
      name={config.name}
      rules={config.rules}
      control={control}
      defaultValue={value}
      render={({ field: { ...field }, fieldState }) => {
        const hasError = !!(fieldState && fieldState.isTouched && fieldState.error);
        const errorMsg = fieldState?.error?.message;
        return (
          <div
            className={`flex border-[1px] w-full h-12 gap-1 rounded-lg bg-grey-100 ${hasError ? 'border-error-200' : ' border-grey-100'}  focus:border-primary-500 `}
          >
            <div className="flex flex-row items-center justify-start w-full pl-4 pr-2">
              <CustomSelect>
                <select
                  data-testid={id}
                  id={id}
                  {...field}
                  className="writing-text text-grey-800"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    onFieldChange?.(e as ExtendedEvent);
                  }}
                >
                  <option value="">Please select an option</option>
                  {(options as Array<Record<string, string>>).map((state) => (
                    <option key={state.option} value={state.option} selected={state.option === value}>
                      {' '}
                      {state.label}
                    </option>
                  ))}
                </select>
              </CustomSelect>
              {hasError && <ErrorIcon className="absolute text-red-500 bg-grey-100 w-lg h-lg right-sm top-[44px]" />}
              {errorMsg && (
                <Typography
                  variant="p-xsmall"
                  className="absolute z-[10] inset-x-0 rounded-[8px] h-[34px] px-[16px] py-[8px] text-error-200 bg-error-100 bottom-[-38px]"
                >
                  {errorMsg}
                </Typography>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

const CustomSelect = styled.div`
  width: 100%;
  position: relative;
  select {
    appearance: none;
    /*  safari  */
    -webkit-appearance: none;
    /*  other styles for aesthetics */
    width: 100%;
    padding-block: 0.675em;
    background-color: transparent;
    outline: transparent;
    cursor: pointer;
  }

  &::before,
  &::after {
    --size: 0.3rem;
    content: '';
    position: absolute;
    right: 0.9rem;
    pointer-events: none;
  }

  &::before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid black;
    top: 40%;
  }

  &::after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
    top: 55%;
  }
`;
