import { TimeSlot } from 'pages/NewTalk/Talk.types';
import React, { useCallback } from 'react';
import { isTimeSlotEnabled } from '../calendar.util';
import { PillWithToolTip } from 'components/Shared/PillWithToolTip/PillWithToolTip';

export type PillsRendererProps = {
  slots: TimeSlot[];
  availableSlots: TimeSlot[];
  date: string;
  selectedTime: { selectedElementId: string; selectedElementValue: string };
  setSelectedTime: React.Dispatch<React.SetStateAction<{ selectedElementId: string; selectedElementValue: string }>>;
  onPillClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelClick: () => void;
  onConfirmClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selectedTimeSlot: string;
};

export const PillsRenderer: React.FC<PillsRendererProps> = ({
  slots,
  availableSlots,
  date,
  selectedTime,
  setSelectedTime,
  onPillClick,
  onCancelClick,
  onConfirmClick,
  selectedTimeSlot,
}) => {
  const renderPills = useCallback(() => {
    return slots.map((item) => {
      const isDisabled = !isTimeSlotEnabled(item, availableSlots);
      return (
        <PillWithToolTip
          key={`${date}-option-${item.from}-${item.to}`}
          from={item.from}
          to={item.to}
          isDisabled={isDisabled}
          date={date}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          onPillClick={onPillClick}
          onCancelClick={onCancelClick}
          onConfirmClick={onConfirmClick}
          selectedTimeInStore={selectedTimeSlot}
        />
      );
    });
  }, [
    slots,
    availableSlots,
    date,
    selectedTime,
    setSelectedTime,
    onPillClick,
    onCancelClick,
    onConfirmClick,
    selectedTimeSlot,
  ]);

  return <>{renderPills()}</>;
};
