import { useState, useCallback, useEffect, MouseEvent } from 'react';
import { BranchPagesRecord, ConfigureStepRecord } from 'pages/NewConfigure/Configure.util';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { useRootStore } from 'contexts/StoreContext';
import { toJS } from 'mobx';
import { useCombinedRulesAndOptions } from 'pages/NewConfigure/hooks/useCombinedRulesAndOptions';
import { startsWithForwardSlashWord } from 'utils/helpers';
import { updateProspectAsync } from 'api/prospect';

export const InputErrorMessage = 'Error: Input value does not exist in BranchPagesRecord.';
export const dataNotAvailableErrorMessage = 'Error: primaryObjectivesData is not available.';
export const usePrimaryBusinessGoal = () => {
  const {
    prospectLayoutStore,
    prospectLayoutStore: { setDirection, prospectInformation },
    configurePageStore,
    configurePageStore: {
      questionStepsData,
      setNextSteps,
      setCompletedConfigureSteps,
      completedConfigureSteps,
      clearCompletedConfigureSteps,
      removeCompletedConfigureStep,
      clearNextSteps,
      clearBranchPage,
      branchPage,
      setBranchPage,
      goToQuestionIndex,
      setCurrentPage,
    },
  } = useRootStore();

  const selectedRules = completedConfigureSteps.get(ConfigureStepRecord.primaryBusinessGoal);
  const data = questionStepsData[ConfigureStepRecord.primaryBusinessGoal];
  const primaryObjectivesData = useCombinedRulesAndOptions(data.questions[0].rules, data.questions[0].options);

  const [inputValues, setInputValues] = useState<string>(branchPage ?? '');
  const isValueIsRoutePath = startsWithForwardSlashWord(inputValues);
  const { id, token } = prospectInformation;

  //this effect is to set the branchPage in store when a choice is made which does not have nextSteps
  //for example Other...
  useEffect(() => {
    if (isValueIsRoutePath && BranchPagesRecord.hasOwnProperty(inputValues)) {
      setBranchPage(inputValues);
    }
  }, [inputValues, isValueIsRoutePath, setBranchPage]);

  //this handles the next button click based on the type of choice selected - i.e Question Flow or Branch Page
  const onNextClicked = useCallback(() => {
    if (isValueIsRoutePath) {
      if (BranchPagesRecord.hasOwnProperty(inputValues)) {
        setBranchPage(inputValues);
        setCurrentPage(BranchPagesRecord[inputValues as keyof typeof BranchPagesRecord]);
        setDirection(Direction.forward);
      } else {
        console.error(InputErrorMessage);
      }
    } else {
      if (primaryObjectivesData) {
        goToQuestionIndex(0, Direction.forward);
      } else {
        console.error(dataNotAvailableErrorMessage);
      }
    }
  }, [
    isValueIsRoutePath,
    inputValues,
    setBranchPage,
    setCurrentPage,
    setDirection,
    primaryObjectivesData,
    goToQuestionIndex,
  ]);

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { name, value } = event.currentTarget;
      const serializedValue = value.split(',').map(Number);
      clearBranchPage();
      setInputValues(value);

      //this is what toggles the goals
      if (selectedRules === name) {
        removeCompletedConfigureStep(ConfigureStepRecord.primaryBusinessGoal);
        clearNextSteps();
        clearCompletedConfigureSteps();
      } else if (toJS(completedConfigureSteps).size > 0) {
        //set new goals and nextSteps when new choice is made
        clearCompletedConfigureSteps();
        setCompletedConfigureSteps(ConfigureStepRecord.primaryBusinessGoal, name);
        setNextSteps(serializedValue);
      } else {
        //if coming in the the primary page for the first time
        setCompletedConfigureSteps(ConfigureStepRecord.primaryBusinessGoal, name);
        setNextSteps(serializedValue);
      }
      //updating data with primary goal choice which would be sent as payload for sales-force
      data.questions[0].value = name;
      updateProspectAsync(id, data, token);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      clearBranchPage,
      selectedRules,
      completedConfigureSteps,
      removeCompletedConfigureStep,
      clearNextSteps,
      clearCompletedConfigureSteps,
      setCompletedConfigureSteps,
      setNextSteps,
    ],
  );

  return {
    selectedRules,
    onNextClicked,
    handleButtonClick,
    isValueIsRoutePath,
    ...prospectLayoutStore,
    ...configurePageStore,
    data,
    primaryObjectivesData,
  };
};
