import { FormPayload } from 'pages/NewConfigure/Survery/ContactForm/ContactForm.type';
import { QuoteScreen } from 'types/quoteScreen';
import { BASE_API_URL } from 'utils/constants';

type CreateLeadAsyncType = (
  payload: FormPayload,
  id: string,
  token: string,
  setQuoteData: (data: QuoteScreen) => void,
  fetchFunction?: typeof fetch,
) => Promise<any>;
/**
 * Creates a lead asynchronously.
 * @param payload
 * @param id
 * @param token
 * @param setQuoteData
 */
export const createLeadAsync: CreateLeadAsyncType = async (payload, id, token, setQuoteData, fetchFunction = fetch) => {
  const url = `${BASE_API_URL.ProspectApiUrl}/${id}/lead`;
  try {
    const response = await fetchFunction(url, {
      method: 'POST',
      headers: {
        'x-prospect-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Failed to create lead: ${response.statusText}`);
    }

    const responseData = await response.json();
    setQuoteData(responseData.data?.quote);
  } catch (error) {
    console.error('Error creating lead:', error);
    throw error;
  }
};
