import { useState, useEffect } from 'react';

export default function useResizeObserver(refElement: React.RefObject<HTMLElement | HTMLDivElement>) {
  // create state
  const [size, setSize] = useState({ height: null as number | null, width: null as number | null });

  useEffect(() => {
    // create elemnt with current object
    const el = refElement.current;

    // do nothing is there is no ref
    if (!el) return;

    // create obsever instance and attach functions to perform
    // in this case get dimensions of elementand update state
    const observer = new ResizeObserver(() => {
      setSize({ height: el.offsetHeight, width: el.offsetWidth });
    });

    // set observer
    observer.observe(el);

    // clean up observer all hooked elements
    return () => {
      observer.disconnect();
    };
  }, [refElement]);

  return { ...size };
}
