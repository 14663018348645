import { FunctionComponent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { QuestionSection } from '../QuestionSectionWithTags';
import SurveyBackAndNextButton from '../SurveyBackAndNextButton';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useRootStore } from 'contexts/StoreContext';
import { Direction } from 'components/Layout/ProspectLayout.types';
import { updateProspectAsync } from 'api/prospect';

type LinearSelectLayoutProps = {
  pageNumber: number;
  inputName: string;
  flexGrid?: boolean;
  css?: string;
  instanceId: string;
};

export const LinearSelectLayout: FunctionComponent<LinearSelectLayoutProps> = observer(
  ({ pageNumber, inputName, css, instanceId, flexGrid = false }) => {
    const {
      configurePageStore: {
        questionStepsData,
        completedConfigureSteps,
        setCompletedConfigureSteps,
        currentPage,
        goToQuestionIndex,
        currentQuestionIndex,
      },
      prospectLayoutStore: { prospectInformation },
    } = useRootStore();

    const primaryGoal = toJS(completedConfigureSteps.get(1));
    const identifier = `${instanceId}-PG:${primaryGoal}-S:${pageNumber}`;

    const data = questionStepsData[pageNumber];

    const [state, setState] = useState<string>(() => toJS(completedConfigureSteps.get(Number(currentPage))) ?? '');

    useEffect(() => {
      if (state !== undefined) {
        setCompletedConfigureSteps(pageNumber, state);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const dataFromStore = toJS(completedConfigureSteps.get(Number(currentPage)));
    const localStateHasChanged = dataFromStore !== state;
    useEffect(() => {
      if (dataFromStore === undefined && state.length !== 0 && localStateHasChanged) {
        // Update the backend with the completed step
        updateProspectAsync(prospectInformation.id, data, prospectInformation.token);
      }
    }, [state, data, prospectInformation, dataFromStore, completedConfigureSteps, localStateHasChanged]);

    //Click handle for the answers
    const handleClickChange = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        const { value } = event.currentTarget;
        setState((prevState) => (prevState === value ? '' : value));
        data.questions[0].value = value;
      },
      [data.questions],
    );

    return (
      <div className="survey-question-container" data-testid="linear-select-layout">
        <p className="survey-question-title" data-testid={`${identifier}-title`}>
          {data.questions[0]?.title}
        </p>
        <div className="survey-question-description" data-testid={`${identifier}-description`}>
          {data.questions[0]?.description}
        </div>
        <div className="flex flex-col justify-start items-start gap-4 my-6 p-0">
          <QuestionSection
            css={css}
            flexgrid={flexGrid}
            options={data.questions[0]?.options}
            onClickHandler={handleClickChange}
            name={inputName}
            state={state}
            selectionMode="singleString"
            instanceId={identifier}
          />

          <SurveyBackAndNextButton
            instanceId={identifier}
            disabled={state !== null && state?.length === 0}
            onNextClick={() => goToQuestionIndex(currentQuestionIndex! + 1, Direction.forward)}
            onBackClick={() => goToQuestionIndex(currentQuestionIndex! - 1, Direction.back)}
          />
        </div>
      </div>
    );
  },
);
