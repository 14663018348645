import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors, fontFamily } from 'utils/tailwindConfig';
import { hexToRgba } from 'utils/helpers';

export const iconSizes = {
  small: { size: 16, stroke: 1 },
  regular: { size: 24, stroke: 1.5 },
  medium: { size: 32, stroke: 1 },
  large: { size: 40, stroke: 1 },
  xlarge: { size: 64, stroke: 0.6 },
};

export const themeColors = {
  white: {
    color: colors.grey[1000],
    backgroundColor: colors.white,
    borderColor: colors.grey[200],
  },
  blue: {
    color: colors.white,
    backgroundColor: colors.primary[500],
    borderColor: hexToRgba(colors.white, 0.25),
  },
  orange: {
    color: colors.grey[1000],
    backgroundColor: colors.warning[200],
    borderColor: hexToRgba(colors.warning[300], 0.3),
  },
  blueLink: {
    color: colors.primary[500],
    backgroundColor: colors.black,
    borderColor: colors.black,
  },
  whiteLink: {
    color: colors['survey-button-text'],
    backgroundColor: colors.black,
    borderColor: colors.black,
  },
  skyLink: {
    color: hexToRgba(colors.white, 0.5),
    backgroundColor: hexToRgba(colors.white, 0.1),
    borderColor: hexToRgba(colors.white, 0.5),
  },
  system: {
    color: colors.grey[800],
    backgroundColor: hexToRgba(colors.white, 0),
    borderColor: hexToRgba(colors.white, 0),
  },
};

interface Props {
  color?: string;
  type?: string;
  theme?: string;
  size?: string;
}

const setPadding = (type: string, size: string) => {
  switch (type) {
    case 'unframed':
    case 'link':
      return 0;
    case 'tertiary':
      if (size === 'large') return '16px 16px';
      return '8px 12px';
    default:
      return '0px 20px 0px 16px';
  }
};

const setHeight = (type: string) => {
  switch (type) {
    case 'unframed':
    case 'link':
    case 'tertiary':
      return 'auto';
    default:
      return '40px';
  }
};

export const Button = styled(motion.div)<Props>`
  font-family: ${fontFamily['polysans-neutral']};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(p) => (p.type === 'link' ? 'row-reverse' : 'row')};
  align-items: center;
  white-space: nowrap;
  gap: 4px;
  border-radius: ${(props) => (props.type === 'tertiary' ? '4px' : '100px')};
  padding: ${({ type, size }) => setPadding(type!, size!)};
  height: ${(props) => setHeight(props.type!)};
  border-width: 1px;
  border-style: ${(props) => (props.type === 'unframed' ? 'none' : 'solid')};
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  &:hover {
    text-decoration: ${(props) => (props.type === 'link' ? 'underline' : 'none')};
  }
`;
