import { updateProspectAsync } from 'api/prospect';
import { topCountries } from 'components/Shared/Overlay/Countries/Countries.utils';
import { useRootStore } from 'contexts/StoreContext';
import { toJS, autorun } from 'mobx';
import { useRef, useState, useEffect, useCallback, MouseEvent, useMemo } from 'react';
import { isEqual } from 'utils/helpers';

export const useCountrySelection = (pageNumber: number) => {
  const {
    configurePageStore: {
      questionStepsData,
      completedConfigureSteps,
      setCompletedConfigureSteps,
      currentQuestionIndex,
      goToQuestionIndex,
    },
    prospectLayoutStore: { prospectInformation },
    overlayStore: { pushOverlay },
  } = useRootStore();

  // Ref to track the latest changes made to the store
  const updatedCountries = useRef<string[]>(toJS(completedConfigureSteps.get(pageNumber)) as string[]);
  const { id, token } = prospectInformation;
  // Local state to track selected countries
  const [countries, setCountries] = useState<string[]>(updatedCountries.current ?? ([] as string[]));

  const data = questionStepsData[pageNumber];
  const selectedCountries = updatedCountries.current !== undefined ? updatedCountries.current : countries;

  useEffect(() => {
    // Clear question.value property on component is mounted to prevent unwanted backend calls,
    data.questions[0].value = undefined;
    if (selectedCountries && !isEqual(updatedCountries.current, countries)) {
      setCompletedConfigureSteps(pageNumber, countries);

      //Ensuring that we has set the value prop in the data object before sending it to the backend,
      if (data.questions[0].value) {
        updateProspectAsync(id, data, token);
      }
    }
  }, [countries, data, id, pageNumber, prospectInformation, selectedCountries, setCompletedConfigureSteps, token]);

  // Subscribe to changes in completedConfigureSteps
  useEffect(() => {
    const disposer = autorun(() => {
      // Trigger rerender whenever completedConfigureSteps changes
      updatedCountries.current = toJS(completedConfigureSteps.get(pageNumber)) ?? [];
      data.questions[0].value = toJS(updatedCountries.current);
      setCountries(completedConfigureSteps.get(pageNumber));
    });

    return () => {
      // this Cleanup the autorun and unsubscribes when component unmounts
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedConfigureSteps]);

  //convert to Set to improve performance and have consistent lookup time
  const topCountriesSet = useMemo(() => new Set(topCountries.map((country) => country.option.toLowerCase())), []);

  // Use filter to return strings that are not found in topCountries
  const additionalCountries = useMemo(
    () => selectedCountries.filter((country) => !topCountriesSet.has(country.toLowerCase())),
    [selectedCountries, topCountriesSet],
  );

  const handleCountryClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { value } = event.currentTarget;
      data.questions[0].value = updatedCountries.current.filter((item) => item !== value);
      setCountries((prevState = []) => {
        if (prevState?.includes(value)) {
          // If value is already in state, remove it
          return prevState.filter((item) => item !== value);
        } else {
          // If value is not in state, add it
          return [...prevState, value];
        }
      });
    },
    [data.questions],
  );

  const handleAdditionCountryClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { value } = event.currentTarget;
      data.questions[0].value = updatedCountries.current;
      if (updatedCountries.current.includes(value)) {
        //This is to remove the current country that fired this event
        const unSelectClickedCountry = updatedCountries.current.filter((item) => item !== value);
        setCompletedConfigureSteps(pageNumber, unSelectClickedCountry);
        data.questions[0].value = unSelectClickedCountry;
        updateProspectAsync(id, data, token);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCompletedConfigureSteps],
  );
  return {
    data,
    completedConfigureSteps,
    selectedCountries,
    additionalCountries,
    handleCountryClick,
    handleAdditionCountryClick,
    currentQuestionIndex,
    goToQuestionIndex,
    pushOverlay,
  };
};
