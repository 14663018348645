import chat from 'assets/icons/chat.svg';
import ArrowRight from 'assets/icons/arrows/arrowRightWhite.svg';
import ContactWhite from 'assets/icons/contactWhite.svg';
import Contractor from 'assets/icons/contractors.svg';
import ContractorBig from 'assets/icons/contractorsBig.svg';
import PhoneBlack from 'assets/icons/phoneWhite.svg';
import Error from 'assets/icons/errorFeedback.svg';
export interface FeedbackProps {
  type: 'contractor' | 'other' | 'manda' | 'transition' | 'other';
}

export interface FeedbackOptions {
  name: string;
  title: string;
  description: string;
  image: string;
  imageMobile?: string;
  buttonText?: string;
  buttonImg?: string;
  notes?: string;
  btnUrl?: string;
}

export const feedbackOptions: FeedbackOptions[] = [
  {
    name: 'contractor',
    title: 'Manage your Contractors',
    description:
      'Engage your contractors quickly and easily, and pay contractor invoices compliantly in over 180+ countries with G-P Meridian Contractor. Sign up now to create a free account and add G-P Meridian Contractor to your growth package.',
    buttonText: 'Sign Up (Free)',
    imageMobile: Contractor,
    image: ContractorBig,
    btnUrl: 'https://goglobal.globalization-partners.com/selfserve/signup',
    buttonImg: ArrowRight,
  },
  {
    name: 'other',
    title: 'Talk to an Expert',
    description:
      'Understanding your unique requirements helps us provide a solution and quote that best matches your needs. Talk to one of our experts to find out how we can help your business.',
    buttonText: 'Talk to an expert',
    image: chat,
    buttonImg: ContactWhite,
  },
  {
    name: 'transition',
    title: 'Manage your Contractors',
    description:
      'Transitioning temporary team members can have unique operational and legal requirements. Talk to one of our experts to find out how we can help your business.',
    buttonText: 'Talk to an expert',
    image: chat,
    buttonImg: ContactWhite,
  },
  {
    name: 'manda',
    title: 'Talk to an expert',
    description:
      'Mergers and acquisitions often have complex requirements, but understanding your needs helps us suggest the right solution. Talk to one of our experts about your transition and we’ll help configure the solution that works for you.',
    buttonText: 'Talk to an expert',
    image: chat,
    buttonImg: ContactWhite,
  },
  {
    name: 'error',
    title: "That's odd...",
    description:
      'It seems something went wrong on our side. Refresh this page and try again. If the problem persists, request a callback and our team of global experts will get back to you asap. ',
    buttonText: 'Get a callback',
    image: Error,
    notes: 'Note: you can always contact us at for free at +1 (888)-855-5328.',
    buttonImg: PhoneBlack,
  },
];
