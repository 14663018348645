import { FunctionComponent, MouseEvent } from 'react';
import { StyledSelectBox } from '../StyledSelectBox';
import { ButtonScaleEffectProps, ToolTipRevealProps } from 'utils/tailwindConfig/elementProps';
import { eliminateSpace } from 'utils/helpers';
import { ToolTip, ToolTipContainer } from './PillWithToolTip.styled';
import { colors } from 'utils/tailwindConfig';
import moment from 'moment';
import { InitialState } from '../WeeklyCalendar/WeeklyCalendar';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

type PillWithToolTipProps = {
  from: string;
  to: string;
  date: string;
  selectedTime: InitialState;
  selectedTimeInStore: string;
  isDisabled: boolean;
  setSelectedTime: (props: InitialState) => void;
  onPillClick: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancelClick: () => void;
  onConfirmClick: (event: MouseEvent<HTMLButtonElement>) => void;
};
export const PillWithToolTip: FunctionComponent<PillWithToolTipProps> = ({
  from,
  to,
  date,
  selectedTime,
  selectedTimeInStore,
  isDisabled,
  onPillClick,
  onCancelClick,
  onConfirmClick,
}) => {
  const text = `${from}-${eliminateSpace(to)}`;
  const meridiem = to.slice(-2);

  const buttonId = `${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING}-${date}:${text}-button`;
  const inputTime = `${date} at ${from} ${meridiem}`;
  const convertedTimeIn24HoursFormat = moment(inputTime, 'YYYY-MM-DD [at] h a').toISOString();
  const selectionConfirmed = selectedTimeInStore === convertedTimeIn24HoursFormat;
  const isSelected = selectedTime.selectedElementId === buttonId || selectionConfirmed;
  const toolTipSecondaryButtonState = selectionConfirmed ? 'Continue' : 'Confirm';

  //this is for the Day_time format in the ID
  const parsedDate_DayHr_Format = moment(`${date}`, 'YYYY-MM-DD:ha').format('dddd_ha');

  const toolTipId = `${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING}-${parsedDate_DayHr_Format}`;

  return (
    <div className="flex relative grow">
      <ToolTipContainer
        initial={false}
        animate={isSelected ? 'open' : 'closed'}
        className="menu w-full"
        data-testid={`${ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE_SCHEDULE_A_MEETING}-${date}:${text}-container`}
      >
        {isDisabled === false && (
          <ToolTip id={date} {...ToolTipRevealProps} style={{ pointerEvents: isSelected ? 'auto' : 'none' }}>
            <div className="bg-warning-200 flex p-2 gap-2 rounded-lg" data-testid={`${toolTipId}-tooltip`}>
              <button
                onClick={onCancelClick}
                id={`${toolTipId}-cancel-button`}
                data-testid={`${toolTipId}-cancel-button`}
              >
                Cancel
              </button>
              <button
                onClick={onConfirmClick}
                value={convertedTimeIn24HoursFormat}
                id={`${toolTipId}-${toolTipSecondaryButtonState}-button`}
                data-testid={`${toolTipId}-${toolTipSecondaryButtonState}-button`}
              >
                {toolTipSecondaryButtonState}
              </button>
            </div>
          </ToolTip>
        )}

        <StyledSelectBox
          id={buttonId}
          data-testid={buttonId}
          $accent={colors.grey[1000]}
          className="writing-text tag-pills w-full text-primary-500"
          {...ButtonScaleEffectProps}
          $checked={isSelected}
          onClick={onPillClick}
          disabled={isDisabled}
          value={convertedTimeIn24HoursFormat}
        >
          {text}
        </StyledSelectBox>
      </ToolTipContainer>
    </div>
  );
};
