export const PROSPECT_ID_TOKEN_NAME = 'x-prospect-token';
export const API_URL = ['.com'].some((dns) => window.location.host.endsWith(dns))
  ? 'https://app.meridian.g-p.com'
  : process.env.REACT_APP_API_ENDPOINT ?? '';

export const GP_PRIVATE_POLICY_URL = 'https://www.globalization-partners.com/privacy-policy/';
export const GP_TERMS_CONDITIONS = 'https://www.globalization-partners.com/terms-conditions/';

export const OPEN_SELF_SERVE_ON_NEW_TAB = () =>
  window.open('https://goglobal.globalization-partners.com/selfserve/signup', '_blank');

export const TIMEZONE = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;

export const BASE_API_URL = {
  ContentApiUrl: `${API_URL}/api/lead-generation/con--des/v2/content`,
  ProspectApiUrl: `${API_URL}/api/lead-generation/pro--des/v2/prospect`,
} as const;

export const API_PATH = {
  landing: 'landing',
  onboarding: 'onboarding',
  'onboarding-steps': 'onboarding-steps',
  'talk-to-specialist': 'talk-to-specialist',
} as const;
