import { CreateMeetingPayload } from 'pages/NewTalk/Talk.types';
import { BASE_API_URL } from 'utils/constants';

/**
 * Creates a meeting asynchronously for a prospect.
 * @param meetingPayload - The payload to be sent with the meeting creation request.
 * @param id - The ID of the prospect.
 * @param token - The token of the prospect.
 * @returns The response payload if the request is successful, otherwise an error object.
 */
export const createMeetingAsync = async (
  meetingPayload: CreateMeetingPayload,
  id: string,
  token: string,
  fetchFunction = fetch,
) => {
  const url = `${BASE_API_URL.ProspectApiUrl}/${id}/lead/meeting`;
  try {
    const response = await fetchFunction(url, {
      method: 'POST',
      headers: {
        'x-prospect-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(meetingPayload),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Failed to create meeting');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
