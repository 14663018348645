import { useEffect } from 'react';
import { useAnimationControls } from 'framer-motion';
import Icon, { ico } from 'components/Icon/Icon';
import { motionConfig } from 'utils/tailwindConfig/motionConfig';
import { Button, iconSizes, themeColors } from './UIButton.styled';

interface Props {
  label?: string;
  icon?: ico;
  showicon?: boolean;
  type?: 'unframed' | 'primary' | 'secondary' | 'tertiary' | 'link';
  size?: 'small' | 'regular' | 'medium' | 'large' | 'xlarge';
  theme?: 'white' | 'blue' | 'orange' | string;
  onClick?: () => void;
  transition?: any;
  instanceId?: string;
}

export default function UIButton(props: Props) {
  const {
    label = null,
    icon = ico.arrowLeft,
    showicon = true,
    size = 'regular',
    type = 'unframed',
    theme = 'blue',
    onClick,
    instanceId,
  } = props;
  const controls = useAnimationControls();

  // for links we need to reverse blue theme to output blue color and not white
  const setTheme = () => {
    if (theme === 'blue' && type === 'link') return 'blueLink';
    if (theme === 'blueLink') return 'blueButton';
    return theme;
  };

  useEffect(() => {
    let themeToSet = theme;
    if (theme === 'blue' && type === 'link') themeToSet = 'blueLink';
    controls.start(themeToSet);
  }, [theme, controls, type]);

  const setIcon = () => {
    if (!showicon) return null;
    return <Icon name={icon} size={iconSizes[size].size} stroke={iconSizes[size].stroke} theme={setTheme()} />;
  };

  const setLabel = () => {
    return label ? label : null;
  };

  const intialTheme = setTheme();
  return (
    <Button
      size={size}
      id={instanceId}
      data-testid={instanceId}
      theme={theme}
      type={type}
      variants={themeColors}
      initial={themeColors[intialTheme as keyof typeof themeColors]}
      transition={{ ...motionConfig.themeChange }}
      whileHover={{ scale: 1.04 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      animate={controls}
    >
      {setIcon()}
      {setLabel()}
    </Button>
  );
}
