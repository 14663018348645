import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react';
import RootStore from 'rootstore';

const StoreContext = createContext<RootStore>({} as RootStore);

export const RootStoreProvider: FunctionComponent<PropsWithChildren<{ store: RootStore }>> = ({ store, children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useRootStore: () => RootStore = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
};
