import { FunctionComponent, PropsWithChildren, useRef } from 'react';
import { BackDrop, StyledContainer, StyledSideDrawer } from './ProspectLayout.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { Direction, ProspectLayoutProps } from './ProspectLayout.types';
import { useRootStore } from 'contexts/StoreContext';
import { motionConfig } from 'utils/tailwindConfig/motionConfig';
import useResizeObserver from 'utils/useResizeObserver';
import Header from 'components/Header/Header';
import { Overlay } from './Overlay';
import { InfoDescriptionLayout } from 'components/Shared/InfoPanel/InfoDescriptionLayout';
import { ProgressBar } from 'components/Shared/ProgressBar/ProgressBar';
import { useProgressBarPosition } from 'components/Shared/ProgressBar/useProgressBarPosition';

const ProspectLayout: FunctionComponent<PropsWithChildren<ProspectLayoutProps>> = ({
  asideIsOpen,
  children,
  currentPage,
  toggleAside,
  headerTheme,
  activateDarkGBOnIntro,
  steps,
  progress,
  showCheck,
  navigateToPage,
  context,
  instanceId,
}) => {
  const {
    prospectLayoutStore: { direction },
  } = useRootStore();

  const ref = useRef(null);

  const size = useResizeObserver(ref); // Calculating the size of the page for the horizontal movement

  const amt = () => size?.height ?? 1000; //getting the height of the page for the horizontal movement

  //This is responsible for the page movement animation, (Entry, Exit and Scroll behavior)
  const scrollVariants = {
    enter: (direction: Direction) => {
      let y = direction === Direction.forward ? amt() : -amt();
      return { y: y, opacity: 0 };
    },
    animate: { y: 0, opacity: 1 },
    exit: (direction: Direction) => {
      let y = direction === Direction.forward ? -amt() : amt();
      return { y: y, opacity: 1 };
    },
  };

  const right = useProgressBarPosition();

  return (
    <StyledContainer
      data-testid="prospect-layout-component"
      $openSideDrawer={asideIsOpen}
      className="box-border min-h-[100vh] flex flex-col justify-center overflow-y-auto mr-0 border-r border-solid border-white"
    >
      <BackDrop
        $showOnlyOnTabletDownward
        $show={asideIsOpen}
        $zIndex={2}
        onClick={() => toggleAside(false)}
        data-testid="backdrop-component"
      />
      <Header theme={headerTheme} context={context} instanceId={instanceId} />
      <Overlay />
      <ProgressBar
        pages={steps}
        progress={progress}
        selected={progress}
        onChange={navigateToPage}
        right={right}
        showCheck={showCheck}
        activateDarkGBOnIntro={activateDarkGBOnIntro}
      />
      <StyledSideDrawer
        $openSideDrawer={asideIsOpen}
        className=" absolute box-border bg-survey-button-text top-0 right-0 min-h-[100vh] w-[350px] flex flex-col justify-start items-start gap-4 overflow-hidden font-basier text-[18px] font-[400] leading-[150%] tracking-[-0.18px] p-0 m-0 text-white"
      >
        <InfoDescriptionLayout />
      </StyledSideDrawer>
      <div className="relative self-stretch flex-auto overflow-hidden bg-white" ref={ref}>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            custom={direction}
            data-testid="prospect-layout-children-wrapper"
            transition={{ ...motionConfig.easIn, duration: 0.85 }}
            variants={scrollVariants}
            initial={'enter'}
            animate={'animate'}
            exit={'exit'}
            key={'page_' + currentPage}
            className="absolute inset-0 flex justify-start flex-col items-center overflow-x-hidden overflow-y-auto"
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </div>
    </StyledContainer>
  );
};

export default ProspectLayout;
