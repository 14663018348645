import { motion } from 'framer-motion';
import styled from 'styled-components';
import { hexToRgba } from 'utils/helpers';
import { colors } from 'utils/tailwindConfig';

export const themeColors = {
  white: {
    color: colors.grey[1000],
    borderColor: colors.grey[200],
    backgroundColor: colors.white,
  },
  blue: {
    color: colors.white,
    borderColor: hexToRgba(colors.white, 0.2),
    backgroundColor: colors.primary[500],
  },
  orange: {
    color: colors.grey[1000],
    borderColor: hexToRgba(colors.warning[300], 0.3),
    backgroundColor: colors.warning[200],
  },
};

export const Container = styled(motion.nav)<{
  $theme: string;
  $context: string;
}>`
  box-sizing: border-box;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 24px;
  top: 0px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: ${({ $theme }) => themeColors[$theme as keyof typeof themeColors].backgroundColor};
  z-index: 1;
  @media screen and (min-width: 768px) {
    display: ${(props) => (props.$context === 'home' ? 'none' : 'flex')};
  }
`;

export const LeftWrapper = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  overflow: visible;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  overflow: visible;
`;

export const StyledLogo = styled.div<{ $context: string }>`
  display: ${(props) => (props.$context === 'home' ? 'inherit' : 'none')};
  @media screen and (min-width: 768px) {
    display: inherit;
  }
`;

export const ButtonBack = styled.div<{ $show: boolean }>`
  display: none;
  @media screen and (min-width: 768px) {
    display: ${(props) => (props.$show ? 'inherit' : 'none')};
  }
`;

export const ButtonBackIcon = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? 'inherit' : 'none')};
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 0;
  margin: 0;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const ButtonQuit = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? 'inherit' : 'none')};
`;

export const ButtonTalk = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? 'inherit' : 'none')};
`;

export const ButtonCall = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? 'inherit' : 'none')};
`;
