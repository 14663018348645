import ProspectLayout from 'components/Layout/ProspectLayout';
import { observer } from 'mobx-react';
import { FunctionComponent } from 'react';
import { useTalk } from './hooks/useTalk';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const Talk: FunctionComponent = observer(() => {
  const {
    asideIsOpen,
    currentPage,
    CurrentComponent,
    direction,
    headerTheme,
    navigatePage,
    positionCurrentPageInTheStepsArray,
    setSideDrawer,
    talkFlowSteps,
  } = useTalk();

  return (
    <ProspectLayout
      context="contact"
      instanceId={ElementIdentifiersRecord.TALK_TO_EXPERT_PAGE}
      asideIsOpen={asideIsOpen}
      toggleAside={setSideDrawer}
      currentPage={currentPage}
      direction={direction}
      headerTheme={headerTheme}
      steps={talkFlowSteps}
      progress={Number(positionCurrentPageInTheStepsArray)}
      selected={Number(positionCurrentPageInTheStepsArray)}
      navigateToPage={navigatePage}
      activateDarkGBOnIntro={true}
    >
      {CurrentComponent && <CurrentComponent />}
    </ProspectLayout>
  );
});

export default Talk;
