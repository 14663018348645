import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from 'utils/tailwindConfig';

export type StyledSelectBoxProps = {
  $checked: boolean;
  /** used to control the background color when option is selected.
   * Default is blue, but you cant pass any color you want
   */
  $accent?: string;
};
export const StyledSelectBox = styled(motion.button)<StyledSelectBoxProps>`
  ${(props) =>
    props.$checked &&
    `
  background-color: ${props.$accent ?? colors.primary[500]}; 
  color: ${colors.white};
  border: 2px solid transparent;
  `};
  position: relative;
  overflow-wrap: anywhere;
  -webkit-user-drag: none;
  user-select: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.grey[200]};
    color: ${colors.grey[600]};
    border: 2px solid transparent;
    pointer-events: none;
  }
`;

export const SelectBoxWithFlag = styled(StyledSelectBox)<{ $checked: boolean }>`
  opacity: ${(p) => (p.$checked ? 1 : 0.5)};
  filter: saturate(${(p) => (p.$checked ? 1 : 0)});
  text-transform: capitalize;
`;
