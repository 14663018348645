import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 30;
const buttonName = 'transactionPrioritiesAndFocusArea';
export const TransactionPrioritiesAndFocusArea = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      css="tabletDownwards:grow"
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_TRANSITION_PRIORITIES_AND_FOCUS_AREAS}
    />
  );
};
