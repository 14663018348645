import { LinearSelectLayout } from 'components/Shared/PageTemplate/LinearSelectLayout';
import { ElementIdentifiersRecord } from 'config/elementIdentifiers';

const pageNumber = 6;
const buttonName = 'priorities';
export const Priorities = () => {
  return (
    <LinearSelectLayout
      pageNumber={pageNumber}
      inputName={buttonName}
      flexGrid
      instanceId={ElementIdentifiersRecord.CONFIGURE_PAGE_PRIORITIES_AND_OPERATIONAL_NEEDS}
    />
  );
};
