import { Direction } from 'components/Layout/ProspectLayout.types';
import { useRootStore } from 'contexts/StoreContext';
import { useEffect } from 'react';
import { colors } from 'utils/tailwindConfig';
import { ConfigurePageRecord, ConfigureStepRecord } from '../Configure.util';
import { progressBarSteps } from './helpers';

export const useConfigure = () => {
  const {
    prospectLayoutStore: {
      asideIsOpen,
      direction,
      headerTheme,
      setHeaderTheme,
      setSideDrawer,
      setDirection,
      clearProspectInformation,
    },
    configurePageStore: {
      nextSteps,
      branchPage,
      currentPage,
      currentQuestionIndex,
      setCurrentQuestionIndex,
      setCurrentPage,
    },
  } = useRootStore();

  const CurrentComponent = ConfigurePageRecord[currentPage as keyof typeof ConfigurePageRecord];
  const currentPageInteger = Number(currentPage);
  //Clean-up the prospect Information once the configure-flow is completed or exited (for example redirect to talk to expert flow)
  useEffect(() => {
    return () => {
      if (!nextSteps) {
        clearProspectInformation();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSteps]);

  /**
   * We'll use this to update content styles, themes, etc. based on the
   * level of progress through the survey
   */
  useEffect(() => {
    setHeaderTheme(currentPageInteger > 0 ? 'white' : 'blue');
    document.body.style.backgroundColor = currentPageInteger > 0 ? colors.white : colors.primary[500];
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.backgroundColor = '#fff';
      document.body.style.overflow = 'auto';
    };
  }, [currentPage, currentPageInteger, setHeaderTheme]);

  //We flatten the nextSteps array so that we can get the type
  //if there are steps it would be a Number[] and if No steps it would be NaN
  const flatNextSteps = nextSteps.flat();
  //if question steps goes below zero, we'll point the page back to primaryBusinessGoal page
  useEffect(() => {
    if (currentQuestionIndex && currentQuestionIndex < 0) {
      setCurrentQuestionIndex(undefined);
      setCurrentPage(ConfigureStepRecord.primaryBusinessGoal);
    }

    if (flatNextSteps.some(isNaN) && currentQuestionIndex === 0) {
      setCurrentPage(ConfigureStepRecord.primaryBusinessGoal);
    }
  }, [currentQuestionIndex, flatNextSteps, nextSteps.length, setCurrentPage, setCurrentQuestionIndex]);

  //this is responsible to keeping track, when the end of question-steps is reached
  // and automatically redirect to the Contact form
  useEffect(() => {
    if (currentQuestionIndex && currentQuestionIndex > nextSteps.length - 1) {
      setCurrentPage(ConfigureStepRecord.ContactForm);
    }
  }, [currentQuestionIndex, nextSteps.length, setCurrentPage]);

  const pageSteps = progressBarSteps(nextSteps, branchPage!);
  const stringifiedPageSteps = pageSteps.map(String);
  const positionCurrentPageInTheStepsArray = stringifiedPageSteps.indexOf(String(currentPage));

  /**
   * Handling the progress bar tooltip onClick action
   * @param pageNumber
   */
  const navigatePage = (pageNumber: number) => {
    // if we're navigating to the primary business goal page, we'll reset the current,
    // question index to undefined
    // or else if you click another choice (Especially a branch page),
    // it would take you to contact form and if you click back, the entire page would then be blank because,
    // the currentPage would be null
    if (pageNumber === ConfigureStepRecord.primaryBusinessGoal || pageNumber === ConfigureStepRecord.intro) {
      setCurrentQuestionIndex(undefined);
    }
    setCurrentPage(stringifiedPageSteps[pageNumber]);
    setDirection(Direction.back);
  };

  return {
    asideIsOpen,
    branchPage,
    currentPage,
    CurrentComponent,
    currentQuestionIndex,
    direction,
    headerTheme,
    navigatePage,
    pageSteps,
    positionCurrentPageInTheStepsArray,
    setSideDrawer,
  };
};
