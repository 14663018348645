import { makeObservable, observable, runInAction } from 'mobx';
import RootStore from 'rootstore';
import { OverlayNameType } from './Overlay.type';

export default class OverlayStore {
  public overlays: OverlayNameType[];
  public isErrorShown: boolean;
  public errorHandler: null | (() => void);

  /**
   * Constructs an OverlayStore instance.
   * @param _rootStore - The root store of the application.
   */
  constructor(_rootStore: RootStore) {
    makeObservable(this, {
      overlays: observable.ref,
      isErrorShown: observable.ref,
      errorHandler: observable.ref,
    });

    this.overlays = [];
    this.isErrorShown = false;
    this.errorHandler = null;
  }

  /**
   * Adds a new overlay to appear above the current content, introducing supplementary visual elements or information.
   * @param overlay - The name of the overlay to be added.
   */
  pushOverlay = (overlay: OverlayNameType): void => {
    runInAction(() => {
      this.overlays = [...this.overlays, overlay];
    });
  };

  /**
   * Removes the most recently added overlay with the specified name, causing it to be unmounted from the screen.
   * @param overlay - The name of the overlay to be removed.
   */
  popOverlay = (): void => {
    runInAction(() => {
      this.overlays = this.overlays.slice(0, this.overlays.length - 1);
    });
  };

  /**
   * Unmounts all currently rendered overlays.
   */
  closeAllOverlays = (): void => {
    runInAction(() => {
      this.overlays = [];
    });
  };

  /**
   * Sets the error state and optionally assigns an error handler function.
   * @param {() => void} [errorHandler] - Optional function to handle the error. If not provided, the error handler will be set to null.
   */
  showError = (errorHandler?: () => void): void => {
    runInAction(() => {
      this.isErrorShown = true;
      this.errorHandler = errorHandler || null;
    });
  };

  /**
   * Clears the error state and, if specified, runs the error handler function associated with the error.
   * @param {{ runErrorHandler?: boolean }} [options] - Optional object to customize behavior.
   * @param {boolean} [options.runErrorHandler=true] - If true and an error handler is present, it will be executed when clearing the error.
   */
  clearError = (options: { runErrorHandler?: boolean } = { runErrorHandler: true }): void => {
    runInAction(() => {
      options.runErrorHandler && this.errorHandler?.();
      this.isErrorShown = false;
    });
  };
}
