import { updateProspectAsync } from 'api/prospect';
import { useRootStore } from 'contexts/StoreContext';
import { toJS } from 'mobx';
import { ConfigureStepRecord } from 'pages/NewConfigure/Configure.util';
import { useState, MouseEvent, useEffect, useCallback } from 'react';
import { hasObjectChanged, isObjectEmpty } from 'utils/helpers';
import { companySizeButtonName, industryButtonName, questionIdRecord } from './CompanySizeAndIndustry';

export const useCompanySizeAndIndustry = (pageNumber: number) => {
  const {
    configurePageStore: {
      questionStepsData,
      setCompletedConfigureSteps,
      completedConfigureSteps,
      goToQuestionIndex,
      currentQuestionIndex,
    },
    prospectLayoutStore: { prospectInformation },
  } = useRootStore();

  const dataFromStore: Record<string, string> = toJS(completedConfigureSteps.get(pageNumber));

  const [state, setState] = useState<Record<string, string>>(dataFromStore ?? {});

  const data = questionStepsData[pageNumber];

  const isCompanySizeAndIndustrySelected =
    state[companySizeButtonName] !== undefined && state[industryButtonName] !== undefined;

  useEffect(() => {
    const { id, token } = prospectInformation;
    if (isCompanySizeAndIndustrySelected) {
      if (!isObjectEmpty(state)) {
        // Check if the state has changed
        if (hasObjectChanged(dataFromStore, state)) {
          const payload = {
            [companySizeButtonName]: state[companySizeButtonName],
            [industryButtonName]: state[industryButtonName],
          };

          setCompletedConfigureSteps(ConfigureStepRecord.companySizeAndIndustry, payload);

          // Update the backend with the completed step
          updateProspectAsync(id, data, token);
        }
      }
    }
  }, [state, data, dataFromStore, prospectInformation, setCompletedConfigureSteps, isCompanySizeAndIndustrySelected]);

  const handleClickChange = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { name, value: inputValue } = event.currentTarget;
      const questionIndex = questionIdRecord[name as keyof typeof questionIdRecord];
      setState((prevState) => {
        // Create a copy of the previous state
        const newState = { ...prevState };

        if (newState[name] !== undefined && newState[name] === inputValue) {
          delete newState[name]; // Remove the property if the value is the same
          data.questions[questionIndex].value = '';
        } else {
          newState[name] = inputValue; // Update the property otherwise
          data.questions[questionIndex].value = inputValue;
        }

        return newState; // Return the updated state
      });
    },
    [data.questions],
  );

  const companySizeOption = data.questions[questionIdRecord[companySizeButtonName]]?.options;
  const industryOption = data.questions[questionIdRecord[industryButtonName]]?.options;

  return {
    completedConfigureSteps,
    currentQuestionIndex,
    companySizeOption,
    data,
    goToQuestionIndex,
    handleClickChange,
    industryOption,
    state,
  };
};
